/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Box, Grommet, Button, FormField, TextInput, ThemeContext, grommet, Clock, Image, MaskedInput } from 'grommet';
import { Lock, User, } from 'grommet-icons';
import ResendEmail from '../pages/ResendEmail';
//import api from '../services/api';
// <p className="errorMsg">{emailError}</p>
//import LoginForm from 'grommet/components/LoginForm';
import mapMarkerImg from '../../src/images/logo_barbershop.svg';
import imgMarkerLogin from '../../src/images/layout_login.svg';
import { FaLock, FaUser } from 'react-icons/fa';


const Login = (props: any) => {
    const {
        email,
        setEmail,
        password,
        setPassword,
        handleLogin,
        handleSingup,
        hasAccount,
        setHasAccount,
        emailError,
        passwordError,
    } = props;

    function CleanForm() {

        setPassword("")
        setEmail("")
    }

    return (
        <Grommet background="dark-2" theme={grommet} full>
            <ThemeContext.Extend
                value={{
                    global: {
                        h2: {
                            family: 'Nunito'
                        },
                        colors: {
                            secondColor: '#dabb9e96',
                            firstColor: '#533d0b',
                            align: 'center',
                            ligthColor: '#FFFFFF'
                        },
                    }
                }}
            >
                <Box

                    pad="large"
                    direction="row"
                    justify="center"
                >
                    <div className="btn_social_BarbershopImg">
                        <img src={imgMarkerLogin} alt="BarbershopApp" />
                    </div>
                    <Box pad="large"
                        animation={{
                            "type": "fadeIn",
                            "delay": 0,
                            "duration": 1600,
                            "size": "xsmall"
                        }}
                        width='large'

                    >
                        <Box 
                        align='center'
                        className='loginlogo'
                        >
                            <Image width='160px' src={mapMarkerImg} alt="BarbershopApp" />
                        </Box>
                        <Box
                            pad="large"
                            background="dark-3"
                            border={{ color: 'dark-4', size: 'large' }}
                            elevation='small'
                            round='medium'

                        >


                            <FormField name="name" htmlFor="textinput-id" label="E-mail">

                                <MaskedInput
                                    icon={<FaUser
                                        color="#533d0b"
                                    />}
                                    id="textinput-id"
                                    placeholder='user name'
                                    type="text" required
                                    name="username"
                                    value={email} onChange={(e) => setEmail(e.target.value)} />
                            </FormField>
                            <FormField name="name" htmlFor="textinput-id" label="Senha">
                                <MaskedInput
                                    icon={<FaLock
                                        color="#533d0b"
                                    />}
                                    id="textinput-id"
                                    placeholder={'senha'}
                                    type="password" required
                                    name="password" value={password}
                                    onChange={(e) => setPassword(e.target.value)} />
                            </FormField>
                            <FormField>
                                {passwordError}
                                {emailError}
                            </FormField>
                            <Box direction="column" gap="large" alignContent="center" text-align="center">
                                <Box>
                                    {hasAccount ? (

                                        <Box direction="column" gap="medium" alignContent="center" text-align="center">
                                            <Button size="large" color="firstColor"
                                                gap='large' type="button" primary label="Cadastrar"
                                                name="singup" value="Cadastrar" text-align="center"
                                                onClick={handleSingup} />
                                            <ResendEmail color="#dabb9e96" infoBtn="Informe seu e-mail para redefinir sua senha:" email={email} />
                                            <h3 color="neutral-4">
                                                <Box onClick={() => setHasAccount(!hasAccount)}>
                                                    Já tem uma conta? Entrar
                                    </Box>
                                            </h3>

                                        </Box>

                                    ) : (
                                        <Box direction="column" gap="medium" alignContent="center" text-align="center">
                                            <Button size="large" color="firstColor" type="button" primary label="Entrar" name="login" value="Entrar" text-align="center" onClick={handleLogin} />
                                            <Button size="large" color="#dabb9e96" type="reset" primary label="Limpar formulário" onClick={CleanForm} />
                                            <h3>
                                                <Box onClick={() => setHasAccount(!hasAccount)}>
                                                    Não tem uma conta? Cadastrar | Não lembra da senha :)
                                    </Box>
                                            </h3>

                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Clock alignSelf="center" type="digital" size="xsmall" />

                            <Box>

                            </Box>
                        </Box>
                    </Box>

                </Box>

            </ThemeContext.Extend>

        </Grommet>

    )
}

export default Login;