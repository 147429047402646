import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Leaflet from 'leaflet';
import { FiPlus, FiMapPin, FiMenu, FiMap } from 'react-icons/fi';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
//import Leaflet, { popup } from 'leaflet';
import { Box, Clock, Image, Main, Text } from 'grommet';
import mapMarkerImg from '../../src/images/logo_barbershop.svg';
import markII from '../../src/images/barbershopapp_markII.svg';
import markI from '../../src/images/markI.svg';
//import mapIcon from '../utils/mapIcons';
import api from '../services/api';
import { FaMapMarkedAlt, FaMapMarkerAlt, FaStoreAlt, FaUserClock } from 'react-icons/fa';
//import { tileLayer } from 'leaflet';
import UserViews from "../pages/UserViewsMap";
import '../css/prelooad.css';
import BtnStyle from "./BtnStyleThemeType";
import Tawkto from '../pages/TawkTo';
import { Apps } from 'grommet-icons';


interface Barbershop {
    id: number;
    latitude: number;
    longitude: number;
    name: string;
    andress: string;
    avatar: string;
    colorbarber: string;
    activated: boolean;
}

interface MapPosition {
    latitude: number;
    longitude: number;
}

interface Professional {
    id: number;
    name: string;
}

function BarbershopMap() {
    const [latMap, setLatMap] = useState(-22.2908638);
    const [longMap, setLongMap] = useState(-42.5224806);
    const [geoMapNow, setGeoMapNow] = useState(false);
    // if ("geolocation" in navigator) 
    if (navigator.geolocation) {
        if (geoMapNow === true) {
            navigator.geolocation.getCurrentPosition(setPosition, showError);
        }
    }
    else {
        alert('navegador não suporta geolozalicação');
        mapLocalized(-22.2938638, -42.5324806);
    }
    let lat
    let long

    function setPosition(position: any) {
        console.log(position)
        lat = position.coords.latitude;
        long = position.coords.longitude;
        mapLocalized(lat, long);
    }
    function showError(error: any) {
        alert(`erro: ${error.message}`);
        mapLocalized(-22.2938638, -42.5324806);
    }
    function mapLocalized(lat: any, long: any) {
        //   alert(`erro ${lat} , ${long}`)
        setLatMap(lat);
        setLongMap(long);
        localStorage.setItem('map-lat', lat);
        localStorage.setItem('map-long', long);

    }
    //-22.2963165  -42.530944399999996
    //   const [positionMap, setPositionMap] = useState({        lat: -22.2938638,        long: -42.5324806      });   
    const UserEmail = localStorage.getItem('userEmail');
    const [countProfissa, setCountProfissa] = useState(0);
    //const [colorbarber, setColorbarber] = useState('#d3cac2');
    const [mark] = useState('https://i.ibb.co/09vsNhQ/icon.png');
    const [markIOff] = useState('https://i.ibb.co/7zZrkP8/barbershopapp-layout.png');
    const [countBarbershop, setCountBarbershop] = useState(0);
    const [colorIcon, setColorIcon] = useState("#fff");
    const Xcount = localStorage.getItem('X-count-Barbershops');
    const [profissionals, setProfissionals] = useState<Professional[]>([]);
    const [barbershops, setBarbershops] = useState<Barbershop[]>([]);
    const [showBot, setShowBot] = useState(false);
    const [showAside, setShowAside] = useState(false);



    const mapIcon = Leaflet.icon({
        iconUrl: mark,
        iconSize: [58, 58],
        iconAnchor: [29, 68],
        popupAnchor: [170, 2]
    })


    function UpDateBarbershopMap() {

        api.get('professionals').then(response => {
            setProfissionals(response.data)
            setCountProfissa(profissionals.length as any)
        });
        api.get('barbershops').then(response => {
            setBarbershops(response.data)
            setCountBarbershop(barbershops.length as any)
        });
        const btnOpen = localStorage.getItem('btnOpen');
        if (btnOpen as any > 0) {
            setShowBot(true);
        }
    }
    useEffect(() => {

        api.get('professionals').then(response => {
            setProfissionals(response.data)
            setCountProfissa(profissionals.length as any)
        });
        api.get('barbershops').then(response => {
            setBarbershops(response.data)
            setCountBarbershop(barbershops.length as any)
        });

        const btnOpen = localStorage.getItem('btnOpen');
        if (btnOpen as any > 0) {
            setShowBot(true);
        }
    }, []);

    if (!barbershops) {

        return (
            <Main>
                <Box pad={{ horizontal: '100px', vertical: '160px' }} align="center" justify="center" direction="row">
                    <div className="preloader17">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </Box></Main>);
    }
    return (
        <Box
            width='full'
            height='full'
            animation='fadeIn'
            onMouseMove={UpDateBarbershopMap}>

            <Main >
                <div id="page-map">
                    <Box
                        direction='column'
                    >
                        <Box
                            animation='slideRight'

                            align='center'
                            justify='between'
                            margin={{ top: 'small', left: 'small' }}
                            direction='row'
                        >
                            <Apps
                                onClick={() => { setShowAside(!showAside) }}
                                color={showAside ? '#FFAA15' : '#FFF'}

                                size='30'
                            />
                            <Box

                                pad={{ horizontal: 'medium', vertical: 'small' }}
                                align='center'
                            >
                                <Clock

                                    type="digital" size={showAside ? 'small' : 'xsmall'} />
                            </Box>

                        </Box>
                        {!showAside && (
                            <Box
                                align='center'
                                animation='slideRight'
                                margin={{ top: 'small' }}
                                onClick={() => { }}
                                hoverIndicator
                            >
                                <Link to="/" >
                                    <Image width='110px'
                                        src={mapMarkerImg} alt="BarbershopApp" />
                                </Link>
                            </Box>
                        )}
                        <Box
                            animation='slideRight'
                            align='center'
                            justify='between'
                            margin={{ top: 'small' }}
                            direction='row'
                        >

<Box
                                animation='slideRight'
                                width='full'
                                justify='between'
                                alignContent='center'
                                alignSelf='center'
                                align='center'>
                                {UserViews(UserEmail as any)}

                            </Box>
                           

                        </Box>
                        {!showAside && (
                            <Box
                                animation='slideRight'
                                pad={{ vertical: 'small' }}>
                                <Box
                                    width='full'
                                    align="center"
                                    pad='medium'
                                    onClick={() => { setGeoMapNow(!geoMapNow); setColorIcon("grey") }}
                                    hoverIndicator>
                                    <FaMapMarkerAlt size={geoMapNow ? '0' : '45'} color={geoMapNow ? '#FFAA15' : '#FFF'} />
                                    <FaMapMarkedAlt size={geoMapNow ? '45' : '0'} color={geoMapNow ? '#FFAA15' : '#FFF'} />
                                </Box>
                            </Box>
                        )}
                        <Box className={showAside ? 'openSide' : ''}
                            responsive
                            animation='slideUp'
                        >
                            {showAside && (
                                <Box
                                    alignSelf='center'
                                    align='center'
                                    alignContent='center'
                                    justify='center'
                                    width='full'
                                    direction='row'
                                    pad={{ top: 'small' }}
                                    hoverIndicator
                                >
                                    <Link to="/" >
                                        <Image width='150px' src={mapMarkerImg}
                                            alt="BarbershopApp" />
                                    </Link>
                                </Box>
                            )}

                            {showAside && (
                                <Box
                                    margin={{ top: 'medium' }}
                                >
                                    <Box
                                        width='full'
                                        direction='row'
                                        justify='center'
                                        align='center'
                                    >
                                        <Box pad={{ horizontal: 'small' }}>
                                            <Text color='status-warning'> <strong>
                                                <h2>  {countProfissa} </h2></strong>
                                            </Text>
                                        </Box>
                                        <Text><strong><h2>Profissas </h2></strong></Text>
                                        <Box pad={{ horizontal: 'small' }}>
                                            <FaUserClock size={32} color="#FFF" />
                                        </Box>
                                    </Box>
                                    <Box
                                        width='full'
                                        direction='row'
                                        justify='center'
                                        align='center'
                                    >
                                        <Box pad={{ horizontal: 'small' }}>
                                            <Text color='status-warning'> <strong><h2>  {countBarbershop} </h2></strong></Text>
                                        </Box>
                                        <Text><strong><h2>Barbershops </h2></strong></Text>
                                        <Box pad={{ horizontal: 'small' }}>
                                            <FaStoreAlt size={32} />
                                        </Box>
                                    </Box>
                                    <Box align='end'
                                    >
                                        <Text><strong><h2> no mapa</h2></strong></Text>
                                    </Box>
                                    {/**
                                        <Text><strong><h3>{UserEmail}</h3></strong></Text>
                                     * 
                                     */}

                                    <Box
                                        pad={{ top: 'xsmall' }}
                                        align='center'
                                    >
                                        <Text><strong><h4>
                                            Bora,<br />
                                                cortar o cabelo e aparar a barba :)
                                            </h4></strong></Text>
                                    </Box>

                                    <Box>
                                        <Box
                                            align="center"
                                            pad='medium'
                                            onClick={() => { setGeoMapNow(true); setColorIcon("grey") }}
                                            hoverIndicator>
                                            <FiMapPin size='30' color={geoMapNow ? '#FFAA15' : '#FFF'} />
                                        </Box>

                                        {showBot && (
                                            <Tawkto />
                                        )}
                                        <Box
                                            pad={{ top: 'xsmall' }}
                                            align='center'
                                        >
                                            <Text
                                                color={geoMapNow ? '#FFAA15' : '#FFF'}>
                                                <strong><h3> Nova Friburgo RJ</h3></strong></Text>
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                        </Box>
                    </Box>
                    <Map
                        // center={[-22.2938638, -42.5324806]}
                        center={[latMap, longMap]}
                        zoom={16}
                        style={{ width: '100%', height: '100%' }}
                    >

                        <TileLayer url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                        />
                        {/* satellite-v9 ou dark vc pode estilizar na HP */}

                        {barbershops.map(barbershop => {



                            const mapIconAvatar = Leaflet.icon({
                                iconUrl: markII,
                                iconRetinaUrl: `${barbershop.avatar}`,
                                iconSize: [58, 58],
                                iconAnchor: [29, 68],
                                popupAnchor: [170, 2]
                            })

                            const mapIconAvatarOff = Leaflet.icon({
                                iconUrl: markI,
                                iconRetinaUrl: markIOff,
                                iconSize: [58, 58],
                                iconAnchor: [29, 68],
                                popupAnchor: [170, 2]
                            })

                            return (
                                (barbershop.activated === true ? (
                                    <Marker
                                        //  icon={mapIcon}
                                        icon={mapIconAvatar}
                                        position={[barbershop.latitude, barbershop.longitude]}
                                        key={barbershop.id}>
                                        <Popup closeButton={false} minWidth={240} maxHeight={240} className="map-popup">
                                            <Box
                                                animation="slideRight"
                                                background="dark-1"
                                                pad={{ vertical: "2px" }}
                                                direction="row"
                                                justify="between"
                                                align="center"
                                                alignContent="between"
                                                alignSelf="center"
                                                width="full"
                                            >
                                                <Box
                                                    animation="pulse"
                                                    justify="center"
                                                    align="center"
                                                    alignContent="center"
                                                    alignSelf="center"
                                                    pad={{ horizontal: "xsmall" }}
                                                >
                                                    <Link to={`/barbershop/${barbershop.id}`}>
                                                        <BtnStyle iconBtnBkcolor={barbershop.colorbarber} icon={16} />
                                                    </Link>
                                                </Box>
                                                <Box
                                                    width="full"
                                                    justify="center"
                                                    align="center"
                                                    alignContent="center"
                                                    alignSelf="center"
                                                    onClick={() => { }}
                                                >
                                                    {barbershop.name}
                                                </Box>
                                            </Box>
                                        </Popup>
                                    </Marker>
                                ) : (
                                    <Marker
                                        //  icon={mapIcon}
                                        icon={mapIconAvatarOff}
                                        position={[barbershop.latitude, barbershop.longitude]}
                                        key={barbershop.id}
                                    >
                                        <Popup closeButton={false} minWidth={240} maxHeight={240} className="map-popup">
                                            <Box
                                                animation="slideRight"
                                                background="dark-1"
                                                pad={{ vertical: "2px" }}
                                                direction="row"
                                                justify="between"
                                                align="center"
                                                alignContent="between"
                                                alignSelf="center"
                                                width="full"
                                            >
                                                <Box
                                                    animation="pulse"
                                                    justify="center"
                                                    align="center"
                                                    alignContent="center"
                                                    alignSelf="center"
                                                    pad={{ horizontal: "xsmall" }}
                                                >
                                                    <Link to={`/barbershop/${barbershop.id}`}>
                                                        <BtnStyle icon={16} />
                                                    </Link>
                                                </Box>
                                                <Box
                                                    width="full"
                                                    justify="center"
                                                    align="center"
                                                    alignContent="center"
                                                    alignSelf="center"
                                                    onClick={() => { }}
                                                >
                                                    {barbershop.name}
                                                </Box>
                                            </Box>
                                        </Popup>
                                    </Marker>
                                ))
                            )
                        })}
                    </Map>

                </div>
            </Main>
        </Box>
    );
}
//<TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
export default BarbershopMap;