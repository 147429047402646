import React, { useState, FormEvent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from "../services/api";
import { useQrEncode, useQrDecode } from 'react-qr-hooks';
import { Box, Button, DropButton, Form, Grommet, Main, Text } from 'grommet';
import { TiTick, TiTicket } from 'react-icons/ti';
import { FiCheck } from 'react-icons/fi';

interface BarbershopParams {
  id: string;
}

const openSchedule = localStorage.getItem('onSchedule');

export default function NewProfissionalSchedule({ color_Schedule = 'status-warning', txt_schedule = '', data_schedule = '', id_user_schedule = '', colorBtn = '', viewSoma = 0 }) {

  const encoded = useQrEncode(
    data_schedule + txt_schedule/* object with options (if needed) */
  );

  const decoded = useQrDecode(`${encoded}`);

  const params = useParams<BarbershopParams>();
  const [schedule_date, setScheduleDate] = useState('');
  const [viewQr, setViewQr] = useState(false);

  const [professional_id, setProfessional_id] = useState(params.id);
  //const [user_id, setUser_id] = useState(id_user_schedule);
  const [user_id, setUser_id] = useState(id_user_schedule);

  const history = useHistory();

  const GremlinDropButton = () => (
    <DropButton
    
      alignSelf="center"
      margin={{ vertical: 'small' }}
      dropContent={renderItems()}
      dropProps={{ align: { left: 'right' } }}
    >
      <MenuItem />
    </DropButton>
  );

  const renderItems = () => (
    <Box
    
      align="center"
      justify="center"
      pad="small"
      background="dark-1">
      <Text>{data_schedule} | R$ {viewSoma},00</Text>
      <Text>{txt_schedule}</Text>
    </Box>
  );

  const MenuItem = () => (
    <Box
     pad={{ horizontal: 'medium' }}>
      <Box height="36px" width="36px" align="center">
        <TiTicket
          size="45"
          color={color_Schedule} />
      </Box>
    </Box>
  );

  async function CleanerInput() {
    await setScheduleDate('');
    await setProfessional_id('');
    //await setProfessional_id(params.id);
    //await setUser_id(idBarber);
  }



  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const data = {
      schedule_date,
      professional_id,
      user_id
    };

    await api.post('schedules', data);
    alert(`Schedules ${schedule_date} :)`);
    CleanerInput();

    history.push(`/professional/${params.id}`);
    console.log({
      schedule_date,
      professional_id,
      user_id
    })
  }

  async function createScheduleFc() {
    const data = {
      schedule_date,
      professional_id,
      user_id
    };


    await api.post('schedules', data);
    alert(`Schedules ${schedule_date} :)`);
    CleanerInput();

    history.push(`/professional/${params.id}`);
    console.log({
      schedule_date,
      professional_id,
      user_id
    })
  }

  return (
    <Grommet>
      <Main>
        <Form onSubmit={handleSubmit}>
          <Box
            onMouseMove={() => { }}
            pad={{ vertical: "medium" , horizontal: "small"}}
            onClick={() => {}}
            align="center" justify="center"
            direction="row-responsive"
            hoverIndicator={{ opacity: 'weak', color: color_Schedule }}
          >
            <Box
           
              onLoad={() => (localStorage.getItem('onSchedule'))}
              className="ticketQr"
              align="center" justify="center"
            >
              {(openSchedule === 'open') ? (
                <>
                  <GremlinDropButton />
                </>
              ) : (
                <>
                  close
          </>
              )}
            </Box>
            <Box
              alignSelf='center'
              alignContent='center'
              align="center" justify="center"
              pad={{ vertical: "medium", horizontal: "large" }}
              direction="row"
              onClick={() => { setScheduleDate(txt_schedule + data_schedule); createScheduleFc() }}
              onFocus={e => setScheduleDate(txt_schedule + data_schedule)}
            >
              <Text
                color={color_Schedule}
              > <h3>Confirmar agendamento  </h3> </Text>

              <Box
              
                pad={{ horizontal: 'small' }}>
                <FiCheck

                  color={color_Schedule} size={35} />
              </Box>
            </Box>
          </Box>
        </Form>
      </Main>
    </Grommet>
  )
}