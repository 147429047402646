import React, { useState, useEffect, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from "../services/api";
import { Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, DateInput, Distribution, DropButton, Form, Grommet, Layer, List, Main, MaskedInput, Menu, Text, TextInput } from 'grommet';
import BtnStyle from './BtnStyleThemeType';
import UpdateBarberUser from './UpdateBarberUser';
import { ThemeType, dark, grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import { FiUser } from 'react-icons/fi';
import { FaRedhat } from 'react-icons/fa';

interface BarbershopUser {
  id: number;
  name: String;
  access: number;
  email: String;
  pix: String;
  cep: String;
  avatar: String;
  andress: String;
  likeUser: number;
  activated: true;
  scheduleView: Array<{
    id: number;
    schedule_date: string;
    professional_id: number;
    user_id: number;
  }>;
}

const customToggleTheme = {
  global: {
    colors: {
      'toggle-bg': 'dark-1',
      'toggle-knob': 'white',
      'toggle-accent': 'accent-2',
    },
  },
  checkBox: {
    border: {
      color: {
        light: 'toggle-bg',
      },
    },
    color: {
      light: 'toggle-knob',
    },
    check: {
      radius: '2px',
    },
    hover: {
      border: {
        color: undefined,
      },
    },
    toggle: {
      background: { light: 'toggle-bg' },
      color: {
        light: 'toggle-knob',
      },
      size: '56px',
      knob: {
        extend: `
          top: -4px;
          box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12),
           0px 2px 2px 0px rgba(0,0,0,0.24);
        `,
      },
      extend: (colorBr: any) => `
        height: 24px;
        background: ${colorBr};
      `,
    },
    gap: 'xsmall',
    size: '27px',
  },
};

const customThemeUpBarber: ThemeType = {
  global: {
    font: {
      family: 'Arial',
    },
  },
  button: {

    border: {
      radius: undefined,
      color: 'dark-3',
    },
    disabled: {
      color: '#fe2693',
      opacity: '.1',
      border: {
        color: '#000000',
        width: '10px',
      },
    },

    primary: {
      color: 'dark-2',
    },
    extend: (txtBtn: any) => {
      let extraStyles = '';
      if (txtBtn.primary) {
        extraStyles = `
          text-transform: uppercase;
        `;
      }
      return `
        color: white;
        font-size: 22px;
        font-weight: bold;

        ${extraStyles}
      `;
    },
  },
};
export default function AdmUsers({
  idUser = 21,
  labelUpBtnUser = 'Update User',
  colorProf = '',
  instaDefault = '',
  emailDefault = '',
  avatarDefalt = 'https://i.ibb.co/9G8fq68/top.png',
  pixCode = 'PIX', barberOpen_hours = '9 às 18h', profIconSize = 35, profIcon = 8,
  profBnTxt = '', profColor = '#DDFF47' }) {

  useEffect(() => {

    api.get('users').then(response => {
      setBarbershopUsers(response.data);
    });

  }, []);

  const [barbershopUsers, setBarbershopUsers] = useState<BarbershopUser[]>([]);
  const [onOpen, setOnOpen] = useState(false);
  const [colorbarber, setColorbarber] = useState();

  if (!barbershopUsers) {
    return (
      <Main>
        <Box pad={{ horizontal: '100px', vertical: '160px' }} align="center" justify="center" direction="row">
          <div className="preloader17">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Box></Main>);
  }
  return (
    <Main>
      <Button
        onClick={() => { setOnOpen(!onOpen) }}
        icon={<FaRedhat
          size='30'
        />
        }
        label={labelUpBtnUser}
      />
      {onOpen && (
        <Grommet theme={deepMerge(dark, customThemeUpBarber)}>
          <Layer
            modal
            position='top'
            onClickOutside={() => setOnOpen(!onOpen)}
            onEsc={() => setOnOpen(!onOpen)}
          >
            <Box
              background='#000'
              align='end'
              width='full'
              pad={{ horizontal: '10px' }}
              margin={{ top: '10px', bottom: '-125px' }}
            >
              <a onClick={() => setOnOpen(!onOpen)} ><BtnStyle iconBtnBkcolor={colorbarber} icon={3} /></a>

              <Box
                animation='slideUp'
                pad='small'
                align='center'
                direction='column'>

                {barbershopUsers.map(usershop => {
                  return (

                    <Box
                      pad='xsmall'
                      justify='between'
                      direction='row'
                      width='600px'>

                      <Box
                        width='80px'
                        align='center'
                        alignContent='center'
                        alignSelf='center'
                        pad={{ horizontal: 'xsmall' }}
                      >
                        <Text>
                          {usershop.id}
                        </Text>
                      </Box>
                      <Box
                        align='center'
                        alignContent='center'
                        alignSelf='center'
                        pad={{ horizontal: 'xsmall' }}
                      >
                        <Text>
                          {usershop.name}
                        </Text>
                      </Box>

                      {/**listar de users */}
                      <UpdateBarberUser
                        sizeIconBarber={40}
                        iconBarber={4}
                        idUserBarber={usershop.id}
                        nameBarberUser={usershop.name as any}
                        avatarUserBarber={usershop.avatar as any}
                        cepUserBarber={usershop.cep as any}
                        pixUserBarber={usershop.pix as any}
                        activatedUserBarber={usershop.activated as any}
                        emailBarber={usershop.email as any}
                        accessUserBarber={usershop.access as any}
                        andressBarber={usershop.andress as any}
                        likeUserBarber={usershop.likeUser as any}
                      />
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Layer>
        </Grommet>
      )}
    </Main>
  )
};