import React, { useEffect, useState } from 'react';
import { FiChevronsLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import mapMarkerImg from '../images/logo_barbershop.svg';
import { Box, Button, Grommet, Image, ThemeType } from "grommet";
import Logout from '../pages/LogoutSideBar';
import QrCard from '../pages/CreateQrCode';
import Tawkto from '../pages/TawkTo';
import UserCreate from '../pages/CreateUser';
import UserViews from "../pages/UserViews";

const customTheme: ThemeType = {

  global: {
    colors: {

    },
    control: {

    },
    font: {
      family: 'Arial',
    },
    drop: {

    },
    hover: {
      color: '#fff',
    },
  },
  radioButton: {
    check: {
      radius: '8px'
    },
  },
  button: {
    border: {
      radius: undefined,
      color: 'dark-3',
    },
    hover: {
      color: '#fff',
    },
    disabled: {
      color: '#fe2693',
      opacity: '.1',
      border: {
        color: '#000000',
        width: '10px',
      },
    },

    primary: {
      color: 'dark-2',
    },
    extend: (txtBtn: any) => {
      let extraStyles = '';
      if (txtBtn.primary) {
        extraStyles = `
          text-transform: uppercase;
        `;
      }
      return `
        color: white;
        font-size: 22px;
        font-weight: bold;
        ${extraStyles}
      `;
    },
  },
};

const AppBar = (props: any) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='transparent'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation=''
    style={{ zIndex: '1' }}
    {...props}
  />
);

const idBarber = localStorage.getItem('idBarbershop');

export default function Sidebar({ color = "#0000006c" }) {

  const [userId, setUserId] = useState(0);

  useEffect(() => {
    setUserId(idBarber as any);

    const btnOpen = localStorage.getItem('btnOpen');
    if (btnOpen as any > 0) {
      setShowBot(true);
    }
    if (userId < 1 as any) {
      setShowCreateUser(true);
    }
    else{
      setShowCreateUser(false);
    }

  });

  function updateUserSing (){
    setUserId(idBarber as any);
    if (userId <= 1 as any) {
      setShowCreateUser(true);
    }
    else{
      setShowCreateUser(false);
    }
  }

  const [pixPagamento, setPixPagamento] = useState('00020126640014BR.GOV.BCB.PIX0118nettobkp@gmail.com0220barbershopapp.com.br52040000530398654046.505802BR5925DOMINGOS DE OLIVEIRA NETT6009SAO PAULO622605226lNnNiaEhExCvzau4gFaFV63041E9E');

  const [showSidebar, setShowSidebar] = useState(true);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showBot, setShowBot] = useState(false);
  const UserEmail = localStorage.getItem('userEmail');
  const { goBack } = useHistory();

  return (
    <Grommet theme={customTheme} >

      <Box
      onMouseMove={() => {updateUserSing()}}
       className="barber-sidebar"
        background={color}
        pad={{ top: '25px' }}
        alignContent="center" alignSelf="center"
        height="76px" width="full" >

        <AppBar>

          <Box 
          onMouseMove={() => {updateUserSing()}}
          className="barber-sidebar-logo"
            direction="row" justify="between"
            alignSelf="center" alignContent="center"
            align="center" pad='small'>
            <Box
              title="User Barbershop´s >"
              direction="row" justify="between"
              alignSelf="start" alignContent="center"
              align="center" pad='small'>
              <Link to="/app/nf" title="Início Mapa">
                <Image height='90'
                  src={mapMarkerImg} alt="BarbershopApp" />
              </Link>
            </Box>

          </Box>


          <Box direction="row" justify="between" alignSelf="center"
            alignContent="center" align="center" pad='small'>
            <Button
              title=" << Voltar :) "
              icon={<FiChevronsLeft
              />}
              onClick={() => { goBack() }}
              hoverIndicator={{ color: "status-warning", opacity: "strong", }} />

          </Box>

          {UserViews(UserEmail as any)}


          <Box direction="row" justify="between"
            alignSelf="center" alignContent="center"
            align="center" pad='small'>

              {showCreateUser &&(
                <UserCreate iconService={20}/>
              )}
              
            <Logout />

            {showBot && (
              <Tawkto />
            )}
          </Box>

        </AppBar>

        <Box direction='row' flex overflow={{ horizontal: 'auto', vertical: 'auto' }}>
          {showSidebar && (
            <Box
              width='small'
              height="xsmall"
              background='transparent'
              elevation='small'
              align='center'
              justify='center'
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <Box
                width='small'
                pad="xxsmall">
                <QrCard txt_label="R$ 6,50" txt_schedule={pixPagamento} />
              </Box>
            </Box>
          )}
        </Box>

      </Box>
    </Grommet>
  );
}