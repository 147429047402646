import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FiCamera } from "react-icons/fi";
import api from "../services/api";
import BtnStyle from './BtnStyleThemeType';
import { Avatar, Box, Grommet, Layer, Main, MaskedInput, TextInput } from 'grommet';
import { dark } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

interface BarbershopParams {
  id: string;
}
export default function NewProfissionals({
  nameDefault = '',
  colorProf = '',
  instaDefault = '',
  emailDefault = '',
  avatarDefalt = 'https://i.ibb.co/9G8fq68/top.png',
  pixCode = 'PIX', barberOpen_hours = '9 às 18h', profIconSize = 35, profIcon = 8,
  profBnTxt = '', profColor = '#DDFF47' }) {

  const [onOpen, setOnOpen] = useState(false);

  const params = useParams<BarbershopParams>();
  const [name, setNameProfissa] = useState('');
  const [email, setProfissaEmail] = useState(emailDefault);
  const [whatsapp, setProfissaWhatsapp] = useState('00 00000 0000');
  const [avatar, setAvatar] = useState(avatarDefalt);
  const [pix, setPix] = useState(pixCode);
  const [colorprof, setColorprof] = useState(colorProf);
  const [instagram, setProfissaInstagram] = useState('https://www.instagram.com/barbershopapp1');
  const [opening_hours, setProfissaOpening_hours] = useState(barberOpen_hours);
  const [open_on_weekends, setProfissaOpen_on_weekends] = useState(false);
  const [activated, setProfissaActivated] = useState(false);
  const [addDetail, setAddDetail] = useState(false);
  const [barbershop, setProfissaBarbershop] = useState('');
  const [imagesProf, setImages] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);

  const history = useHistory();

  async function CleanerInput() {
    await setNameProfissa('');
    await setProfissaEmail(emailDefault);
    await setProfissaWhatsapp('00 00000 0000');
    await setColorprof(colorProf);
    await setProfissaActivated(false);
    await setProfissaInstagram('https://www.instagram.com/barbershopapp1');
    await setProfissaOpening_hours(barberOpen_hours);
  }
  function handleSelectImages(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }
    const selectImages = Array.from(event.target.files);
    setImages(selectImages);

    const selectedImagesPreview = selectImages.map(imagesProf => {
      return URL.createObjectURL(imagesProf);
    });

    setPreviewImages(selectedImagesPreview);
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const data = new FormData();

    data.append('barbershop', barbershop);
    data.append('name', name);
    data.append('email', email);
    data.append('whatsapp', whatsapp);
    data.append('instagram', instagram);
    data.append('avatar', avatar);
    data.append('pix', pix);
    data.append('colorprof', colorprof);
    data.append('opening_hours', opening_hours);
    data.append('open_on_weekends', String(open_on_weekends));
    data.append('activated', String(activated));

    imagesProf.forEach(imagesProf => {
      data.append('imagesProf', imagesProf);
    })
    await api.post('professionalsim', data);
    alert(`Profissional ${name} cadastrado.`);
    CleanerInput();

    history.push(`/barbershop/${params.id}`);
    console.log({
      barbershop,
      name,
      email,
      whatsapp,
      instagram,
      colorprof,
      opening_hours,
      open_on_weekends,
      activated
    })
  }

  return (
    <Main>
      <a onClick={() => { setOnOpen(!onOpen) }}>
        <BtnStyle iconBtnBkcolor={profColor} iconsize={profIconSize} icon={profIcon} />
      </a>
      {onOpen && (
        <Grommet theme={deepMerge(dark)}>
          <Layer
            modal
            position='center'
            onClickOutside={() => setOnOpen(!onOpen)}
            onEsc={() => setOnOpen(!onOpen)}
          >
            <Box
              justify='end'
              align='end'
              width='full'
              pad={{ horizontal: '10px' }}
              margin={{ top: '10px', bottom: '-125px' }}
            >
              <a onClick={() => setOnOpen(!onOpen)} ><BtnStyle iconBtnBkcolor={profColor} icon={3} /></a>
            </Box>
            <Box
              align='center'
              animation='slideUp'
            >
              <Box
                pad={{ horizontal: 'small' }}
                align='center'
                direction='column'>
                <form onSubmit={handleSubmit} className="create-profissional-form">
                  <Box
                    onClick={() => { setAddDetail(!addDetail) }}
                    align='center'>
                    <Avatar size='100px' src={avatar} />
                  </Box>
                  <Box
                    pad={{ vertical: 'small' }}
                    width='full'>
                    <TextInput
                      title='Profissional '
                      placeholder={`${nameDefault} adicione, um Barbeiro ou profissional`}
                      value={name}
                      onChange={e => setNameProfissa(e.target.value)}
                      onBeforeInput={e => setProfissaBarbershop(params.id)}
                    />
                  </Box>

                  <Box>
                    <MaskedInput
                      title='whatsapp '
                      placeholder='whatsapp '
                      value={whatsapp}
                      onChange={e => setProfissaWhatsapp(e.target.value)}
                    />
                  </Box>
                  <Box pad={{ vertical: 'small' }}>
                    <MaskedInput
                      mask={[{ fixed: 'https://instagram.com/' }, { regexp: /^.*$/ }]}
                      title='@instagram.com'
                      placeholder='instagram.com/'
                      value={instagram}
                      onChange={e => setProfissaInstagram(e.target.value)}
                    />
                  </Box>
                  {addDetail && (
                    <Box
                      animation='slideUp'
                    >
                      <Box
                        width='full'>
                        <TextInput
                          title='e-mail de acesso'
                          placeholder='e-mail de acesso'
                          value={email}
                          onChange={e => setProfissaEmail(e.target.value)}
                        />
                      </Box>
                      <Box pad={{ vertical: 'small' }}>
                        <MaskedInput
                          mask={[{ fixed: 'https://i.ibb.co/' }, { regexp: /^.*$/ }]}
                          title='Avatar - siga as instruções :)'
                          placeholder='Avatar - siga as instruções :)'
                          value={avatar}
                          onChange={e => setAvatar(e.target.value)}
                        />
                      </Box>

                      <Box direction='row'
                        width='full'>
                        <TextInput
                          placeholder='Seu código Pix'
                          title='Seu código Pix'
                          value={pix}
                          onChange={e => setPix(e.target.value)}
                        />
                        <Box pad={{ horizontal: 'xsmall' }} />
                        <TextInput

                          placeholder='9 às 18h'
                          title='Horário de atendimento'
                          value={opening_hours}
                          onChange={e => setProfissaOpening_hours(e.target.value)}
                        />
                         <Box pad={{ horizontal: 'xsmall' }} />
                        <Box
                        background={colorprof}
                        round='xsmall'
                         >
                        <TextInput
                          disabled={activated ? false : true}
                          placeholder='color prof HEX #FF0 '
                          title='color prof'
                          value={colorprof}
                          onChange={e => {setColorprof(e.target.value) ; setProfissaActivated(false)}}
                        />
                        </Box>
                      </Box>


                      <Box
                        pad={{ vertical: 'small' }}
                        width='full'>

                      </Box>
                      <div className="input-block">

                        <div className="images-container">
                          {previewImages.map(imagesProf => {
                            return (
                              <img key={imagesProf} src={imagesProf} alt={name} />
                            )
                          })}
                          <label htmlFor="image[]" className="new-image">
                            <FiCamera size={24} color="#000" />
                          </label>
                        </div>

                        <input multiple onChange={handleSelectImages} type="file" id="image[]" />
                      </div>
                    </Box>
                  )}

                  {!addDetail && (
                    <>
                      <Box
                        pad={{ horizontal: 'small' }}
                        align='center'
                      >
                      </Box>
                      <Box
                        direction='row'
                        justify='between'
                        width='full'
                        pad={{ horizontal: 'medium' }}>
                        <Box
                          pad='small'
                          alignContent='center'
                          width='full'
                          align='center'
                          round={{ corner: 'left', size: '8px' }}
                          onClick={() => setProfissaOpen_on_weekends(true)}
                          background={open_on_weekends ? profColor : 'dark-1'}>
                          <strong>Final de semana SIM</strong>
                        </Box>
                        <Box
                          pad='small'
                          alignContent='center'
                          align='center'
                          width='full'
                          round={{ corner: 'right', size: '8px' }}
                          onClick={() => setProfissaOpen_on_weekends(false)}
                          background={!open_on_weekends ? profColor : 'dark-1'}>
                          <strong>Final de semana NÃO</strong>
                        </Box>
                      </Box>
                    </>
                  )}
                  <Box
                    align='center'
                    title='Clique em cadastrar barbeiro para confimar:)'
                  >
                    <BtnStyle txtLabel={profBnTxt} iconBtnBkcolor={profColor} iconsize={profIconSize} icon={profIcon} />
                  </Box>
                </form>
              </Box>
            </Box>
          </Layer>
        </Grommet>
      )}
    </Main>
  )
}