import React from 'react';
import { FiLogOut  } from 'react-icons/fi';
import {  Button } from "grommet";

import fire from "../services/fire";
const btnLogout = ({ color = "#fff", size = '28' }) => {
    const handleLogout = () => {
        fire.auth().signOut();
        localStorage.setItem('userEmail', '');
      };
    return (
        <Button
        title="< Logout"
              icon={<FiLogOut 
                color={color}
                size={size}
              />}
              onClick={handleLogout}
              hoverIndicator={{ color: "status-warning", opacity: "strong", }} />

    );
};
export default btnLogout;

