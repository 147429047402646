/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { FiAward, FiXCircle, FiMinusCircle, FiPlusCircle, FiScissors, FiCreditCard } from "react-icons/fi";
import { useHistory, useParams } from 'react-router-dom';
//import '../styles/pages/barbershop.css';//
import Sidebar from "../components/Sidebar";
import CreateService from "./CreateService";
import CreateLikes from "./CreateLikes";
import CreateQrCode from "./CreateQrCode";
import ViewQrCode from "./ViewQrCode";
import ViewQrCodeShare from "./ViewQrCodeShare";
import DeleteSchedule from "./DeleteSchedule";
import TipContentQr from "./TipContentQr";
import TipContent from "./TipContent";
import DeleteService from "./DeleteServiceProfessional";
import api from "../services/api";
import '../css/deleteAtt.css';
import { Box, Button, Card, CardBody, CardFooter, CardHeader, Text, Distribution, grommet, Grommet, DropButton, DateInput, Image, Main, Avatar, Heading, Carousel, ThemeType } from "grommet";
import { Clock, Schedule } from "grommet-icons";
import { Tip } from "grommet/components/Tip";
import BtnStyle from "./BtnStyleThemeType";
import iconCard from '../../src/images/01barbershh.png';
import UpdateProf from "./UpdateProf";
import UpdateAgendaProf from "./UpdateAgendaProf";
import TicketSchedule from "./TicketSchedule";
import { deepMerge } from "grommet/utils";

interface Barbershop {

  id: number;
  latitude: number;
  longitude: number;
  name: string;
  email: string;
  andress: string;
  opening_hours: string;
  open_on_weekends: string;
  activated: string;

  attributes: Array<{
    id: number;
    name: string;
  }>;

  professionals: Array<{
    id: number;
    name: string;
    email: string;
    whatsapp: number;
    instagram: string;
    opening_hours: string;
    open_on_weekends: boolean;
    activated: boolean;
  }>;

  images: Array<{
    id: number;
    url: string;
  }>;
}
interface BarbershopParams {
  id: string;
}
interface BarbershopUser {
  id: number;
  name: string;
}
interface ProfBarbershop {
  id: number;
  barbershop: number;
  name: string;
  email: string;
  whatsapp: number;
  instagram: string;
  avatar: string;
  pix: string;
  colorprof: string;
  opening_hours: string;
  open_on_weekends: boolean;
  activated: boolean;
  scheduleView: Array<{
    id: number;
    schedule_date: string;
    professional_id: number;
    user_id: number;

  }>;

  servicesView: Array<{
    id: number;
    category: string;
    service_value: number;
    service_time: number;
    professional_id: number;
    user_id: number;
  }>;
  imagesView: Array<{
    id: number;
    url: string;
  }>;
}
const idBarber = localStorage.getItem('idBarbershop');

export default function Professional({ colorBarber = 'status-warning' }) {


  const [userId, setUserId] = useState(0);

  const addEmailLogin = localStorage.getItem('userEmail');

  const { goBack } = useHistory();

  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const params = useParams<BarbershopParams>();
  const [professional, setProfessional] = useState<ProfBarbershop>();
  const [barbershopUser, setBarbershopUser] = useState<BarbershopUser>();
  const [txtSchedule, setTxtSchedule] = useState('');
  const [addProfissa, setAddProfissa] = useState(false);
  const [addService, setAddService] = useState(false);
  const [addAward, setAddAward] = useState(false);
  const [viewSchedule, setViewSchedule] = useState(true);
  const [addDateSchedule, setAddDateSchedule] = useState('ticket');
  const [addNumAward, setAddNumAward] = useState(11);
  const [agendaOpen, setAgendaOpen] = useState(false);
  const [viewVisitCard, setViewVisitCard] = useState(false);
  const [qrView, setQrView] = useState(false);
  const [somaAdd, setSomaAdd] = useState(true);
  const [onSchedule, setOnSchedule] = useState(localStorage.getItem('onSchedule'));
  //4df8f8 top
  //d63737
  const [firstColor, setFirstColor] = useState(colorBarber);
  const [secondColor, setSecondColor] = useState('#dabb9e');
  const [statuswarning, setStatuswarning] = useState('#FFAA15');
  const [ligthColor, setLigthColor] = useState('#FFFFFF');
  const [sinalizaColor, setSinalizaColor] = useState('#e72a2a');
  const [colorBtnContaOk, setColorBtnContaOk] = useState('#fff');
  const [alertColor, setAlertColor] = useState('#0ae678');
  const [soma, setSoma] = useState(0);

  const customTheme: ThemeType = {

    carousel: {

      animation: {
        duration: 600,
      },
      icons: {
        color: professional?.colorprof,
      },
      disabled: {
        icons: {
          color: professional?.colorprof,
        },
      },
    },
    global: {

      focus: {
        border: {
          color: professional?.colorprof,
        },
        shadow: {
          color: 'white',
        },
      },
      font: {
        family: 'Arial',
      },
      hover: {
        color: professional?.colorprof,
      },
    },

    button: {
      border: {
        radius: undefined,
        color: 'dark-3',
      },
      hover: {
        color: professional?.colorprof,
      },
      disabled: {
        color: '#ffffff48',
        opacity: '.1',
        border: {
          color: '#000000',
          width: '10px',
        },
      },

      primary: {
        color: 'dark-2',
      },
      extend: (txtBtn: any) => {
        let extraStyles = '';
        if (txtBtn.primary) {
          extraStyles = `
            text-transform: uppercase;
          `;
        }
        return `
          color: white;
          font-size: 22px;
          font-weight: bold;
  
          ${extraStyles}
        `;
      },
    },
  };

  function AwardUpDate() {
    if (onSchedule === 'close') {
      setSoma(0);
    }

    if (addNumAward < 11) {
      setAddAward(true);
    }
    else {
      setAddAward(false);
    }

  }

  function AddSub(totalSoma: any) {
    if (soma <= 0) {
      setSoma(0);
      setViewSchedule(true);
      setColorBtnContaOk(ligthColor);
      setSomaAdd(true);
      setSoma(0);
    }

    if (somaAdd) {
      setColorBtnContaOk(ligthColor);
      setSoma(soma + totalSoma);
      if (addDateSchedule === 'ticket') {
        setColorBtnContaOk(statuswarning);
      } else {
        setColorBtnContaOk(alertColor);
      }
    }
    else {
      if (soma > 0) {
        setSoma(soma - totalSoma);
      }
      else {
        setSoma(0);
      }
    }
  }

  function AttributeUpDate() {
    api.get(`user/${idBarber}`).then(response => {
      setBarbershopUser(response.data);
    });

    setUserId(idBarber as any);

    api.get(`professional/${params.id}`).then(response => {
      setProfessional(response.data);
      // setUserId(`${idBarber}`);
      //setUserId(`${params.id}`); eu alterei aqui verificar se deu ruim,...
      setAddNumAward(userId as any);
    });
    AwardUpDate();
  }
  useEffect(() => {
    api.get(`user/${idBarber}`).then(response => {
      setBarbershopUser(response.data);
    });

    setUserId(idBarber as any);

    api.get(`professional/${params.id}`).then(response => {
      setProfessional(response.data);
    });

  }, [params.id]);


  if (!professional) {
    return (
      <Main>
        <Box pad={{ horizontal: '100px', vertical: '160px' }} align="center" justify="center" direction="row">
          <div className="preloader17">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Box></Main>);
  }
  if (professional.imagesView.length === 0) {
  }

  return (

    <div id="page-orphanage">
      <Sidebar />

      <main onMouseOut={AttributeUpDate}>
        <Box
          margin={{ top: '80px' }}
          className="orphanage-details">
          {professional.imagesView.length === 0 ? (
            <Image src={iconCard} />
          ) : (
            <Grommet theme={deepMerge(customTheme)}>
              <Box
                // fica top com a cor      background={barbershop.colorbarber}
                margin={{ bottom: '10px' }}
              >
                <Carousel
                  className='carousel'
                  controls={true} play={3500}>
                  {professional.imagesView.map(img => (
                    <Box height="400px" width="700px">
                      <Image key={img.id}
                        src={img.url}
                        style={{ width: '700px', height: '500px' }} fit="cover"
                        fallback={iconCard}
                      />
                    </Box>
                  ))}
                </Carousel>
              </Box>

            </Grommet>
          )}

          {addProfissa ? (
            <Box
              animation='slideLeft'
              margin={{ top: '168px' }}
              background='dark-1'
              className="btn_social_Prof">
              <Box direction="column" >

                <a title={`Fechar edição agenda:) ${professional.name}`}
                  onClick={() => { setAddProfissa(!addProfissa); setAgendaOpen(false) }}

                >
                  <BtnStyle iconBtnBkcolor={professional.colorprof} icon={6} /></a>

                <a
                  onClick={() => { setQrView(!qrView) }}
                  title={`Serviços :)`}
                  target="_blank"
                >

                  <UpdateAgendaProf
                    colorbarberTxtProf={professional.colorprof}
                    iconProf={22}
                    avatarProf={professional.avatar}
                    nameProf={professional.name}
                    profId={professional.id}
                  />
                </a>

                <UpdateProf
                  iconProf={4}
                  sizeIconProf={35}
                  tbnTxtProf=''
                  colorbarberTxtProf={professional.colorprof}
                  nameProf={professional.name}
                  emailProf={professional.email}
                  instagramProf={professional.instagram}
                  avatarProf={professional.avatar}
                  pixProf={professional.pix}
                  whatsappProf={professional.whatsapp as any}
                  open_on_weekendsProf={professional.open_on_weekends}
                  opening_hoursProf={professional.opening_hours}
                  activatedProf={professional.activated}

                />

                <a title={`Barcode Limpar:)`}
                >
                  <CreateService
                    coloriconService={professional.colorprof}
                    iconService={31} />
                </a>

              </Box>
            </Box>
          ) : (
            <Box
              className="btn_social_Prof">
              {!viewVisitCard ? (
                <Box
                  direction="column"
                  animation='slideRight'
                  background='dark-1'
                >

                  {addEmailLogin === professional.email ? (
                    <a title={`Editar seu cadastro :) 
                  ${professional.name}`}
                      onClick={() => {
                        setAddProfissa(!addProfissa);
                        setAddService(false);
                        setAgendaOpen(true)
                      }}
                    >
                      <BtnStyle
                        iconBtnBkcolor={professional.colorprof}
                        icon={4} /></a>
                  ) : (
                    <a
                      onClick={() => { setViewVisitCard(!viewVisitCard) }}
                      title={`Compartilhar, Cartão Visita - ${professional.name}`}
                      target="_blank" rel="noopener noreferrer" >
                      <BtnStyle iconBtnBkcolor={professional.colorprof} icon={30} /></a>
                    //<a title={`e-mail:) ${professional.name}`} target="_blank" rel="noopener noreferrer" href={`mailto:${professional.email}`}>
                    //<BtnStyle icon={14} /></a>
                  )}

                  <a title="Tabela de Preços" onClick={() => {
                    localStorage.setItem('onSchedule', 'open');
                    setAgendaOpen(!agendaOpen)
                  }} rel="noopener noreferrer" href="#schedule'">
                    <BtnStyle
                      iconBtnBkcolor={professional.colorprof}
                      href={'#schedule'} icon={32} /></a>

                </Box>
              ) : (
                // Menu Qr compartilhar Card Visit
                <Box
                  margin={{ top: '-130px' }}
                  animation='slideLeft'
                  background='dark-1'
                  direction='column'>
                  <Box
                    onClick={() => { setViewVisitCard(!viewVisitCard) }}
                  >
                    <ViewQrCodeShare
                      zap_share={`${professional.whatsapp}`}
                      name_share={`${professional.name}`}
                      txt_link_share={`https://barbershopapp.com.br/professional/${professional.id}`} />

                  </Box>
                  <Box
                    justify='between'
                    direction='row'>
                    {professional.whatsapp === '00 00000 0000' as any ? (
                      <></>
                    ) : (
                      <Box>
                        <a title={`whatsapp:) ${professional.name}`} target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=${professional.whatsapp}&text=${professional.name}. Quero%20cortar%20o%20cabelo!`}>
                          <BtnStyle iconBtnBkcolor={professional.colorprof} icon={11} /></a>
                      </Box>
                    )}
                    <Box>
                      <a title={`@instagram:) ${professional.name}`} target="_blank" rel="noopener noreferrer" href={`${professional.instagram}`}>
                        <BtnStyle iconBtnBkcolor={professional.colorprof} icon={12} /></a>
                    </Box>
                    <Box>
                      <a title={`E-mail to:) ${professional.name}`} target="_blank" rel="noopener noreferrer" href={`mailto:${professional.email}`}>
                        <BtnStyle
                          iconBtnBkcolor={professional.colorprof}
                          icon={14} />
                      </a>
                    </Box>

                  </Box>

                </Box>

              )}
            </Box>
          )}

          {/**
           * Create likes - códigos de atualização para monitoração
           * 
           *  */}
          <CreateLikes />
          <div className="btn_add_Award">
            <FiAward
              size={!addAward ? '70' : '0'}
              color={!addAward ? 'transparent' : ligthColor}
            />
            <FiAward
              size={!addAward ? '0' : '70'}
              color={!addAward ? 'transparent' : ligthColor}
            />
            {/* colocar aqui resultado do hanking*/}
            {addNumAward < 11 ? (
              <p>{addNumAward}</p>
            ) : (
              <>
              </>
            )}

          </div>

          <Box>
            <Box
              margin={{ top: '-180px', horizontal: '520px', bottom: '-50px' }}
              width='200px'
              height="260px"
              pad='xxsmall'
              className="btn_view_icon_card"
              animation='fadeIn'>
              <a onClick={() => {
                setAgendaOpen(!agendaOpen);
                setViewVisitCard(false);
              }} >
                <Avatar size='130px' src={professional.avatar} />
              </a>
            </Box>
            <Box
              animation='slideUp'
              align='center'
              pad={{ horizontal: 'large' }}>
              <Heading color={professional.colorprof}>{professional.name}  </Heading>
            </Box>
            <Box pad='xsmall'>

            </Box>
          </Box>

          {/* </div>    <div className="orphanage-details-content"> */}
          {/* esse é o bloco Data soma e fecha */}
          <Box id="schedule" />
          {soma > 0 ? (
            <Grommet theme={grommet}
              background="dark">
              <div
                className="dateBack"
              >
                <Box background="dark-1"
                  justify="between"
                  pad={{ horizontal: "small" }}
                  direction="row"
                  animation="fadeIn">
                  <Box
                    direction="row-responsive"
                    justify="center"
                    align="center"
                    gap="medium"
                    background="dark-1">
                    <Box
                      align="center"
                      justify="center"
                      background="dark-1">
                      <DateInput
                        format="dd/mm/yyyy"
                        value={(new Date()).toISOString()}
                        onChange={({ value }) => {
                          const date = new Date(value as any);
                          const text = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                          setAddDateSchedule(text);
                          setColorBtnContaOk(alertColor);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    direction="row"
                    justify="center"
                    background="dark-1"
                    align="center"
                    animation="fadeIn">
                    <Box
                      justify="center"
                      direction="row"
                      align="center">
                      <DropButton
                        color="dark-1"
                        label={
                          <h2>
                            <Box
                              background="dark-1"
                            >
                              {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(soma)}
                            </Box>
                          </h2>
                        }
                        dropAlign={{ top: 'bottom', right: 'right' }}
                        dropContent={
                          <h1>
                            <Grommet>
                              <Tip content={<TipContentQr message={`Scaneie o QrCode e pague ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(soma)} para ${professional.name} agendar ${barbershopUser?.name}`} />}>
                                <Box
                                  direction="row"
                                  justify="center"
                                  pad="xxsmall"
                                  align="center"
                                  animation="fadeIn"
                                  background="dark-1">
                                  <CreateQrCode
                                    txt_schedule={`${barbershopUser?.name} ${txtSchedule} ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(soma)} `}
                                    data_schedule={addDateSchedule}
                                  />
                                </Box>
                              </Tip>
                            </Grommet>
                          </h1>
                        }
                      />
                    </Box>
                    {/*Somar seria aqui*/}
                    {/** <Tip content="Clique na tesoura para somar:">
                        <Button icon={<FaCalendarAlt color={ligthColor}
                          onClick={() => alert(`Pague ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(soma)} para ${professional.name} poder te agendar...`)} size={30} />}
                          hoverIndicator />
                      </Tip> */}
                  </Box>
                  <Box
                    pad={{ horizontal: "small" }}
                  >
                    <Grommet>
                      <Tip content={<TipContent message={'Zerar soma'} />}>
                        <Button
                          icon={<FiXCircle color={ligthColor}
                            onClick={() => {
                              setSoma(0);
                              setColorBtnContaOk(ligthColor);
                              setTxtSchedule('');
                              setAddDateSchedule('ticket');
                            }}
                            onMouseUp={() => { setViewSchedule(true); setAgendaOpen(false) }}
                            size={30} />}
                          hoverIndicator={{ color: professional.colorprof, opacity: "strong", }} />
                      </Tip>
                    </Grommet>
                    {viewSchedule ? (
                      <Box>
                        <Grommet>
                          <Tip content={<TipContent message={'Subtrair'} />}>
                            <Button
                              icon={<FiMinusCircle
                                color={ligthColor}
                                onClick={() => { setViewSchedule(false); setSomaAdd(false) }}
                                size={30} />}
                              hoverIndicator={{ color: professional.colorprof, opacity: "strong", }} />
                          </Tip>
                        </Grommet>
                      </Box>
                    ) : (
                      <Grommet>
                        <Box>
                          <Tip content={<TipContent message={'Somar'} />}>
                            <Button icon={<FiPlusCircle
                              color={ligthColor}
                              onClick={() => { setViewSchedule(true); setSomaAdd(true) }}
                              size={30} />}
                              hoverIndicator={{ color: professional.colorprof, opacity: "strong", }} />
                          </Tip>
                        </Box>
                      </Grommet>
                    )}
                  </Box>
                </Box>
              </div>
            </Grommet>
          ) : (
            <>
            </>
          )}
          {agendaOpen ? (
            <div>
              {professional.servicesView.map(services => {
                return (
                  <Distribution
                    values={[
                      { value: 10, color: "dark-1" },
                    ]}
                  >
                    {value => (
                      <Grommet
                        theme={grommet}
                        background="dark-1"
                      >
                        <Box
                          pad={{ horizontal: "small" }}
                          onMouseUp={() => setTxtSchedule(txtSchedule + `, ${services.category}`)}
                          onClick={() => {
                            if (professional.activated) {
                              AddSub(services.service_value)
                              /** acima validação de soma e subtração */
                            }
                            else { }
                          }}

                        >
                          <Button href="#schedule">

                            {/** somar e subtrair aqui */}

                            {addProfissa && (
                              <DeleteService id_delete={services.id} />
                            )}
                            <Grommet>
                              <Card
                                key={services.id}
                                direction="row"
                                animation="zoomIn"
                                background="dark-1">
                                <CardHeader
                                  animation="zoomIn"
                                  pad="small"
                                  width="small"
                                  justify="between"
                                  background="dark-1">
                                  <Box>
                                    <Text
                                      onMouseUp={() => setTxtSchedule(services.category)}
                                    ><h3 className="category">{services.category}</h3></Text>
                                  </Box>
                                  {viewSchedule ? (
                                    <Grommet>
                                      <Tip content={<TipContent message={` Somar o valor de ${services.category}`} />}>
                                        <Button
                                          icon={<FiScissors color={ligthColor}
                                            onMouseUp={() => setTxtSchedule(services.category)}
                                            size='30' />} hoverIndicator
                                        />
                                      </Tip>
                                    </Grommet>
                                  ) : (
                                    <Grommet>
                                      <Tip content={<TipContent message={` Subtrair o valor de ${services.category}`} />}>
                                        <Button
                                          icon={
                                            <FiScissors color={professional.colorprof}
                                              size="30" />} hoverIndicator />
                                      </Tip>
                                    </Grommet>
                                  )}
                                </CardHeader>
                                <CardBody
                                  animation="zoomIn"
                                  pad="xxsmall"
                                  justify="center"
                                  align="center"
                                  background="dark-5" >
                                  <h2>
                                    {viewSchedule ? (
                                      <Box animation="slideUp">
                                        <Text
                                          size="xxlarge"
                                          color={professional.colorprof}>
                                          {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(services.service_value)}
                                        </Text>
                                      </Box>
                                    ) : (
                                      <Box animation="slideDown">
                                        <Text
                                          size="xxlarge"
                                          color={ligthColor}>
                                          {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(services.service_value)}
                                        </Text>
                                      </Box>
                                    )}
                                  </h2>
                                </CardBody>
                                <CardFooter
                                  animation="zoomIn"
                                  justify="between"
                                  pad={{ horizontal: "small" }}
                                  background="transparent"
                                >
                                  <Text
                                    color={ligthColor}><h4>{services.service_time} minutos</h4></Text>
                                  <Grommet>
                                    <Tip
                                      content={
                                        <TipContent message={`A ${services.category} demora ${services.service_time} minutos`} />
                                      }>
                                      <Button
                                        icon={
                                          <Clock
                                            color={professional.colorprof} />} hoverIndicator />
                                    </Tip>
                                  </Grommet>
                                </CardFooter>
                              </Card>
                            </Grommet>
                          </Button>
                        </Box>
                      </Grommet>
                    )}
                  </Distribution>
                )
              })}</div>
          ) : (
            <Box>
              {!professional.activated && (
                <Grommet>
                  <Box
                    justify="center"
                    align="center"
                    direction="row"
                    background="dark-1"
                    pad="small"
                    onClick={() => alert(`Agenda de ${professional.name} está fechada! Chame ele pelo zap: ${professional.whatsapp}`)}>
                    <Box pad="small" background="">
                      <h3><Schedule size='40' color={professional.colorprof} /></h3>
                    </Box>
                    <Box pad="small" >
                      <h3>Agenda Fechada</h3>
                    </Box>
                  </Box>
                </Grommet>
              )}
            </Box>
          )}
          {soma > 0 && (
            <Box
              background='dark-1'
            >
              <TicketSchedule
                iconProf={5}
                iconProflabel='agendar'
                colorbarberTxtProf={professional.colorprof}
                sizeIconProf={40}
                userName={`${barbershopUser?.name}`}
                profId={professional.id}
                avatarProf={professional.avatar}
                profName={professional.name}
                activatedProf={true}
                txtScheduleProf={`${barbershopUser?.name} - ${professional.name} ${txtSchedule} ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(soma)} `}
                somaSchedule={soma}
                addDateScheduleProf={addDateSchedule}
              />
            </Box>
          )}

          <Box>
            <div className="attributes">{professional.scheduleView.map(schedule => {
              return (
                <>
                  {qrView && (
                    <div key={schedule.id} >
                      <div className="attri">
                        <Box
                          onLoad={() => { AttributeUpDate() }}
                          onMouseMove={() => { AttributeUpDate() }}
                        >
                          <h3>
                            {schedule.user_id === barbershopUser?.id ? (
                              <>
                                {barbershopUser?.name}
                              </>
                            ) : (
                              <>
                                ticket
                              </>
                            )}
                          </h3>
                        </Box>

                        <Box pad="xsmall" background="dark-1">
                          {
                            addProfissa ? (
                              <Box>
                                <DeleteSchedule id_delete={schedule.id} />
                              </Box>

                            ) : (
                              <>
                                <DropButton
                                  color="dark-1"
                                  label={
                                    <Box
                                      className="btn_PgQrcode"
                                      pad="xxsmall"
                                    >
                                      <ViewQrCode
                                        txt_schedule={`${schedule.schedule_date}`}
                                      />
                                    </Box>
                                  }
                                  dropAlign={{ top: 'bottom', right: 'right' }}
                                  dropContent={
                                    <Grommet>
                                      <h2>
                                        <Tip
                                          dropProps={{ align: { top: 'bottom' } }}
                                          content={<TipContentQr message={`Scaneie o QrCode ${barbershopUser?.name} 
                                          e pague à ${professional?.name}, te agendar!`}
                                          />}>
                                          <Box
                                            direction="row"
                                            justify="center"
                                            pad="xxsmall"
                                            align="center"
                                            animation="fadeIn"
                                            background="dark-1">
                                            <CreateQrCode
                                              data_schedule={`${schedule.schedule_date}`}
                                              txt_label={`${barbershopUser?.name}`}
                                              link_to={`/professional/${professional.id}`}
                                            />
                                          </Box>
                                        </Tip>
                                      </h2>
                                    </Grommet>
                                  }
                                />
                                {/** Qr entra aqui */}

                              </>
                            )}

                        </Box>
                        <Box>
                          <h5>
                            {schedule.user_id === barbershopUser?.id ? (
                              <>
                                <FiCreditCard color={'#fbff00'} size='15' /> {schedule.id}
                              </>
                            ) : (
                              <>
                                <FiCreditCard color={'#000'} size='15' /> {schedule.id}
                              </>
                            )}
                          </h5>
                        </Box>
                      </div>
                    </div>
                  )}
                </>

              )
            })}</div>
          </Box>
        </Box>
        <Box id="schedule" />
      </main>
    </div>
  );
}