/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { FiAward, FiInfo, FiShare, FiShare2 } from "react-icons/fi";
import { Map, Marker, TileLayer } from "react-leaflet";
import { Link, useParams } from 'react-router-dom';
import Leaflet from 'leaflet';
import { GoVerified } from "react-icons/go";
//import '../styles/pages/barbershop.css';//
import Sidebar from "../components/Sidebar";
import Attribute from "./CreateAttribute";
import Profissional from "./CreateProf";
import CreateQrCode from "./CreateQrCode";
import DeleteAttribute from "./DeleteAttribute";
import DeleteProfissa from "./DeleteProfissa";
import TipContent from "./TipContent";
import mapIcon from "../utils/mapIcons";
import markII from '../../src/images/barbershopapp_markII.svg';

import api from "../services/api";
import '../css/deleteAtt.css';
import '../css/prelooad.css';
import { Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, Carousel, CheckBox, Grommet, Heading, Image, Main, Nav, Stack, Text, TextInput, ThemeType } from "grommet";
import { Clock, Revert, Cart } from "grommet-icons";
import { Tip } from "grommet/components/Tip";
import ViewQrCodeShare from "./ViewQrCodeShare";
import BtnStyle from "./BtnStyleThemeType";
import UpdateBarber from "./UpdateBarber";
import iconCard from '../../src/images/01barbershh.png';
import PropTypes from 'prop-types';
import { dark } from 'grommet/themes';

import { deepMerge } from 'grommet/utils';

interface Barbershop {

  id: number;
  latitude: number;
  longitude: number;
  name: string;
  email: string;
  avatar: string;
  colorbarber: string;
  pix: string;
  andress: string;
  opening_hours: string;
  open_on_weekends: boolean;
  activated: boolean;

  attributes: Array<{
    id: number;
    name: string;
  }>;

  professionals: Array<{
    id: number;
    name: string;
    email: string;
    avatar: string;
    pix: string;
    whatsapp: number;
    instagram: string;
    opening_hours: string;
    open_on_weekends: boolean;
    activated: boolean;
  }>;

  images: Array<{
    id: number;
    url: string;
  }>;
}
interface BarbershopParams {
  id: string;
}
interface ProfBarbershop {
  barbershop: number;
  name: string;
  email: string;
  avatar: string;
  pix: string;
  whatsapp: number;
  instagram: string;
  opening_hours: string;
  open_on_weekends: boolean;
  activated: boolean;
  schedule: Array<{
    id: number;
    schedule_date: string;
    professional_id: number;
    user_id: number;
  }>;
  services: Array<{
    id: number;
    category: string;
    service_value: number;
    service_time: number;
    professional_id: number;
  }>;
  imagesProf: Array<{
    id: number;
    url: string;
  }>;
}


const idBarber = localStorage.getItem('idBarbershop');

export default function Barbershop() {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const params = useParams<BarbershopParams>();
  const [barbershop, setBarbershop] = useState<Barbershop>();
  //const [professional, setProfessional] = useState<ProfBarbershop>();
  const [userId, setUserId] = useState(0);
  const [addProfissa, setAddProfissa] = useState(false);
  const [addHeard, setAddHeard] = useState(false);
  const [addAward, setAddAward] = useState(false);
  const [viewSchedule, setViewSchedule] = useState(false);
  const [viewProfissa, setViewProfissa] = useState(false);
  const [viewGlobeMap, setViewGlobeMap] = useState(false);
  const [viewQrShare, setViewQrShare] = useState(false);
  const [onClickAtt, setOnClickAtt] = useState(false);
  const [viewMenu, setViewMenu] = useState(true);
  const [viewStatus, setViewStatus] = useState(false);
  const [txtNovoProfissa, setTxtNovoProfissa] = useState('ops...');


  const [addNewProfissa, setAddNewProfissa] = useState(false);
  const [addNumAward, setAddNumAward] = useState(11);
  //4df8f8 top
  //d63737
  // essas duas seguintes foram geradas pelo app do itaú
  //00020126640014BR.GOV.BCB.PIX0118nettobkp@gmail.com0220barbershopapp.com.br520400005303986540578.005802BR5925DOMINGOS DE OLIVEIRA NETT6009SAO PAULO622605227EpElMZgCFf8u3QLDfKt9463046CD3 
  //00020126640014BR.GOV.BCB.PIX0118nettobkp@gmail.com0220barbershopapp.com.br52040000530398654046.505802BR5925DOMINGOS DE OLIVEIRA NETT6009SAO PAULO622605226lNnNiaEhExCvzau4gFaFV63041E9E
  //setPixPagamento(`00020126570014BR.GOV.BCB.PIX0118nettobkp@gmail.com0213barbershopapp520400005303986540578.005802BR5925DOMINGOS DE OLIVEIRA NETT6009SAO PAULO622605223Lj3dDtHY8H1xB8Y3G9Uff6304C3AF`);
  //gerada verificar se funciona
  //00020126570014BR.GOV.BCB.PIX0118nettobkp@gmail.com0213barbershopapp520400005303986540578.005802BR591 4Domingos Netto6013Nova Friburgo610928613-00162130509astipg00163047E98
  const [pixPagamento, setPixPagamento] = useState('00020126640014BR.GOV.BCB.PIX0118nettobkp@gmail.com0220barbershopapp.com.br52040000530398654046.505802BR5925DOMINGOS DE OLIVEIRA NETT6009SAO PAULO622605226lNnNiaEhExCvzau4gFaFV63041E9E');


  const barberavatarLink =
    'https://i.ibb.co/09vsNhQ/icon.png';



  const [firstColor, setFirstColor] = useState('#000000');
  const [secondColor, setSecondColor] = useState('#dabb9e');
  const [alertColor, setAlertColor] = useState('#39CC83');
  const [sinalizaColor, setSinalizaColor] = useState('#e72a2a');
  const [statuswarning, setStatuswarning] = useState('#FFAA15');
  const [ligthColor, setLigthColor] = useState('#FFFFFF');
  const [txtQrNetto, setTxtQrNetto] = useState('ops...');
  const [updateColor, setUpdateColor] = useState('#FFF');
  const [txtBtn, setTxtBtn] = useState('Agende um horário :)');
  const [txtLabel, setTxtLabel] = useState('Agende um horário :)');
  const [colorBtn, setColorBtn] = useState("grey");
  const [animationBtn, setAnimationBtn] = useState("");
  const [carouselView, setCarouselView] = useState(true);

  const addEmailLogin = localStorage.getItem('userEmail');
  const H = ({ level = 1, size = 'large' }) => (
    <Heading level={level as any} size={size}>
      {`Heading ${level} ${size}`}
    </Heading>
  );

  H.propTypes = {
    level: PropTypes.number.isRequired,
    size: PropTypes.string.isRequired,
  };

  const Set = ({ size = 'large' }) => (
    <div>
      {[1, 2, 3, 4, 5, 6].map(level => (
        <H key={level} level={level} size={size} />
      ))}
    </div>
  );

  Set.propTypes = {
    size: PropTypes.string.isRequired,
  };

  const customThemeAvatar: ThemeType = {
    avatar: {
      size: {
        myLarge: '70px',
      },
      text: {
        size: {
          myLarge: '32px',
        },
        fontWeight: 700,
        extend: `font-family: Comic Sans MS;`,
      },
      extend: `border: 4px solid ${colorBtn};
              box-shadow: 2px 2px 20px 1px white;`,

    },
  }
  const customTheme: ThemeType = {

    carousel: {

      animation: {
        duration: 600,
      },
      icons: {
        color: colorBtn,
      },
      disabled: {
        icons: {
          color: colorBtn,
        },
      },
    },
    global: {

      focus: {
        border: {
          color: updateColor,
        },
        shadow: {
          color: 'white',
        },
      },
      font: {
        family: 'Arial',
      },
      hover: {
        color: updateColor,
      },
    },

    button: {
      border: {
        radius: undefined,
        color: 'dark-3',
      },
      hover: {
        color: updateColor,
      },
      disabled: {
        color: '#ffffff48',
        opacity: '.1',
        border: {
          color: '#000000',
          width: '10px',
        },
      },

      primary: {
        color: 'dark-2',
      },
      extend: (txtBtn: any) => {
        let extraStyles = '';
        if (txtBtn.primary) {
          extraStyles = `
            text-transform: uppercase;
          `;
        }
        return `
          color: white;
          font-size: 22px;
          font-weight: bold;
  
          ${extraStyles}
        `;
      },
    },
  };

  useEffect(() => {
    setTxtLabel(txtLabel);

  }, []);
  function PlanosPix(plan = 1) {

    switch (plan) {
      case (1):
        setPixPagamento(`00020126640014BR.GOV.BCB.PIX0118nettobkp@gmail.com0220(${barbershop?.id}.${barbershop?.name})52040000530398654046.505802BR5925DOMINGOS DE OLIVEIRA NETT6009SAO PAULO622605226lNnNiaEhExCvzau4gFaFV63041E9E`);
        break;
      case (2):
        setPixPagamento(`00020126640014BR.GOV.BCB.PIX0118nettobkp@gmail.com0220barbershopapp.com.br52040000530398654046.505802BR5925DOMINGOS DE OLIVEIRA NETT6009SAO PAULO622605226lNnNiaEhExCvzau4gFaFV63041E9E`);
        break;
      case (3):
        setPixPagamento(`00020126610014BR.GOV.BCB.PIX0118nettobkp@gmail.com0217barbershopapp-003520400005303986540519.505802BR5925DOMINGOS DE OLIVEIRA NETT6009SAO PAULO622605223xPbrSC0YQAwDxRnNoYmSJ63049C11`);
        break;
      case (10):
        setPixPagamento(`00020126570014BR.GOV.BCB.PIX0122nettobkp@gmail.com0213barbershopapp520400005303986540578.005802BR`);
        break;
      case (12):
        setPixPagamento(`00020126610014BR.GOV.BCB.PIX0118nettobkp@gmail.com0217barbershopapp-012520400005303986540578.005802BR5925DOMINGOS DE OLIVEIRA NETT6009SAO PAULO622605222decXYs0JGfHcBa71GfKEB63048BC2`);
        break;
    }
  }
  function ClosedOpendMenu(status_menu = false) {
    setViewGlobeMap(status_menu);
    setViewProfissa(status_menu);
    setViewStatus(status_menu);
    setViewMenu(!viewMenu);
    PlanosPix(2);
  }
  function AwardUpDate() {
    setTxtQrNetto(`@BarbershopApp ${barbershop?.name} curte:)`);

    if (addNumAward < 11) {
      setAddAward(true);
    }
    else {
      setAddAward(false);
    }
  }
  function AttributeUpDate(colorUpdate = '') {
    api.get(`barbershop/${params.id}`).then(response => {
      setBarbershop(response.data);
      setAddNumAward(userId);
      setColorBtn(colorUpdate);
      setUpdateColor(colorUpdate);

    });
    AwardUpDate();
  }

  async function BarberUpdate() {

    await api.put(`barbershop/${params.id}`, barbershop);
  }

  async function colorUpdate(colorUpdate = '') {
    setColorBtn(colorUpdate)
  }

  useEffect(() => {
    api.get(`barbershop/${params.id}`).then(response => {
      setBarbershop(response.data);
      setUserId(idBarber as any);
      setFirstColor("#000");
      setSecondColor("#dabb9e");
      setSinalizaColor("#ff1313");
      setLigthColor("#FFFFFF");
    });
  }, [params.id]);

  if (!barbershop) {
    return (
      <Main>
        <Box pad={{ horizontal: '100px', vertical: '160px' }} align="center" justify="center" direction="row">
          <div className="preloader17">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Box></Main>);
  }
  if (barbershop.images.length === 0) {
  }
  ///** menu social */ 
  /// as imagens estão ficando top

  const mapIconAvatar = Leaflet.icon({
    iconUrl: markII,
    iconRetinaUrl: `${barbershop.avatar}`,
    iconSize: [58, 58],
    iconAnchor: [29, 68],
    popupAnchor: [170, 2]
  })

  return (
    <div id="page-orphanage">

      <Sidebar />
      <main onMouseOut={() => AttributeUpDate(barbershop.colorbarber)} >
        <Box               
          margin={{ top: '80px' }}
          id="top" className="orphanage-details">
          {/** troquei div por Box -->   <div  className="orphanage-details"> */}
          {barbershop.images.length === 0 ? (
            <Image src={iconCard} />
          ) : (
            <Grommet theme={deepMerge(customTheme)}>
              <Box
                animation='slideRight'
                // fica top com a cor      background={barbershop.colorbarber}
                margin={{ bottom: '10px' }}
              >
                <Carousel
                  className='carousel'
                  controls={true} play={3500}>
                  {barbershop.images.map(img => (
                    <Box height="400px" width="700px">
                      <Image key={img.id}
                        src={img.url}
                        style={{ width: '700px', height: '500px' }} fit="cover"
                        fallback={iconCard}
                      />
                    </Box>
                  ))}
                </Carousel>
              </Box>

            </Grommet>
          )}
          {addProfissa ? (
            <Grommet theme={deepMerge(dark, customTheme)}>
              <Box
                animation='slideUp'
                background='dark-1'
                className='menuSocialBarber'
                margin={{ top: '-65px' }}
              >
                <a
                  onClick={() => {
                    setAddProfissa(!addProfissa);
                  }}
                  href="#edit">
                  <BtnStyle
                    iconBtnBkcolor={barbershop.colorbarber} icon={6} />
                </a>

              </Box>
            </Grommet>

          ) : (
            <Box
              animation='slideLeft'
              background='dark-1'
              margin={{ top: '345px' }} responsive className='menuSocialBarber'>
              {addEmailLogin === barbershop.email ? (
                <Grommet theme={deepMerge(dark, customTheme)}>
                  <Box
                    background='dark-1'
                  >
                    <a href="#edit"
                      onClick={() => {
                        setViewStatus(false);
                        setViewGlobeMap(false);
                        setViewProfissa(!viewProfissa);
                        setAddProfissa(!addProfissa);
                      }}>
                      <BtnStyle
                        iconBtnBkcolor={barbershop.colorbarber} icon={4} />
                    </a>
                  </Box>
                </Grommet>
              ) : (
                <Grommet theme={deepMerge(dark, customTheme)}>
                  {viewQrShare ? (
                    <Box
                    background='dark-1'
                      direction='column'
                      margin={{ top: '-185px' }}
                      animation="slideUp"
                      
                    >
                      <Box
                      onClick={() => {
                        setViewQrShare(false);
                        setViewGlobeMap(false);

                      }}
                      >
                      <ViewQrCodeShare
                        name_share={barbershop.name}
                        txt_link_share={`https://barbershopapp.com.br/barbershop/${barbershop.id}`} />
                      </Box>
                      <Box
                        direction='row'
                        justify='between'
                      >
                        <Box
                        onClick={() => {
                          setViewGlobeMap(!viewGlobeMap);
                          setViewProfissa(false);
                          setViewStatus(false);
                        }}
                        >
                          <a href='#map'
                          >
                          <BtnStyle icon={13} iconBtnBkcolor={barbershop.colorbarber} />
                          </a>
                        </Box>
                        <Box
                        
                        onClick={() => {
                          setViewGlobeMap(false);
                          setViewProfissa(false);
                          setViewStatus(!viewStatus);
                        }}
                        >
                           <a href='#status'
                          >
                        <BtnStyle icon={7} iconBtnBkcolor={barbershop.colorbarber} />
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      animation='slideRight'
                      background='dark-1'
                    >
                      <a href="#edit"
                        onClick={() => {
                          setViewQrShare(true);
                        }}>
                        <BtnStyle
                          iconBtnBkcolor={barbershop.colorbarber} icon={30} />
                      </a>
                    </Box>
                  )}

                </Grommet>
              )}

            </Box>
          )}
          <Box margin={{ top: '260px', right: '10px' }}
            alignSelf='end' responsive className='menuSocialBarber'>
            {/**menu view aqui */}
            {viewMenu && (
              <Box animation="fadeIn">
                <Nav alignContent="between" justify="between" background="transparent" >
                  <Box id="menu" direction="column" alignContent="between" justify="between">
                    <a href="#menu"
                      title="Barbeiros "
                      className="noLink"
                      onClick={() => {
                        setViewGlobeMap(false);
                        setViewProfissa(!viewProfissa);
                        setAddNewProfissa(false);
                        setViewQrShare(false);
                        setTxtNovoProfissa('Excluir & Cadastrar Barbeiro');
                        setViewStatus(false);
                      }}
                    >
                      <Box
                        animation={animationBtn as any}
                      >
                        <Stack anchor="bottom-right">
                          <Grommet theme={deepMerge(animationBtn === '' ? customTheme : customThemeAvatar)}>
                            <Box
                              margin={{ top: '30px', right: '30px' }}
                            >
                              <Avatar size="120px" src={barbershop.avatar} />
                            </Box>
                          </Grommet>
                          <Box
                            align='center'
                            alignContent='between'
                            alignSelf='center'
                            justify='between'
                            round background='transparent'
                            margin={{ right: '32px' }}
                          >
                            <GoVerified size={22} color={ligthColor} />
                          </Box>
                        </Stack>
                      </Box>
                    </a>
                  </Box>
                </Nav>
              </Box>
            )}
          </Box>
          <div className="btn_add_Award">
            <FiAward
              size={!addAward ? '70' : '0'}
              color={!addAward ? 'transparent' : ligthColor}
            />
            <FiAward
              size={!addAward ? '0' : '70'}
              color={!addAward ? 'transparent' : ligthColor}
            />
            {/* colocar aqui resultado do hanking*/}
            {addNumAward < 11 ? (
              <>{addNumAward}</>
            ) : (
              <></>
            )}
          </div>

          <Box>

            <Box
              animation='slideUp'
              align='center'
              pad={{ horizontal: 'large', vertical: 'small' }}>
              <Heading color={barbershop.colorbarber}>{barbershop.name}  </Heading>
            </Box>

            {addProfissa && (
              <Box
                animation='slideRight'
                align='center'
                justify='between'
                pad={{ horizontal: 'xlarge' }}
                direction='row'>

                <UpdateBarber
                  iconBarber={10}
                  sizeIconBarber={55}
                  tbnTxtBarber=''
                  nameBarber={barbershop.name}
                  latitudeBarber={barbershop.latitude}
                  longitudeBarber={barbershop.longitude}
                  emailBarber={barbershop.email}
                  andressBarber={barbershop.andress}
                  pixBarber={barbershop.pix}
                  avatarBarber={barbershop.avatar}
                  open_on_weekendsBarber={barbershop.open_on_weekends}
                  opening_hoursBarber={barbershop.opening_hours}
                  activatedBarber={barbershop.activated}
                  colorbarberTxtBarber={barbershop.colorbarber}
                />

                {barbershop.activated && (
                  addProfissa && (
                    <>
                      <Box
                        align='center'
                      >
                        <Profissional
                          nameDefault={barbershop.name}
                          colorProf={barbershop.colorbarber}
                          instaDefault={barbershop.name}
                          emailDefault={barbershop.email}
                          avatarDefalt={barbershop.avatar}
                          profColor={barbershop.colorbarber}
                          profIcon={8}
                          profBnTxt='cadastrar novo barbeiro'
                          profIconSize={55} />
                      </Box>

                      <Attribute
                        sizeIconAttri={55}
                        iconAttri={9}
                        colorAttri={barbershop.colorbarber} />
                    </>
                  )

                )}

              </Box>

            )}
            <div className="attributes">{barbershop.attributes.map(attribute => {
              return (
                <Grommet theme={deepMerge(customTheme)}>
                  <Box key={attribute.id}
                    elevation="xsmall"
                    pad="xxsmall">
                    <Box  >
                      {addProfissa && (
                        <DeleteAttribute id_delete={attribute.id} />
                      )}
                    </Box>
                    <Box    
                      pad={{ vertical: "xsmall" }}
                      background={{ color: `${barbershop.colorbarber}`, opacity: 'strong' }}
                      justify="between"
                      onClick={() => { setOnClickAtt(!onClickAtt)}}
                      align="center"
                      
                      hoverIndicator={{ color: 'dark-1', opacity: "strong", }}>
                      <Heading level={3}  >{attribute.name}</Heading>
                    </Box>
                  </Box>
                </Grommet>
              )
            })}
            </div>
            <Box pad='small' />
            {/**Menu aqui */}
            <Grommet theme={deepMerge(customTheme)}>
              <Box id="end"></Box>
              {viewProfissa && (
                <>
                  {barbershop.professionals.map(profissa => {
                    return (
                      <Box
                        pad={{ horizontal: 'large', bottom: 'small' }}
                        animation="zoomIn" direction="row">

                        {/**
                        *  <Link to={`/professional/${profissa.id}`}>
                          <Box
                            background='dark-1'
                            pad='xsmall'
                            className="btn_view_icon_card"
                            animation={animationBtn as any}>
                            <Avatar size="xlarge" src={profissa.avatar} />
                          </Box>
                        </Link>
                        */}

                        <Card
                          key={profissa.id}
                          elevation="large"
                          height="260px"
                          width="large"
                          background="dark-1"
                          round={{ corner: 'top-right', size: '15px' }}
                        >

                          <CardHeader
                            justify="between"
                            background="dark-1"
                            pad="small">

                            {addProfissa ? (
                              <Box
                                animation="fadeIn"
                                direction="row"
                                width='full'
                                justify='between'
                              >
                                <Box
                                  pad={{ horizontal: "small" }}>
                                  <Heading color={barbershop.colorbarber} level={2}>{profissa.name}</Heading>
                                </Box>
                                <Box
                                  animation="pulse" >
                                  <DeleteProfissa
                                    id_delete={profissa.id} />
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                animation="fadeIn"
                                align="center"
                                alignContent="center"
                                alignSelf="center">
                                <Heading color={barbershop.colorbarber} level={2}>{profissa.name}</Heading>
                              </Box>
                            )}
                          </CardHeader>
                          <CardBody
                            justify="center"
                            direction="row"

                            height='xlarge'
                            width='large'
                          >
                            <Card
                              justify="center"
                              align="center" pad="small"
                              round='null'
                              elevation='null'
                            >
                              <Link to={`/professional/${profissa.id}`}>
                                <Box
                                  width='200px'
                                  height="260px"
                                  pad='xxsmall'
                                  className="btn_view_icon_card"
                                  animation={animationBtn as any}>
                                  <Avatar size='130px' src={profissa.avatar} />
                                </Box>
                              </Link>
                            </Card>
                            <Card justify="center"
                              align="center" pad="medium"
                              round='null'
                              elevation='null'
                            >
                              <h3><Clock
                                color={barbershop.colorbarber}
                                size="medium" />
                              </h3>
                            </Card>
                            <Card
                              justify="center"
                              width="medium"
                              round='null'
                              elevation='null'
                              pad="medium">
                              <h4>
                                {profissa.opening_hours}
                              </h4>
                            </Card>

                          </CardBody>

                          <CardFooter justify="between" pad={{ horizontal: "small" }} >
                            <a target="_blank" href={`${profissa.instagram}`} >
                              <BtnStyle
                                iconTarget="_blank"
                                icon={12}
                                iconBtnBkcolor={barbershop.colorbarber}
                                iconTitle={`@${profissa.instagram}`}
                                iconsize={30}
                              />
                            </a>

                            <h4>
                              {profissa.open_on_weekends ? (
                                <Tip content={<TipContent message={`Pai tá on! Insta, Zap, bora agendar ${userId}`} />}>
                                  <Box>
                                    sábado On
                              </Box>
                                </Tip>
                              ) : (
                                <Tip content={<TipContent message={`Pai tá on! Insta, Zap, bora agendar ${userId}`} />}>
                                  <Box>
                                    sábado off
                                  </Box>
                                </Tip>
                              )}
                            </h4>
                            <a target="_blank"
                              href={`https://api.whatsapp.com/send?phone=${profissa.whatsapp}&text=${profissa.name}. Quero%20cortar%20o%20cabelo!`}
                            >
                              <BtnStyle
                                iconTarget="_blank"
                                icon={11}
                                iconBtnBkcolor={barbershop.colorbarber}
                                iconTitle={`@${profissa.whatsapp}`}
                                iconsize={30}
                              />
                            </a>

                          </CardFooter>
                        </Card>
                      </Box>
                    )
                  })}
                </>
              )}
              {viewGlobeMap && (
                <Box id="map" pad="medium" animation="zoomIn">
                  <Box
                    elevation="medium"
                    justify="center"
                    align="center"
                    background="dark-1"
                    pad="small"
                  >
                    <Map
                      center={[barbershop.latitude, barbershop.longitude]}
                      zoom={16}
                      style={{ width: '100%', height: 280 }}
                      dragging={false}
                      touchZoom={false}
                      zoomControl={false}
                      scrollWheelZoom={false}
                      doubleClickZoom={false}
                    >
                      <TileLayer
                        url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                      />
                      <Marker interactive={false} icon={mapIconAvatar} position={[barbershop.latitude, barbershop.longitude]} />
                    </Map>
                    <a className="noLink" target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/dir/?api=1&destination=${barbershop.latitude},${barbershop.longitude}`}>
                      <Box
                        justify="center"
                        align="center"
                        direction="row"
                        pad={{ vertical: "small" }}
                      >
                        <BtnStyle icon={2} iconBtnBkcolor={barbershop.colorbarber} iconcolor="#fff" txtLabel="Ver rota no Google Maps" />
                      </Box>
                    </a>
                  </Box>
                </Box>
              )}
              {viewStatus ? (
                <Box animation="zoomIn" id="status">
                  <Box pad="small">
                    <Text color={colorBtn} textAlign="center" size="medium">{barbershop.andress}</Text>
                    <Text textAlign="center" size="small">{barbershop.email}</Text>
                  </Box>
                  <a href="#menu" className="noLink">
                    <Box
                      onClick={() => {
                        setViewSchedule(!viewSchedule);
                        setColorBtn(colorBtn);
                        setAnimationBtn('pulse');
                        if (viewSchedule === true) {
                          setColorBtn(colorBtn);
                          setTxtBtn("Escolha o seu barbeiro preferido!");
                        }
                        else {
                          setTxtBtn("Agende um horário!");
                          setColorBtn(colorBtn);
                        }
                      }}
                      direction="row"
                      animation="fadeIn"
                      align="center"
                      justify="center"
                      pad="xxsmall"
                    >
                      <Box
                        animation="fadeIn"
                        align="center"
                        pad="small"
                      >
                      </Box>
                      <Box>
                        <BtnStyle iconsize={35}
                          icon={5} txtLabel={txtBtn} iconBtnBkcolor={colorBtn} />
                      </Box>
                    </Box>
                  </a>

                  <div>
                    {viewSchedule && (
                      <Box
                        direction='row'
                        width='full'
                        justify='center'
                        pad='medium'
                        onClick={() => setViewSchedule(false)} className="open-details">
                        <Box
                          animation="fadeIn"
                          background="dark-4"
                          width='full'
                          pad='small'
                          direction='row'
                          round={{ corner: 'left', size: '15px' }}
                        >
                          <Box
                            pad='small'
                          >
                            <BtnStyle
                              iconsize={40}
                              icon={25}
                              iconBtnBkcolor={colorBtn}
                            />
                          </Box>
                          <Box
                            align='center'
                            justify='center'

                          >
                            <strong>
                              <Text color={ligthColor}>
                                Segunda à Sexta <br />
                                {barbershop.opening_hours}
                              </Text>
                            </strong>
                          </Box>
                        </Box>
                        {barbershop.open_on_weekends ? (
                          <Box
                            width='full'
                            pad='small'
                            direction='row'
                            animation="fadeIn"
                            background={alertColor}
                            round={{ corner: 'right', size: '15px' }}
                          >
                            <Box
                              pad='small'
                            >
                              <BtnStyle
                                iconsize={40}
                                icon={28}
                                iconBtnBkcolor={colorBtn}
                              />
                            </Box>
                            <Box
                              align='center'
                              justify='center'
                            >
                              <strong>
                                <Text color={ligthColor}>
                                  Atendemos <br />
                              fim de semana
                            </Text>
                              </strong>
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            animation="fadeIn"
                            background="status-warning"
                            width='full'
                            pad='small'
                            direction='row'
                            round={{ corner: 'right', size: '15px' }}
                          >
                            <Box
                              pad='small'
                            >
                              <BtnStyle
                                iconsize={40}
                                icon={27}
                                iconBtnBkcolor={colorBtn}
                              />

                            </Box>
                            <Box
                              align='center'
                              justify='center'
                            >
                              <strong>
                                <Text color={ligthColor}>
                                  Não Atendemos <br />
                              fim de semana
                            </Text>
                              </strong>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </div>
                </Box>
              ) : (
                <>
                </>
              )}
              {!barbershop.activated && (
                <><Box>
                  {viewMenu ? (
                    <Box animation="fadeIn">
                      <Nav alignContent="between" justify="between" background="transparent" >
                        <Box id="menu" direction="column" alignContent="between" justify="between">

                          <a href="#menu" className="noLink" >
                            <Box
                              onClick={() => { setViewMenu(!viewMenu) }}

                              animation={animationBtn as any}
                            >
                              <FiInfo className="btn_view_Menu" size='55' color={colorBtn} />
                            </Box>
                          </a>

                        </Box>
                      </Nav>
                    </Box>

                  ) : (
                    <>
                      <Grommet >
                        <Box
                          background={{ color: 'dark-1', opacity: 1 }}
                          animation="fadeIn"
                        >
                          <Grommet theme={deepMerge(customTheme)}>
                            <Box
                              id="QrCode"
                              animation="fadeIn"
                              justify="between" direction="row" align="center" pad={{ vertical: "small" }}>
                              <Box
                                pad="xsmall">
                                <Button
                                  href={'#QrCode'}
                                  icon={<Cart
                                    size='32'
                                  />
                                  }
                                  label="R$ 6,50"
                                  onClick={() => { PlanosPix(1); setTxtQrNetto(`Pagamento único de R$ 6,50 `) }}
                                  primary />
                              </Box>
                              <Box pad="xsmall">
                                <Button
                                  href={'#QrCode'}
                                  icon={<Cart
                                    size='32'
                                  />
                                  }
                                  label="R$ 19,00"
                                  onClick={() => { PlanosPix(3); setTxtQrNetto(`Pagamento único de R$ 19,00 `) }}
                                  primary />
                              </Box>
                              <Box pad="xsmall">
                                <Button
                                  href={'#QrCode'}
                                  icon={<Cart
                                    size='32'
                                  />
                                  }
                                  label="R$ 78,00"
                                  onClick={() => { PlanosPix(12); setTxtQrNetto(`Pagamento único de R$ 78,00 `) }}
                                  primary />
                              </Box>
                            </Box>
                          </Grommet>
                        </Box>
                        <Box>
                          <CreateQrCode txt_schedule={pixPagamento}
                            //  txt_label={`BarbershopApp  ${barbershop.name} curte:)`}
                            txt_label={txtQrNetto}
                            link_to={`/barbershop/${barbershop.id}`}
                          />
                        </Box>
                      </Grommet></>)}</Box></>
              )}
            </Grommet>
          </Box>
        </Box>
      </main>
    </div>
  );
}