import React, { Fragment } from 'react';
import { BiEraser } from "react-icons/bi";
import { useParams, useHistory } from 'react-router-dom';
import api from "../services/api";
import '../css/deleteAtt.css';
import { Trash } from 'grommet-icons';
import { Form } from 'grommet';

interface BarbershopQuery {
  id: string;
}

export default function DeleteService({ id_delete = 0 }) {
  //  const [attribute, setAttribute] = useState([]);
  const query = useParams<BarbershopQuery>();
  const id_Service_Delete = id_delete;
  const history = useHistory();

  async function handleDeleteService(e: any) {
    e.preventDefault();
    //   setAttribute(attribute.filter(attribute => attribute.id !== id_Att_Delete));
    try {
      await api.delete(`service/${query.id}?attri=${id_Service_Delete}`)
      history.push(`/professional/${query.id}`);
      alert(`Serviço Deletado.${id_Service_Delete}`);
    } catch (err) {
      alert('Erro ao Deletar serviço, tente novamente.');
      //  history.push(`/barbershop/${barbershop}`);
    }
  }
  return (
    <Fragment>
      <Form >
        <div className="deletaService">
          <Trash className="delAtt" color='status-warning' onClick={handleDeleteService} size='20'/>
        </div>
      </Form>
    </Fragment>
  )
}