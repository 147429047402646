import React, { useState, FormEvent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from "../services/api";
import { Box, Button, Header, Image, Meter, Stack, Text } from 'grommet';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { FaRedhat, FaRegHeart } from 'react-icons/fa';

interface BarbershopParams {
  id: string;
}
interface LikeProfessional {
  professional_id: number;
  user_id: number;
}
// verificar os likes functions e seguir por esse cainho
const idBarber = localStorage.getItem('idBarbershop');
const countLikeHeader = localStorage.getItem('X-Total-LikeProf');

export default function NewProfissionalLike({ like = 'true', likId = 0 }) {
  const [likesProf, setLikesProf] = useState<LikeProfessional[]>([]);
  const params = useParams<BarbershopParams>();
  const [professional_id, setProfessional_id] = useState(params.id);
  const [user_id, setUser_id] = useState(idBarber);
  const [newLike, setNewLike] = useState(false);
  const [countLike, setCountLikes] = useState();
  const history = useHistory();

  function UpDateLikes() {
    setCountLikes(likesProf.length as any)
  }
  useEffect(() => {
    api.get('likes').then(response => {
      setLikesProf(response.data)
      setCountLikes(likesProf.length as any)
    });

  }, []);

  async function LikeUser() {
    const data = {
      professional_id,
      user_id
    };
    await api.post('likes', data);
    alert(`like user ${professional_id} :)`);
    await UpDateLikes();
  }
  async function LikeUserUpdate() {
    const data = {
      professional_id,
      user_id
    };
    await api.put(`likes/${1}`, data);
    alert(`like user ${professional_id} :)`);
    await UpDateLikes();
  }
  return (
    <>
      <div className="btn_add_Heart">
        <Stack anchor="top-right">
          <Button
            type="submit">
            <MdFavoriteBorder
              size={!newLike ? '50' : '0'}
              color='#fff'
              onClick={() => { setNewLike(true); LikeUser() }}
            />
            <MdFavorite
              size={!newLike ? '0' : '50'}
              color='#fff'
              onClick={() => { setNewLike(false); LikeUser() }}
            />
            
          </Button>
          <Box
            background="black"
            pad={{ horizontal: 'xxsmall' }}
            round
          >
            <Text >
              {likesProf.length}
            </Text>
          </Box>
        </Stack>
      </div>
      <h3
        onMouseMove={() => UpDateLikes}
        className="codLike">
          <FaRegHeart
          color='grey'
          title={`Você poderar dar like´s:) Em breve!!!${professional_id}, ${user_id},${likesProf.length},${countLike}`}
          />
      </h3>
    </>
  )
}