import { Accordion, AccordionPanel, Box, Text, Button } from 'grommet';
import { Lock } from 'grommet-icons';
import React from 'react';
import { FaLock } from 'react-icons/fa';
import fire from "../services/fire";
const btnResendEmail = ({ color = "", email = "", infoBtn = "" }) => {
  const sendPasswordResetEmail = () => {
    fire.auth().sendPasswordResetEmail(email);
    alert(`E-mail enviado pra ${email}`);
  };
  return (
    <Box direction="column"  >
      <Button color={color} size="large" type='reset' primary label="Redefinir Senha" onClick={sendPasswordResetEmail} />
      <Accordion pad="medium">
        <AccordionPanel header={<FaLock color='#533d0b' />}>
          <Box pad="small" background="light-2" >
            <Text>{infoBtn}</Text>
          </Box>
        </AccordionPanel>
      </Accordion>
    </Box>
  );
};
export default btnResendEmail;
