import {Box, Button, Grommet, Layer, Main, MaskedInput, TextInput } from 'grommet';
import React, { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import api from "../services/api";
import { FormDown, FormUp } from 'grommet-icons';
import { grommet, dark } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import BtnStyle from "./BtnStyleThemeType";
import { FiUser } from 'react-icons/fi';

const customRoundedTheme = deepMerge(grommet, {
  global: {
    focus: {
      border: {
        color: 'white',
      },
      shadow: {
        color: 'white',
      },
    },

    colors: {
      selected: 'status-warning',

    },
    control: {
      border: {
        radius: '0px',
        selected: 'dark-1',

      },
    },
    input: {
      weight: 700,
    },
    font: {
      size: '22px',
      weight: 700,
    },
  },
  text: {
    medium: '13px',
  },
  textInput: {
    extend: 'padding: 0 12px;',
  },
  select: {

    control: {
      extend: 'padding: 3px 6px;',
      open: {
        background: 'dark-1',
        border: '1px solid dark-1',
      },
      select: {
        background: 'dark-1',
      },
      section: {
        color: 'dark-1',
      }
    },
    icons: {
      down: FormDown,
      up: FormUp,
      color: 'light-1',
      margin: 'small',
    },
  },
});

const userMailBarber = localStorage.getItem('userEmail');

export default function NewUserCreate({
  mailUser = userMailBarber,
  iconService = 22,
  coloriconService = 'status-warning',
  iconSizeService = 35,
}) {

  const [onOpen, setOnOpen] = useState(false);
  const [bntInfoName, setBntInfoName] = useState(true);

  const [name, setName] = useState('');
  const [access, setAccess] = useState(0);
  const [email, setEmail] = useState(mailUser);
  const [cep] = useState('28600 000');
  const [pix] = useState('PIX');
  const [avatar] = useState('https://i.ibb.co/09vsNhQ/icon.png');
  const [andress] = useState('atualize seu endereço, Nº');
  const [likeUser] = useState(0);
  const [activated] = useState(true);
  
  const history = useHistory();

  async function CleanerInput() {
    setOnOpen(false);
    setBntInfoName(false);
    await setName('');
    await setAccess(0);
  }

  async function CreateNameUser(e: FormEvent) {
    e.preventDefault();

    const data = {
      name,
      email,
      cep,
      pix,
      avatar,
      access,
      andress,
      likeUser,
      activated
    };

    await api.post('users', data);
    alert(`User  ${name} cadastrado.`);
    CleanerInput();
  }

  const nameMask = [
    {
      regexp: /^[\w\-_.]+$/,
      placeholder: 'Seu nome ',
    },
    
  ];
  return (
    <Main>
      {bntInfoName && (
       <a 
      title='Cadastre seu Nome, para visualizar sua agenda, pai!'
      
      onClick={() => { setOnOpen(!onOpen) }}>
        <BtnStyle iconBtnBkcolor={coloriconService} iconsize={iconSizeService} icon={iconService} />
      </a> 
      )}
      
      {onOpen && (

        <Grommet theme={deepMerge(dark, customRoundedTheme)}>
          <Layer
            modal
            position='center'
            onClickOutside={() => setOnOpen(!onOpen)}
            onEsc={() => setOnOpen(!onOpen)}
          >
            <Box
              align='center'
              animation='slideUp'
            >

              <Box
                id="service"
                animation="zoomIn"
              >
                <form onSubmit={ CreateNameUser } className="create-profissional-form">
                  <Box
                    pad='small'
                    direction='row'
                  >
                    <Box pad={{ horizontal: 'small' }}><FiUser /></Box> Nome:
                  </Box>
                  <Box
                    pad={{ horizontal: 'small' }}
                    direction='row'
                    width='full'>
                    <MaskedInput
                      icon={<FiUser />}
                         mask={nameMask}
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                    
                    <Box pad={{ horizontal: 'xsmall' }} />
                  </Box>
                  <Box
                    pad='small'
                    direction='row'
                    width='full'>
                    <TextInput
                      disabled
                      placeholder='Seu e-mail'
                      title='Seu e-mail'
                      value={mailUser as any}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <Box pad={{ horizontal: 'xsmall' }} />
                  </Box>
                  <Box
                    direction="row"
                    pad="medium"
                    justify="center"
                    animation="fadeIn"
                    align="center"
                    alignSelf="center"
                    alignContent="center"
                  >
                    <Box
                      pad={{ horizontal: "medium" }}
                      animation="fadeIn"
                      align="center"
                      alignSelf="center"
                      alignContent="center"
                    >
                      <Button
                       type="submit"
                       >
                        <BtnStyle
                          iconBtnBkcolor={coloriconService}
                          icon={6} txtLabel={"Cadastrar"} />
                      </Button>
                    </Box>


                  </Box>
                  <Box id="qrinfo"></Box>
                </form>
              </Box>
            </Box>
          </Layer>
        </Grommet>
      )}
    </Main>
  )
}