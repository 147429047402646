import React, { Fragment } from 'react';
import { FiTrash2 } from "react-icons/fi";
import { useParams, useHistory } from 'react-router-dom';
import api from "../services/api";
import '../css/deleteAtt.css';
import { Box, Button, Form } from 'grommet';
import { Trash } from 'grommet-icons';

interface BarbershopQuery {
  id: string;
}

export default function DeleteAttribute({ id_delete = 0 }) {
  //  const [attribute, setAttribute] = useState([]);
  const query = useParams<BarbershopQuery>();
  const id_Att_Delete = id_delete;
  const history = useHistory();

  async function handleDeleteAttribute(e: any) {
    e.preventDefault();
    //   setAttribute(attribute.filter(attribute => attribute.id !== id_Att_Delete));
    try {
      await api.delete(`professional/${query.id}?attri=${id_Att_Delete}`)
      history.push(`/barbershop/${query.id}`);
      alert(`Profissinal Deletado.${id_Att_Delete}`);
    } catch (err) {
      alert('Erro ao Deletar Profissinal, e tente novamente.');
      //  history.push(`/barbershop/${barbershop}`);
    }
  }
  return (
    <Fragment>
      <Form >
        <Button
          onClick={handleDeleteAttribute}
          icon={
            <Trash size='30'
            color="status-warning" 
            />
          } 
          hoverIndicator >
        </Button>
      </Form>
    </Fragment>
  )
}