import React, { useState, useEffect, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import api from "../services/api";
import {
  Avatar, Box, Button, Form, Grommet, Image, Layer,
  Main, MaskedInput, Nav, Select, Text, TextInput
} from 'grommet';
import { Tip } from 'grommet/components/Tip';
import {
  FiCalendar, FiCode, FiEdit, FiHome,
  FiLink, FiLogOut, FiMail, FiMessageCircle,
  FiMessageSquare, FiPlus, FiSmile, FiThumbsUp, FiUser
} from 'react-icons/fi';
import ViewQrCode from "./ViewQrCode";
import '../css/prelooad.css';
import TipContentQrUser from "./TipContentQrUser";
import TipContent from "./TipContentIdUser";
import BtnStyle from "./BtnStyleThemeType";
import UpdateUsersAdm from "./UpdateUsersAdm";
import Tawkto from './TawkTo';
import Logout from './Logout';
import { ThemeType, dark, grommet } from 'grommet/themes';
import iconShop from '../../src/images/iconShop.svg'
import { Edit, FormDown, FormUp, User } from 'grommet-icons';
import { deepMerge } from 'grommet/utils';
import {
  BiBarcode, BiCool, BiCopyright,
  BiHome, BiHomeHeart, BiSave, BiShield, BiStore
} from 'react-icons/bi';
import { FaCannabis, FaChessKing, FaEdit, FaEnvelope, FaFistRaised, FaLemon, FaSave, FaSkull, FaUser, FaYinYang } from 'react-icons/fa';

const customRoundedTheme = deepMerge(grommet, {
  global: {
    focus: {
      border: {
        color: 'white',
      },
      shadow: {
        color: 'white',
      },
    },

    colors: {
      selected: 'status-warning',

    },
    control: {
      border: {
        radius: '0px',
        selected: 'dark-1',

      },
    },
    input: {
      weight: 700,
    },
    font: {
      size: '22px',
      weight: 700,
    },
  },
  text: {
    medium: '13px',
  },

  select: {

    control: {
      extend: 'padding: 3px 6px;',
      open: {
        background: 'dark-1',
        border: '1px solid dark-1',
      },
      select: {
        background: 'dark-1',
      },
      section: {
        color: 'dark-1',
      }
    },
    icons: {
      down: FaSkull,
      up: FormUp,
      color: 'light-1',
      margin: 'small',
    },
  },
});





const customThemeUserBarber: ThemeType = {
  global: {
    font: {
      family: 'Arial',
    },
  },

  button: {

    border: {
      radius: undefined,
      color: 'dark-3',
    },
    disabled: {
      color: '#FFCA58',
      opacity: '.1',
      border: {
        color: '#000000',
        width: '10px',
      },
    },

    primary: {
      color: 'dark-2',
    },
    extend: (txtBtn: any) => {
      let extraStyles = '';
      if (txtBtn.primary) {
        extraStyles = `
          text-transform: uppercase;
        `;
      }
      return `
        color: white;
        font-size: 22px;
        font-weight: bold;

        ${extraStyles}
      `;
    },
  },
};

interface BarbershopParams {
  id: string;
}
interface UserBarber {
  id: number;
  name: string;
  email: string;
  cep: string;
  pix: string;
  andress: string;
  avatar: string;
  access: number;
  likeUser: number;
  activated: boolean;
  scheduleView: Array<{
    id: number;
    user_id: number;
    professional_id: number;
    schedule_date: string;
  }>;
};
const UserId = localStorage.getItem('idBarbershopUser');
const UserEmail = localStorage.getItem('userEmail');

export default function ViewUserBarber({
  id_user = UserId || null, mail_user = UserEmail,
  pix_user = '', cep_user = '', avatar_user = '',
  access_user = 0, andress_user = '', activated_user = true,
  likeUser_user = 0, name_user = '', avatar_size = '130px'

}) {

  const defaultAvatar = [
    'BarberShopAppp',
    'Barber I',
    'Caveira I',
    'Caveira II',
    'Caveira III',
    'Caveira IV',
    'Caveira V',
    'Caveira VI',
    'Dim I',
    'Dim II',
    'Top I',
    'Top II',
    'Punk I',
    'Punk II',
    'Punk III',
    'Hipster I',
  ];

  function handleAvatar() {

    switch (avatarUp) {
      case ('BarberShopAppp'):
        setAvatar('https://i.ibb.co/7zZrkP8/barbershopapp-layout.png');
        break;
      case ('Caveira I'):
        setAvatar('https://i.ibb.co/B2Z0Hdr/01barbershhcav2.png');
        break;
      case ('Barber I'):
        setAvatar('https://i.ibb.co/whM5T67/b5.png');
        break;
      case ('Caveira II'):
        setAvatar('https://i.ibb.co/YbkDV71/barbershopapp-Cav.png');
        break;
      case ('Caveira III'):
        setAvatar('https://i.ibb.co/VQpbVMk/01barbershhcav.png');
        break;
      case ('Caveira IV'):
        setAvatar('https://i.ibb.co/HDW6tzK/01barbe1rshhcav.png');
        break;
      case ('Caveira V'):
        setAvatar('https://i.ibb.co/DVRtgzX/cav1.png');
        break;
      case ('Hipster I'):
        setAvatar('https://i.ibb.co/9nTJyF0/01barbe1rshhphone.png');
        break;
      case ('Top I'):
        setAvatar('https://i.ibb.co/1Xm1Fm5/barbershopapp7.png');
        break;
      case ('Top II'):
        setAvatar('https://i.ibb.co/ZSCM99W/top2.png');
        break;
      case ('Punk I'):
        setAvatar('https://i.ibb.co/Zf91b7R/steampunk.png');
        break;
      case ('Punk II'):
        setAvatar('https://i.ibb.co/dk0zfK1/punk.png');
        break;
      case ('Punk III'):
        setAvatar('https://i.ibb.co/vzhs7tv/steampunk1.png');
        break;
      case ('Dim I'):
        setAvatar('https://i.ibb.co/D7NqpZQ/dim.png');
        break;
      case ('Dim II'):
        setAvatar('https://i.ibb.co/ggQ1CXb/b6.png');
        break;
      case ('Caveira VI'):
        setAvatar('https://i.ibb.co/xSF75MS/cav2.png');
        break;

      default:
        setAvatar(avatar_user);
    }


  }


  function nwc() {
    return (

      <Main>
        <Box pad={{ horizontal: '100px', vertical: '160px' }} align="center" justify="center" direction="row">
          <div className="preloader17">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Box></Main>

    )
  }
  const [onOpen, setOnOpen] = useState(false);
  const [bntInfoName, setBntInfoName] = useState(true);

  const [name, setName] = useState(name_user);
  const [access, setAccess] = useState(access_user);
  const [id, setID] = useState(id_user);
  const [email, setEmail] = useState(mail_user);
  const [cep, setCep] = useState(cep_user);
  const [pix, setPix] = useState(pix_user);
  const [avatar, setAvatar] = useState(avatar_user);
  const [avatarUp, setAvatarUp] = useState(avatar_user);
  const [avatarSize, setAvatarSize] = useState(avatar_size);
  const [andress, setAndress] = useState(andress_user);
  const [likeUser, setLikeUser] = useState(likeUser_user);
  const [activated, setActivated] = useState(activated_user);

  const [userBarber, setUserBarber] = useState<UserBarber>();
  const [viewUser, setViewUser] = useState(false);
  const [showChatBot, setShowChatBot] = useState(false);
  const [count, setCount] = useState(0);
  const [userDetail, setUserDetail] = useState(false);
  const [editDetail, setEditDetail] = useState(false);
  const [editDetailAvatar, setEditDetailAvatar] = useState(false);
  const [userViewSchedule, setUserViewSchedule] = useState(false);
  const btnOpen = localStorage.getItem('btnOpen');
  const favicon = document.getElementById("favicon");

  async function UpdateUserForm(e: FormEvent) {
    e.preventDefault();

    const data = {
      id,
      name,
      email,
      cep,
      pix,
      avatar,
      access,
      andress,
      likeUser,
      activated
    };
    await api.put('user', data);
    alert(`User  ${name} cadastrado.`);
  }
  useEffect(() => {
    if (id_user === 0 as any || null) {
      nwc();
    }
    else {
      api.get(`user/${id_user}`).then(response => {
        setUserBarber(response.data);
      });
    }
  });
  if (!userBarber) {
    return (
      <Main>
        <Box pad={{ horizontal: '100px', vertical: '160px' }} align="center" justify="center" direction="row">
          <div className="preloader17">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Box></Main>
    );
  }
  //if (id_user === 0) {    return( <BtnStyle icon={22} />  )  }
  return (
    (viewUser ? (
      <Grommet theme={deepMerge(dark, customThemeUserBarber, customRoundedTheme)}>
        <Layer
          position='left'
          onClickOutside={() => {
            setViewUser(false);
            setEditDetailAvatar(false);
            setEditDetail(false);
            setUserDetail(false);
          }}
          onEsc={() => {
            setViewUser(false);
            setEditDetailAvatar(false);
            setEditDetail(false);
            setUserDetail(false);
          }}
        >
          <Box

            justify='end'
            align='end'
            width='full'
            pad={{ horizontal: '10px' }}
            margin={{ top: '25px', bottom: '-45px' }}
            onClick={() => {
              setViewUser(!viewUser);
              setEditDetailAvatar(false);
              setEditDetail(false);
            }}
          >
            <BtnStyle icon={3} />
          </Box>
          <Box

            align="center"
            justify="center"
            pad={{ horizontal: "small", vertical: "xsmall" }} >
            <Box
              animation='slideUp'
              margin={{ top: '-140px' }}
              align='center'
              pad={{ top: 'small' }}
              onClick={() => {
                setUserDetail(!userDetail);
                setEditDetailAvatar(false);
                setEditDetail(false);
                setUserViewSchedule(false)
              }}
            >
              <Avatar round='0' size='130px' src={userBarber.avatar} />
            </Box>
            <Box
              width='full'
              justify='between'
              direction="row" pad="xsmall" >
              <Box
                direction="row"
                align="center"
                pad={{ horizontal: "xlarge", vertical: "small" }} >
                <strong>
                  <Tip content={<TipContent message={`ID: ${userBarber.id}`} />}>
                    <h3>
                      {userBarber.name}
                    </h3>
                  </Tip>
                </strong>
              </Box>
            </Box>
            {userViewSchedule && (
              <>
                <strong><Text >Schedule:</Text></strong>
                {userBarber.scheduleView.map(scheduleV => {
                  return (
                    <Box
                      width='full'
                      title={`Barbeiro: ${scheduleV.professional_id}`}
                      direction="row"
                      pad="xxsmall">
                      <Box direction="row" pad="xxsmall" alignContent="center" alignSelf="center" align="center">
                        <Tip content={<TipContentQrUser message={<ViewQrCode txt_schedule={`${scheduleV.schedule_date}`} /> as any} />}>
                          <Link to={`/professional/${scheduleV.professional_id}`}>

                            <Box pad="xxsmall" >
                              <BtnStyle icon={18} />
                            </Box>
                          </Link>
                        </Tip>
                        <Box >
                          {scheduleV.schedule_date}
                        </Box>
                      </Box>
                    </Box>
                  )
                })}

              </>
            )}
            <Box
              direction="column"
              animation='slideUp'
            >
              <Box
                align="center"
                justify='between'
              >
                <Form
                  onSubmit={UpdateUserForm}
                >
                  {userDetail && (
                    <Box
                      animation='slideUp'
                    >

                      <Box
                        pad='xsmall'
                        align='center'
                        animation='slideRight'
                        onClick={() => {
                          setEditDetailAvatar(!editDetailAvatar);
                          setEditDetail(false)
                        }}
                      >
                        <Box
                          align='center'
                          alignSelf='center'
                          alignContent='center'
                          direction='row'
                        >
                          <Box pad='xxsmall'>
                            <FaSkull
                              color={editDetailAvatar ? '#FFCA58' : ''}
                            />
                          </Box>
                          {editDetailAvatar && (<Text>Alterar seu Nome & Avatar:</Text>)}
                        </Box>
                      </Box>
                      {editDetailAvatar && (
                        <Box>
                          <Box
                            pad={{ top: 'small' }}
                            direction='row'
                            width='full'>
                            <TextInput
                              icon={<FaUser />}
                              placeholder={userBarber.name}
                              title='Seu nome'
                              value={name as any}
                              onChange={e => setName(e.target.value)}
                            />
                          </Box>
                          <Box
                            animation='slideLeft'
                            pad={{ top: 'small' }}
                            direction='row'
                            align='center'
                            alignContent='center'
                            alignSelf='center'
                            width='full'>

                            <Box
                              pad={{ horizontal: 'small' }}
                              width='120px'
                            >
                              <Image
                                width='80px'
                                src={avatar}
                              />
                            </Box>
                            <Box
                              pad={{ horizontal: 'small' }}
                              width='full'
                            >
                              <MaskedInput
                                icon={<FiLink />}
                                mask={[{ fixed: 'https://i.ibb.co/' }, { regexp: /^.*$/ }]}
                                title='Avatar - siga as instruções :)'
                                placeholder='Avatar - siga as instruções :)'
                                value={avatar}
                                onChange={event => setAvatar(event.target.value)}
                              />
                            </Box>
                            <Box
                              title='Passe o mouse por cima,
                             para validar a sua opção!'
                              onMouseMove={() => handleAvatar()}
                              width='150px'>
                              <Select
                                placeholder='Defaults Avatar'
                                value={avatarUp}
                                options={defaultAvatar}
                                onChange={({ option }) => { setAvatarUp(option) }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                      <Box
                        pad='xsmall'
                        align='center'
                        animation='slideLeft'
                        onClick={() => {
                          setEditDetail(!editDetail);
                          setEditDetailAvatar(false)
                        }}
                      >
                        <Box
                          pad='xxsmall'
                          direction='row'
                        >
                          <Box pad='xxsmall'>
                            <FaEdit
                              color={editDetail ? '#FFCA58' : ''}
                            />
                          </Box>
                          {editDetail && (<Text>Editar seu endreço:</Text>)}
                        </Box>
                      </Box>
                      {editDetail && (
                        <Box animation='slideLeft'>
                          <Box
                            direction='row'
                            width='full'>
                            <TextInput
                              icon={<BiHomeHeart />}
                              placeholder={cep}
                              title='Seu Cep'
                              value={cep}
                              onChange={e => setCep(e.target.value)}
                            />
                            <Box pad={{ horizontal: 'xsmall' }} />
                            <TextInput
                              icon={<FaEnvelope />}
                              disabled
                              placeholder={email}
                              title='Seu e-mail'
                              value={email as any}
                              onChange={e => setEmail(e.target.value)}
                            />
                            <Box pad={{ top: 'xsmall' }} />

                          </Box>
                          <Box
                            pad={{ top: 'small' }}
                            direction='row'
                            width='full'>
                            <TextInput
                              icon={<BiHome />}
                              placeholder={andress}
                              title='Seu Cep'
                              value={andress}
                              onChange={e => setAndress(e.target.value)}
                            />
                            <Box pad={{ horizontal: 'xsmall' }} />
                            <TextInput
                              disabled={access <= 0 ? true : false}
                              icon={<BiBarcode />}
                              placeholder={userBarber.pix}
                              title='Seu PIX Qr'
                              value={pix}
                              onChange={e => setPix(e.target.value)}
                            />
                          </Box>
                        </Box>
                      )}

                      <Box
                        pad={{ top: 'small' }}
                        direction='row'
                        align='center'
                        justify='center'
                        alignSelf='center'
                        width='full'>
                      </Box>
                    </Box>
                  )}
                  {!userDetail && (
                    <Box direction='row'
                      justify='between'
                      animation={userViewSchedule ? 'slideRight' : 'slideUp'}
                      width='350px'
                    >
                      <Button
                        hoverIndicator={{ color: "#FFF", opacity: "medium", }}
                        icon={<FiCalendar size='40' color={userViewSchedule ? '#FFAA15' : ''} />}
                        onClick={() => { setUserDetail(false); setUserViewSchedule(!userViewSchedule) }}
                      />
                      <Button
                        hoverIndicator={{ color: "#FFF", opacity: "medium", }}
                        icon={<FiEdit size='40' color={userViewSchedule ? '#FFAA15' : ''} />}
                        onClick={() => { setUserDetail(true); setUserViewSchedule(false) }}
                      />

                      {access <= 3 && (
                        access > 0 && (
                          <Link to="/create/barbershops" >
                            <Button
                              hoverIndicator={{ color: "#FFF", opacity: "medium", }}
                              icon={<BiStore size='40' color={userViewSchedule ? '#FFAA15' : '#FF0'} />}
                              onClick={() => { setUserDetail(true); setUserViewSchedule(false) }}
                            />
                          </Link>
                        )
                      )}
                      {access === 4 as any && (
                        <Box
                          animation={userViewSchedule ? 'slideRight' : 'slideUp'}
                        >
                          <Link to="/adm/create/barbershops" >
                            <Button
                              hoverIndicator={{ color: "#FFF", opacity: "medium", }}
                              icon={<BiStore size='40' color={userViewSchedule ? '#FFAA150' : '#FF0000'} />}
                              onClick={() => { setUserDetail(true); setUserViewSchedule(false) }}
                            />
                          </Link>
                        </Box>
                      )}
                      {access === 5 as any && (
                        <Box
                          animation={userViewSchedule ? 'slideRight' : 'slideUp'}
                        >
                          <Link to="/adm/create/barbershops" >
                            <Button
                              hoverIndicator={{ color: "#FFF", opacity: "medium", }}
                              icon={<FaYinYang size='40' color={userViewSchedule ? '#FFAA15' : '#FFF'} />}
                              onClick={() => { setUserDetail(true); setUserViewSchedule(false) }}
                            />
                          </Link>
                        </Box>
                      )}
                      <Logout hoverIndicatorColor='#FFF' size='40' color={userViewSchedule ? '#FFAA15' : ''} />
                    </Box>
                  )}
                  {userDetail && (<>
                    <Box
                      animation={userViewSchedule ? 'slideRight' : 'slideUp'}
                      pad={{ horizontal: 'medium' }}
                      align='center'
                      alignSelf='center'
                      direction='row'
                      justify='between'
                      width='full'>
                      <Box
                        title='ChatBot
                        Precisa de ajuda, validar cadastro:)'
                        pad='small'
                        onClick={() => { setShowChatBot(true) }}
                        hoverIndicator
                      >
                        <FiMessageSquare
                          color={showChatBot && "#FFAA15" as any}
                          size='30'
                        />
                      </Box>
                      {showChatBot === true && (
                        <Tawkto />
                      )}
                      <Button
                        label="Atualizar cadastro"
                        type="submit"
                        hoverIndicator
                        icon={<FaSave />}
                      />
                      <Box
                        pad='small'
                        onClick={() => { }}
                        hoverIndicator
                        title='Art System Ti - By Netto'

                      >
                        <BiCopyright
                          title='Art System Ti - By Netto'
                          size='30'
                        />

                      </Box>
                      <Box
                        pad='small'
                        onClick={() => { }}
                        hoverIndicator
                        title='Art System Ti - By Netto'

                      >
                        <FaFistRaised
                          title='Art System Ti - By Netto'
                          size='30'
                        />

                      </Box>
                      <Box
                        pad='small'
                        onClick={() => {
                          alert(`Sr. ${name} em breve link de atualizações futuras. 
                        Dúvidas consulte-nos pelo ChatBot :)
                        `);
                        }}
                        hoverIndicator
                        title='Estamos trabalhando no Qr Pix, Aguarde!'

                      >
                        <FaLemon
                          color='#CDDC39'
                          title='Estamos trabalhando no Qr Pix, Aguarde!'
                          size='30'
                        />

                      </Box>

                    </Box>
                    <Box pad={{ top: 'xsmall' }} />
                  </>)}
                </Form>
              </Box>
            </Box>
          </Box>
          <Box
            align='center'
          >
            {access === 5 as any && (
              <Box pad={{ bottom: 'xsmall' }}>
                <UpdateUsersAdm
                  labelUpBtnUser='Administrador'

                />
              </Box>
            )}
          </Box>
        </Layer>
      </Grommet>

    ) : (
      <Nav >
        <Button
          margin={{ top: '25px' }}
          icon={<Avatar src={avatar} size={avatar_size} />}
          onClick={() => setViewUser(true)}
          hoverIndicator={{ color: "#FFF", opacity: "medium", }} />
      </Nav>
    ))

  )

}