import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from "../services/api";
import { Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, DateInput, Distribution, DropButton, Grommet, Layer, Main, Text } from 'grommet';
import BtnStyle from './BtnStyleThemeType';
import { ThemeType, dark, grommet } from 'grommet/themes';
import DeleteSchedule from "./DeleteSchedule";
import ViewQrCode from "./ViewQrCode";
import CreateQrCode from "./CreateQrCode";
import CreateSchedule from "./CreateSchedule";
import TipContentQr from "./TipContentQr";

import { deepMerge } from 'grommet/utils';
import { Tip } from 'grommet/components/Tip';
import { FiClock, FiCreditCard, FiMinusCircle, FiPlusCircle, FiScissors, FiXCircle } from 'react-icons/fi';
import TipContent from './TipContent';
import DeleteService from './DeleteServiceProfessional';
import { Clock, Schedule } from 'grommet-icons';

interface BarbershopProfParams {
  id: string;
}
interface BarbershopProf {
  name: String;

  email: String;
  pix: String;
  avatar: String;
  colorbarber: String;
  andress: String;
  opening_hours: String;
  open_on_weekends: true;
  activated: true;
  scheduleView: Array<{
    id: number;
    schedule_date: string;
    professional_id: number;
    user_id: number;

  }>;


}

interface ProfBarbershop {
  id: number;
  barbershop: number;
  name: string;
  email: string;
  whatsapp: number;
  instagram: string;
  avatar: string;
  pix: string;
  opening_hours: string;
  open_on_weekends: boolean;
  activated: boolean;
  scheduleView: Array<{
    id: number;
    schedule_date: string;
    professional_id: number;
    user_id: number;

  }>;

  servicesView: Array<{
    id: number;
    category: string;
    service_value: number;
    service_time: number;
    professional_id: number;
    user_id: number;
  }>;
  imagesView: Array<{
    id: number;
    url: string;
  }>;
}

interface BarbershopUser {
  id: number;
  name: string;
}

const customToggleTheme = {
  global: {
    colors: {
      'toggle-bg': 'dark-1',
      'toggle-knob': 'white',
      'toggle-accent': 'accent-2',
    },
  },
  checkBox: {
    border: {
      color: {
        light: 'toggle-bg',
      },
    },
    color: {
      light: 'toggle-knob',
    },
    check: {
      radius: '2px',
    },
    hover: {
      border: {
        color: undefined,
      },
    },
    toggle: {
      background: { light: 'toggle-bg' },
      color: {
        light: 'toggle-knob',
      },
      size: '56px',
      knob: {
        extend: `
          top: -4px;
          box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12),
           0px 2px 2px 0px rgba(0,0,0,0.24);
        `,
      },
      extend: (colorBr: any) => `
        height: 24px;
        background: ${colorBr};
      `,
    },
    gap: 'xsmall',
    size: '27px',
  },
};

const customThemeUpBarber: ThemeType = {
  global: {
    font: {
      family: 'Arial',
    },
  },
  button: {

    border: {
      radius: undefined,
      color: 'dark-3',
    },
    disabled: {
      color: '#fe2693',
      opacity: '.1',
      border: {
        color: '#000000',
        width: '10px',
      },
    },

    primary: {
      color: 'dark-2',
    },
    extend: (txtBtn: any) => {
      let extraStyles = '';
      if (txtBtn.primary) {
        extraStyles = `
          text-transform: uppercase;
        `;
      }
      return `
        color: white;
        font-size: 22px;
        font-weight: bold;

        ${extraStyles}
      `;
    },
  },
};
// verificar os likes functions e seguir por esse cainho
const idBarber = localStorage.getItem('idBarbershop');
const countLikeHeader = localStorage.getItem('X-Total-LikeProf');

export default function UpdateAgendaProf({ profId = 0, profName = '', userName = '', profIdSchelude = 0,
  txtScheduleProf = '', somaSchedule = 0, addDateScheduleProf = 'ticket IoI',
  activedProf = false, iconProflabel = '',
  iconProf = 8, sizeIconProf = 35, colorbarberTxtProf = 'status-warning',
  avatarProf = '', pixProf = '',
  emailProf = '', andressProf = '', nameProf = '',
  instagramProf = '', whatsappProf = '',
  activatedProf = true, opening_hoursProf = ''
  , open_on_weekendsProf = true }) {
  //const [barberUp, setBarberUp] = useState<BarbershopProf[]>([]);
  const params = useParams<BarbershopProfParams>();
  const [barberUp, setBarberUp] = useState<BarbershopProf>();
  const [barbershopUser, setBarbershopUser] = useState<BarbershopUser>();
  const [professional, setProfessional] = useState<ProfBarbershop>();

  const [userId, setUserId] = useState(0);

  const [professional_id, setProfessional_id] = useState(params.id);
  const [user_id, setUser_id] = useState(idBarber);
  const [newEdit, setNewEdit] = useState(true);
  const [onOpen, setOnOpen] = useState(false);
  const [countLike, setCountLikes] = useState();
  const history = useHistory();

  const [name, setName] = useState(nameProf);
  const [email, setEmail] = useState(emailProf);
  const [colorbarber, setColorbarber] = useState(colorbarberTxtProf);
  const [txtSchedule, setTxtSchedule] = useState(txtScheduleProf);
  const [avatar, setAvatar] = useState(avatarProf);
  const [whatsapp, setWhatsapp] = useState(whatsappProf);
  const [instagram, setInstagram] = useState(instagramProf);
  const [pix, setPix] = useState(pixProf);
  const [andress, setAndress] = useState(andressProf);
  const [opening_hours, setOpening_hours] = useState(opening_hoursProf);
  const [open_on_weekends, setOpen_on_weekends] = useState(open_on_weekendsProf);
  const [activated, setActivated] = useState(activatedProf);
  const [crash, setCrash] = useState(false);
  const [viewTxtQr, setViewTxtQr] = useState(true);
  const [soma, setSoma] = useState(somaSchedule);
  const [somaAdd, setSomaAdd] = useState(true);
  const [viewSchedule, setViewSchedule] = useState(true);
  const [addDateSchedule, setAddDateSchedule] = useState(addDateScheduleProf);
  const [colorBtnContaOk, setColorBtnContaOk] = useState('#fff');
  const [agendaOpen, setAgendaOpen] = useState(true);
  const [addProfissa, setAddProfissa] = useState(false);
  const [qrView, setQrView] = useState(false);


  function AddSub(totalSoma: any) {
    if (soma <= 0) {
      setSoma(0);
      setViewSchedule(true);
      setColorbarber(colorbarber);
      setSomaAdd(true);
      setSoma(0);
    }

    if (somaAdd) {

      setSoma(soma + totalSoma);
      if (addDateSchedule === 'ticket') {
        setColorbarber('status-warning');
      } else {
        setColorbarber('alert-Color');
      }
    }
    else {
      if (soma > 0) {
        setSoma(soma - totalSoma);
      }
      else {
        setSoma(0);
      }
    }
  }

  function AttributeUpDate() {
    api.get(`user/${idBarber}`).then(response => {
      setBarbershopUser(response.data);
    });

    setUserId(idBarber as any);

    api.get(`professional/${params.id}`).then(response => {
      setProfessional(response.data);
      // setUserId(`${idBarber}`);
      //setUserId(`${params.id}`); eu alterei aqui verificar se deu ruim,...
    });
  }
  useEffect(() => {
    api.get(`user/${idBarber}`).then(response => {
      setBarbershopUser(response.data);
    });

    setUserId(idBarber as any);

    api.get(`professional/${profId}`).then(response => {
      setProfessional(response.data);
    });


  }, [params.id]);


  useEffect(() => {
    api.get(`professional/${profId}`).then(response => {
      setBarberUp(response.data)
    });

  }, []);

  async function BarberProf() {
    const data = {
      name,
      email,
      pix,
      avatar,
      whatsapp,
      instagram,
      opening_hours,
      open_on_weekends,
      activated,
    };
    await api.put(`professional/${professional_id}`, data);

    alert(`Cadastro alterado com sucesso Sr. ${name} :)`);
  }

  return (
    <Main>
      <Box
        align='center'
      >
        <a onClick={() => { setOnOpen(!onOpen) }}>
          <BtnStyle
            txtLabel={iconProflabel} icon={iconProf} iconsize={sizeIconProf} iconBtnBkcolor={colorbarberTxtProf} />
        </a>
      </Box>
      {onOpen && (
        <Grommet theme={deepMerge(dark)}>

          <Layer
            modal
            position='center'
            onClickOutside={() => setOnOpen(!onOpen)}
            onEsc={() => setOnOpen(!onOpen)}
          >
            <Box
              justify='end'
              align='end'

              pad={{ horizontal: '10px' }}
              margin={{ top: '-20px', bottom: '-115px' }}
            >
              <a onClick={() => setOnOpen(!onOpen)} ><BtnStyle iconBtnBkcolor={colorbarberTxtProf} icon={3} /></a>
              <a onClick={() => setCrash(!crash)} ><BtnStyle icon={crash ? 22 : 18} iconBtnBkcolor={colorbarberTxtProf} /></a>
            </Box>

            <Box
              align='start'
              pad='xsmall'
              background='#000'
            >
              <Box
                background='#000'
                pad={{ horizontal: 'xsmall', top: 'xsmall', bottom: 'small' }}
              >
                <Box
                  direction='row'
                  width='600px'

                >
                  <Box
                    animation='slideUp'
                    pad={{ top: 'small' }}
                    onClick={() => { }}
                  >
                    <Avatar size='150px' src={avatarProf} />
                  </Box>

                  {crash ? (
                    <Box
                      animation='slideUp'
                      pad='xsmall'
                      border={{ color: colorbarberTxtProf, size: '5px', style: 'dashed' }}
                      width='380px'
                      height='180px'
                      onClick={() => setCrash(!crash)}
                    >
                      <Box
                        align='center'
                        pad={{ top: 'medium', vertical: 'small' }}
                      >
                        <Text alignSelf='center' ><h1><strong>{profName}</strong></h1> </Text>
                      </Box>
                      <Box pad='small'>
                        <Text color={colorbarberTxtProf}
                          alignSelf='center' >
                          <h3><strong>
                            {addDateScheduleProf}  | R$ {somaSchedule},00
                             </strong></h3>
                        </Text>
                      </Box>
                      <Box>
                        <Text alignSelf='center' ><h3><strong>{userName}</strong></h3> </Text>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      animation={crash ? 'slideLeft' : 'fadeIn'}
                      width='380px'
                      align='center'
                      alignSelf='center'
                    >
                      <Box
                        pad={{ vertical: 'small' }}
                        width='150px'
                        height='180px'
                        onClick={() => setCrash(!crash)}
                      >
                        <ViewQrCode txt_schedule={txtScheduleProf} />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              {crash ? (
                <Box
                onClick={() => setCrash(!crash)}
                background={colorbarberTxtProf}
                align='center'
                animation='slideRight'
                width='615px'
              >
                <Box 
               pad='small'
               >
                <Text alignSelf='center' >
                  <h3><strong>{txtScheduleProf}</strong></h3>
                </Text>
              </Box>
              </Box>
              ):(
                <Box
                onClick={() => setCrash(!crash)}
                background={colorbarberTxtProf}
                align='center'
                animation='slideLeft'
                width='615px'
              >
               <Box 
               pad='small'
               >
               <Text alignSelf='center' >
                  <h3><strong>Scaneie o Qr e pague pelo PIX!</strong></h3>
                </Text>
                 </Box>
              </Box>
              )}

              <Box
                width='615px'
              >
                <CreateSchedule
                  //  txt_schedule={`${barbershopUser?.name} ${txtScheduleProf} ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(soma)} `}
                  txt_schedule={txtScheduleProf}
                  data_schedule={addDateScheduleProf}
                  color_Schedule={colorbarber}
                  id_user_schedule={userId as any}
                  colorBtn={colorBtnContaOk}
                  viewSoma={somaSchedule}
                />
              </Box>

            </Box>
          </Layer>
        </Grommet>
      )}
    </Main>
  )
}