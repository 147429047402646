import firebase from 'firebase';

  var firebaseConfig = {
    apiKey: "AIzaSyD3vrnladZVlo8HxgEi31pOZsZG1USMeyE",
    authDomain: "barbershoppapp.firebaseapp.com",
    projectId: "barbershoppapp",
    storageBucket: "barbershoppapp.appspot.com",
    messagingSenderId: "593501766590",
    appId: "1:593501766590:web:8194f73331b0089d956fef",
    measurementId: "G-SFBPETGKDE"
  };
  // Initialize Firebase
const fire =  firebase.initializeApp(firebaseConfig);

export default fire;  