import React, { Fragment, useEffect } from 'react';
import { BiEraser } from "react-icons/bi";
import { useParams, useHistory } from 'react-router-dom';
import api from "../services/api";
import '../css/deleteAtt.css';
import { grommet, ThemeType, dark } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import { Trash } from 'grommet-icons';

interface BarbershopQuery {
  id: string;
}

export default function DeleteAttribute({ id_delete = 0 }) {
  //  const [attribute, setAttribute] = useState([]);
  const query = useParams<BarbershopQuery>();
  const id_Att_Delete = id_delete;
  const history = useHistory();

  async function handleDeleteAttribute(e: any) {
    e.preventDefault();
    //   setAttribute(attribute.filter(attribute => attribute.id !== id_Att_Delete));
    try {
      await api.delete(`attribute/${query.id}?attri=${id_Att_Delete}`)
      history.push(`/barbershop/${query.id}`);
      alert(`Atributo Deletado.${id_Att_Delete}`);
    } catch (err) {
      alert('Erro ao Deletar atributo, tente novamente.');
      //  history.push(`/barbershop/${barbershop}`);
    }
  }

  
  const customTheme: ThemeType = {
    global: {
        focus: {
            border: {
              color: 'white',
            },
            shadow: {
              color: 'white',
            },
          },
        font: {
            family: 'Arial',
        },
    },
   
    button: {
        border: {
            radius: undefined,
            color: 'dark-3',
        },
        disabled: {
            color: '#fe2693',
            opacity: '.1',
            border: {
                color: '#000000',
                width: '10px',
            },
        },

        primary: {
            color: 'dark-2',
        },
        extend: (tbnTxtAttri: any) => {
            let extraStyles = '';
            if (tbnTxtAttri.primary) {
                extraStyles = `
            text-transform: uppercase;
          `;
            }
            return `
          color: white;
          font-size: 22px;
          font-weight: bold;
  
          ${extraStyles}
        `;
        },
    },
};

useEffect(() => {
    

}, []);


  return (
    <Fragment>
      <form >
        <div className="deleta">
          <Trash className="delAtt" onClick={handleDeleteAttribute} size='20'/>
        </div>
      </form>
    </Fragment>
  )
}