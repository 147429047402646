import React, { useState, useEffect } from 'react';
import api from "../services/api";
import { Box, Main } from 'grommet';
import '../css/prelooad.css';
import UpUser from './UpUser';
interface UserBarber {
  id: number;
  name: string;
  email: string;
  avatar: string;
  cep: string;
  pix: string;
  andress: string;
  likeUser: number;
  access: number;
  activated: boolean;
  scheduleView: Array<{
    id: number;
    user_id: number;
    professional_id: number;
    schedule_date: string;
  }>;
};
//const history = useHistory();
const IdUserRef = localStorage.getItem('idBarbershopUser');

export default function ViewUserBarbers(user_email = '') {
  const [userBarbers, setUserBarbers] = useState<UserBarber[]>([]);
  useEffect(() => {
    api.get('users').then(response => {
      setUserBarbers(response.data);
    });
  }, []);
  if (IdUserRef === 0 as any) {
    return (
      <Main>
        <Box pad={{ horizontal: '100px', vertical: '160px' }} align="center" justify="center" direction="row">
          <div className="preloader17">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Box>
      </Main>);
  }
  if (!userBarbers) {
    return (
      <Main>
        <Box pad={{ horizontal: '100px', vertical: '160px' }} align="center" justify="center" direction="row">
          <div className="preloader17">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Box>
      </Main>);
  }
  return (
    <Box>
      {userBarbers.map(userbarber => {
        return (
          <Box
            align='center'
            key={userbarber.id}>
            {user_email === userbarber.email && (
              <a onMouseMove={() => localStorage.setItem('idBarbershop', userbarber.id as any)
              }>
                <UpUser
                avatar_size='140px'
                 id_user={userbarber.id as any}
                  name_user={userbarber.name}
                  activated_user={userbarber.activated}
                  pix_user={userbarber.pix}
                  cep_user={userbarber.cep}
                  avatar_user={userbarber.avatar}
                  access_user={userbarber.access}
                  andress_user={userbarber.andress}
                  likeUser_user={userbarber.likeUser}
                />
              </a>
            )}
          </Box>
        )
      })}
    </Box>

  )
}