import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from "../services/api";
import { useQrEncode, useQrDecode } from 'react-qr-hooks';
import { Box, Button, Image, Text } from 'grommet';
import { Ticket } from 'grommet-icons';

interface BarbershopParams {
  id: string;
}

export default function NewProfissionalSchedule({ txt_label = '', txt_schedule = '', data_schedule = '' }) {

  const encoded = useQrEncode(
    txt_schedule + data_schedule/* object with options (if needed) */
  );

  const decoded = useQrDecode(`${encoded}`);

  const params = useParams<BarbershopParams>();
  const [schedule_date, setScheduleDate] = useState('');
  const [professional_id, setProfessional_id] = useState(params.id);
  const [user_id, setUser_id] = useState();


  async function createScheduleQr() {

    const data = {
      schedule_date,
      professional_id,
      user_id
    };

    await api.post('schedules', data);
    alert(`Schedules ${schedule_date} :)`);

    console.log({
      schedule_date,
      professional_id,
      user_id
    })
  }

  return (
    <main>
     {/**   <Ticket size="25" color="#fff" /> */}
        <Box
          pad="xxsmall"
          background="dark-1">
          <Image
            src={`${encoded}`}
            fill="horizontal"
            fallback={txt_schedule + data_schedule}
            fit="cover" alt="Art System Ti - By QR code" />
        </Box>        
    </main>
  )
}