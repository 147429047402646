import { Box, Text } from 'grommet';
import React, { useState, useEffect } from 'react';

export default function TipContent ({ message = '' })  {
  const [messageTip, setMessageTip] = useState('ops..');

    useEffect(() => {
        setMessageTip(message);
      }, []);

      return(
        <Box
        animation="fadeIn"
        align="center"
        round={{ size: 'medium' }}
        pad="small"
         background="dark-1" 
           >
             <strong><Text alignSelf="center" size='70px' color="light-1">{messageTip}</Text></strong>
        </Box>
      )
};