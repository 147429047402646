import React, { useState, Fragment, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FiPlus, FiPlusCircle, FiUsers } from "react-icons/fi";
import { Avatar, Box, Button, CheckBox, Grid, Grommet, Header, Image, Layer, MaskedInput, Text, TextInput } from 'grommet';
import BtnStyle from './BtnStyleThemeType';
import { grommet, ThemeType, dark } from 'grommet/themes';
import api from "../services/api";
import { Anchor, Card, CardBody, CardFooter, CardHeader, Form, FormField, Heading, Nav } from 'grommet';
import { deepMerge } from 'grommet/utils';

interface Barbershop {

  id: number;
  latitude: number;
  longitude: number;
  name: string;
  email: string;
  avatar: string;
  colorbarber: string;
  pix: string;
  andress: string;
  opening_hours: string;
  open_on_weekends: string;
  activated: string;

  attributes: Array<{
    id: number;
    name: string;
  }>;
}
interface BarbershopParams {
  id: string;
}

export default function NewAttribute({ tbnTxtAttri = '', iconAttri = 10, sizeIconAttri = 55, colorAttri = '' }) {
  const params = useParams<BarbershopParams>();
  const [name, setNameAttribute] = useState('');
  const [txtLabel, setTxtLabel] = useState('');
  
  const [barbershop, setBarbershopId] = useState<Barbershop>();
  const history = useHistory();

  const [onOpen, setOnOpen] = useState(false);


  async function handleNewAttribute(e: any) {
    e.preventDefault();

    const data = {
      name,
      barbershop,
    };
    try {
      await api.post('attributes', data)
      history.push(`/barbershop/${barbershop}`);
      alert(`Atributo ${name} Cadastrado.`);
      CleanerInput();
    } catch (err) {
      alert('Erro ao cadastrar atributo, tente novamente.');
      //  history.push(`/barbershop/${barbershop}`);
    }
  }

  useEffect(() => {
    api.get(`barbershop/${params.id}`).then(response => {
      setBarbershopId(response.data);
    })
  }, [params.id]);

  async function CleanerInput() {

    await setNameAttribute('');

  }


  const customTheme: ThemeType = {
    global: {
        focus: {
            border: {
              color: 'white',
            },
            shadow: {
              color: 'white',
            },
          },
        font: {
            family: 'Arial',
        },
    },
   
    button: {
        border: {
            radius: undefined,
            color: 'dark-3',
        },
        disabled: {
            color: '#fe2693',
            opacity: '.1',
            border: {
                color: '#000000',
                width: '10px',
            },
        },

        primary: {
            color: 'dark-2',
        },
        extend: (tbnTxtAttri: any) => {
            let extraStyles = '';
            if (tbnTxtAttri.primary) {
                extraStyles = `
            text-transform: uppercase;
          `;
            }
            return `
          color: white;
          font-size: 22px;
          font-weight: bold;
  
          ${extraStyles}
        `;
        },
    },
};

useEffect(() => {
    setTxtLabel(txtLabel);

}, []);


  return (
    <Box align='center'>
      <a onClick={() => { setOnOpen(!onOpen) }}>
        <BtnStyle txtLabel={tbnTxtAttri} icon={iconAttri} iconsize={sizeIconAttri} iconBtnBkcolor={colorAttri} />
      </a>
      {onOpen && (
        <Grommet theme={deepMerge(dark, customTheme)}>

<Layer
            modal
            position='center'

            onClickOutside={() => setOnOpen(!onOpen)}
            onEsc={() => setOnOpen(!onOpen)}
          >
            <Box
                justify='end'
                align='end'
                width='full'
                pad={{horizontal:'10px'}}
               margin={{ top: '10px', bottom: '-45px'}}
              >
                <a onClick={() => setOnOpen(!onOpen)} ><BtnStyle iconBtnBkcolor={colorAttri} icon={3} /></a>
              </Box>

          <Card 
                animation='slideUp'
          pad="xxsmall" elevation="xsmall" round="xxsmall">
            <CardBody pad="xsmall">
              <Nav direction="row" justify="start" pad="small">
                <Form className="">
                  {/**
             * <input placeholder="O que têm de bom em sua barbershop..."
              value={name}
              onChange={e => setNameAttribute(e.target.value)}
              onBeforeInput={e => setBarbershopId(params.id)}
            />
             */}
                  <FormField >
                    <Box 
                    direction='row'>
                      <Heading level={3}> Novo Atributo: </Heading>
                      <Box
                        alignContent='center'
                        alignSelf='center'
                        pad={{horizontal:'small'}}
                      >
                        <Text color={colorAttri} size='small' alignSelf='start'>No máximo 8 caracteres</Text>
                      </Box>
                    </Box>


                    <TextInput className="inputTxtAtributo" placeholder="O que têm de bom em sua barbershop..."
                      value={name}
                      onChange={e => setNameAttribute(e.target.value)}
                      onBeforeInput={e => setBarbershopId(params.id as any)}
                    />
                  </FormField>

                </Form>
                <Box
                  alignSelf='center'
                  alignContent='center'
                  align='center'
                  justify='center'
                  className="addAtributo" >
                  <a onClick={handleNewAttribute}>
                    <BtnStyle icon={iconAttri} iconsize={sizeIconAttri} iconBtnBkcolor={colorAttri} />
                  </a>
                </Box>
              </Nav>
            </CardBody>
          </Card>
          </Layer>
        </Grommet>
      )}
    </Box>
  )
}