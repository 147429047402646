import { Box, Button } from 'grommet';
import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import fire from "../services/fire";
const btnLogout = ({ color = "#fff" , size = '28', hoverIndicatorColor = 'status-warning'}) => {
  const handleLogout = () => {
    localStorage.setItem('idBarbershopUser', 0 as any);
    localStorage.setItem('userEmail', null as any);
    localStorage.setItem('idBarbershop', 0 as any);
    localStorage.setItem('btnOpen', '0');
    fire.auth().signOut();
  };
  return (
    <Box>
      <Button
       title="< Logout"
              icon={<FiLogOut
                 color={color}
                 size={size}
              />}
              onClick={handleLogout}
              hoverIndicator={{ color: hoverIndicatorColor , opacity: "medium", }} />
        </Box>
  );
};
export default btnLogout;

