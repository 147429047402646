import React, { useState, FormEvent } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import api from "../services/api";
import { useQrEncode, useQrDecode } from 'react-qr-hooks';
import { Box, Button, Image } from 'grommet';
import { Calendar, ShareOption, Ticket } from 'grommet-icons';

interface BarbershopParams {
  id: string;
}

const idBarber = localStorage.getItem('idBarbershop');

export default function NewProfissionalSchedule({ link_to = '', txt_label = '', txt_schedule = '', data_schedule = '' }) {

  const encoded = useQrEncode(
    txt_schedule + data_schedule/* object with options (if needed) */
  );

  const decoded = useQrDecode(`${encoded}`);

  const params = useParams<BarbershopParams>();
  const [schedule_date, setScheduleDate] = useState('');
  const [professional_id, setProfessional_id] = useState(params.id);
  const [user_id, setUser_id] = useState(idBarber);

  const history = useHistory();

  async function CleanerInput() {
    await setScheduleDate('');
    await setProfessional_id(params.id);
    await setUser_id(idBarber);
  }

  async function createScheduleQr() {

    const data = {
      schedule_date,
      professional_id,
      user_id
    };

    await api.post('schedules', data);
    alert(`Schedules ${schedule_date} :)`);
    CleanerInput();

    history.push(`/professional/${params.id}`);
    console.log({
      schedule_date,
      professional_id,
      user_id
    })
  }
  return (
    <main>
      <Box
        pad={{ horizontal: "medium" }}
        justify="between"
        direction="row"
      >
        <Box
          pad="medium"
          align="center"
          justify="center"
        >
          <h3>{txt_label}</h3>
        </Box>
        <Box
          pad="medium"
        >
          <Link to={`${link_to}`}>
            <Button
              onClick={createScheduleQr}
              icon={<ShareOption size='38' />}
            />
          </Link>
        </Box>
      </Box>
      <Box
      >
        <Image
          src={`${encoded}`}
          fill="horizontal"
          fallback={txt_schedule + data_schedule}
          fit="cover" alt="Art System Ti - By QR code" />
      </Box>
    </main>
  )
}