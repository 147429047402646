import { Box, Button, Diagram, DiagramConnectionAnchor, DiagramConnectionType, Grommet, Layer, Main, MaskedInput, Select, Stack, Text } from 'grommet';
import React, { useState, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from "../services/api";
import { FormDown, FormUp, User } from 'grommet-icons';
import { grommet, dark } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import BtnStyle from "./BtnStyleThemeType";
import { FaInfoCircle, FaQrcode } from 'react-icons/fa';

const anchor: DiagramConnectionAnchor = 'horizontal';
const type: DiagramConnectionType = 'curved';

const connection = {
  id: 'shimi',
  anchor,
  color: '#FFF',
  thickness: 'xsmall',
  type,
  toTarget: 'yummy',
  fromTarget: 'gremlin',
  round: true,
};

const connections = [connection];

interface BarbershopParams {
  id: string;
}

const customRoundedTheme = deepMerge(grommet, {
  global: {
    focus: {
      border: {
        color: 'white',
      },
      shadow: {
        color: 'white',
      },
    },

    colors: {
      selected: 'status-warning',

    },
    control: {
      border: {
        radius: '0px',
        selected: 'dark-1',

      },
    },
    input: {
      weight: 700,
    },
    font: {
      size: '22px',
      weight: 700,
    },
  },
  text: {
    medium: '13px',
  },
  textInput: {
    extend: 'padding: 0 12px;',
  },
  select: {

    control: {
      extend: 'padding: 3px 6px;',
      open: {
        background: 'dark-1',
        border: '1px solid dark-1',
      },
      select: {
        background: 'dark-1',
      },
      section: {
        color: 'dark-1',
      }
    },
    icons: {
      down: FormDown,
      up: FormUp,
      color: 'light-1',
      margin: 'small',
    },
  },
});
export default function NewProfissionalService({
  iconService = 22,
  coloriconService = 'status-warning',
  iconSizeService = 35,


}) {

  const [onOpen, setOnOpen] = useState(false);

  const params = useParams<BarbershopParams>();
  const [category, setProfissaCategory] = useState('');
  const [service_value, setProfissaCategoryValue] = useState('');
  const [service_time, setProfissaCategoryTime] = useState('');
  const [professional_id, setProfBarbershopId] = useState('');

  const [infoQr, setInfoQr] = useState(false);


  const history = useHistory();
  const options = ['Barba', 'Tesoura', 'Máquina', 'Pézinho', 'Sobrancelha', 'Navalha'];

  async function CleanerInput() {
    await setProfissaCategory('');
    await setProfissaCategoryValue('');
    await setProfissaCategoryTime('');
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const data = {
      category,
      service_value,
      service_time,
      professional_id,
    };

    await api.post('services', data);
    alert(`Service ${category} cadastrado.`);
    CleanerInput();

    history.push(`/professional/${params.id}`);
    console.log({
      category,
      service_value,
      service_time,
      professional_id,
    })
  }

  return (
    <Main>
      <a onClick={() => { setOnOpen(!onOpen) }}>
        <BtnStyle iconBtnBkcolor={coloriconService} iconsize={iconSizeService} icon={iconService} />
      </a>
      {onOpen && (

        <Grommet theme={deepMerge(dark, customRoundedTheme)}>
          <Layer
            modal
            position='center'
            onClickOutside={() => setOnOpen(!onOpen)}
            onEsc={() => setOnOpen(!onOpen)}
          >
            <Box
              align='center'
              animation='slideUp'
            >

              <Box
                id="service"
                animation="zoomIn"
              >
                <form onSubmit={handleSubmit} className="create-profissional-form">
                  <fieldset>
                    <Box
                      width='full'
                      justify='end'
                      align='end'
                      margin={{ top: '-20px', bottom: '-25px' }}
                    >
                      <a onClick={() => setOnOpen(!onOpen)} ><BtnStyle iconBtnBkcolor={coloriconService} icon={3} /></a>
                    </Box>
                    <div
                      id="open"
                      className="attributeCadProfissa">
                      <div className="input-block">
                        <label htmlFor="Category">Serviço:  {category}</label>
                        <Box
                        >
                          <Select

                            placeholder="Select"
                            value={category}
                            options={options}
                            onChange={({ option }) => setProfissaCategory(option)}
                            onOpen={e => setProfBarbershopId(params.id)}
                          />
                        </Box>
                      </div>

                      <Box pad={{ vertical: 'small' }}>
                        Valor do serviço:  {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(service_value as any)}
                        <Box pad={{ vertical: 'xsmall' }} />
                        <MaskedInput
                          title={Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(service_value as any)}
                          placeholder=" Entre com o valor inteiro & use ponto para separar os centavos:)"
                          value={service_value}
                          onChange={event => setProfissaCategoryValue(event.target.value)}
                        />
                      </Box>

                      <Box pad={{ vertical: 'small' }}>
                        Tempo do serviço: {service_time} minutos
                      <Box pad={{ vertical: 'xsmall' }} />
                        <MaskedInput
                          title={Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(service_value as any)}
                          placeholder=" Tempo do serviço:"
                          value={service_time}
                          onChange={event => setProfissaCategoryTime(event.target.value)}
                        />
                      </Box>


                    </div>
                  </fieldset>
                  <Box
                    direction="row"
                    pad="medium"
                    justify="center"
                    animation="fadeIn"
                    align="center"
                    alignSelf="center"
                    alignContent="center"
                  >
                    <Box
                      pad={{ horizontal: "medium" }}
                      animation="fadeIn"
                      align="center"
                      alignSelf="center"
                      alignContent="center"
                    >
                      <Button type="submit">
                        <BtnStyle iconBtnBkcolor={coloriconService} icon={6} txtLabel={"Cadastrar"} />
                      </Button>
                    </Box>

                    <a href="#qrinfo" className="noLink">
                      <Box
                        pad={{ horizontal: "medium" }}
                        animation="fadeIn"
                        align="center"
                        alignSelf="center"
                        alignContent="center"
                      >
                        <Button
                          icon={
                            <FaInfoCircle size='35'
                              color="#fff"
                            />
                          }
                          onClick={() => { setInfoQr(!infoQr) }}
                          hoverIndicator={{ color: coloriconService, opacity: "strong", }} />
                      </Box>
                    </a>
                  </Box>
                  <Box id="qrinfo"></Box>
                  {!infoQr === true ? (
                    <></>
                  ) : (
                    <Grommet theme={deepMerge(dark)}>
                      <a href="#open" className="noLink">
                        <Box
                          animation="fadeIn"
                          onClick={() => { setInfoQr(false); }}
                        >
                          <Box
                            direction="column"
                            animation="fadeIn"
                            align="center"
                            justify="center"
                            pad="xxsmall">
                            <strong><Text color="#FFF">Cadastre um serviço:</Text></strong>
                            <strong><Text color="#FFF">Assim fica fácil gerar um Qr PIX!</Text></strong>
                            <strong><Text color="#FFF">Seu cliente agradece!</Text></strong>
                          </Box>
                          <Stack
                          >
                            <Box fill pad="xlarge">
                              <Box align="end"
                              >
                                <User id="gremlin" color="#FFF" size="xlarge" />
                              </Box>
                              <Box align="start" pad={{ vertical: 'large' }}>
                                <Box pad="xxsmall">
                                  <FaQrcode
                                    id="yummy"
                                    color="#FFF"
                                    size="80px" />
                                </Box>
                              </Box>
                            </Box>
                            <Diagram connections={connections} />
                          </Stack>
                        </Box>
                      </a>
                    </Grommet>
                  )}
                </form>
              </Box>
            </Box>
          </Layer>
        </Grommet>
      )}
    </Main>
  )
}