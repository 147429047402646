import { Box, Text } from 'grommet';
import React, { useState, useEffect } from 'react';

export default function TipContent({ message = '' }) {
  const [messageTip, setMessageTip] = useState('ops..');

  useEffect(() => {
    setMessageTip(message);

  }, []);

  return (
    <Box
      round="small"
      align="center"
      alignSelf="center"
      alignContent="center"
      animation="fadeIn"
      justify='center'
      background="dark-1"
      pad={{ vertical: "10px" }}
      direction="row" >
      <Box
        background="dark-1"
        align="center"
        alignSelf="center"
        alignContent="center"
        animation="fadeIn"
        justify='center'
      >
        <strong><Text alignSelf="center" size="large" color="light-1">{message}</Text></strong>
      </Box>
      <Box
        pad={{ horizontal: "5px" }}
        background="dark-1"
        direction="row"
      >
        <svg viewBox="0 0 22 22" version="1.1" width="50px" height="50px"><polygon fill="grey" points="6 2 18 12 6 22" transform="matrix(-1 0 0 1 30 0)" /></svg>
      </Box>
    </Box>
  )
};