import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from "../services/api";
import { useQrEncode, useQrDecode } from 'react-qr-hooks';
import { Box, Button, Grommet, Image, Text, ThemeType } from 'grommet';
import { IceCream, Ticket } from 'grommet-icons';
import { deepMerge } from 'grommet/utils';

interface BarbershopParams {
  id: string;
}
const customTheme: ThemeType = {

  carousel: {

    animation: {
      duration: 600,
    },
    icons: {
      color: "#ff0",
    },
    disabled: {
      icons: {
        color: "#ff0",
      },
    },
  },
  global: {

    focus: {
      border: {
        color: "#ff0",
      },
      shadow: {
        color: 'white',
      },
    },
    font: {
      family: 'Arial',
    },
    hover: {
      color: "#ff0",
    },
  },

  button: {
    border: {
      radius: undefined,
      color: 'dark-3',
    },
    hover: {
      color: "#ff0",
    },
    disabled: {
      color: '#ffffff48',
      opacity: '.1',
      border: {
        color: '#000000',
        width: '10px',
      },
    },

    primary: {
      color: 'dark-2',
    },
    extend: (txtBtn: any) => {
      let extraStyles = '';
      if (txtBtn.primary) {
        extraStyles = `
          text-transform: uppercase;
        `;
      }
      return `
        color: white;
        font-size: 22px;
        font-weight: bold;

        ${extraStyles}
      `;
    },
  },
};

export default function NewQrShare({ zap_share = '', txt_link_share = '', name_share = '' }) {

  const encoded = useQrEncode(
    `${name_share}:
    ${zap_share === '00 00000 0000' ? '' : zap_share}

    ${txt_link_share}
    `
  );

  const decoded = useQrDecode(encoded as any);

  const params = useParams<BarbershopParams>();
  const [schedule_date, setScheduleDate] = useState('');
  const [professional_id, setProfessional_id] = useState(params.id);
  const [user_id, setUser_id] = useState();


  async function createScheduleQr() {

    const data = {
      schedule_date,
      professional_id,
      user_id
    };

    await api.post('schedules', data);
    alert(`Schedules ${schedule_date} :)`);

    console.log({
      schedule_date,
      professional_id,
      user_id
    })
  }

  return (
    <main>
       <Grommet theme={deepMerge(customTheme)}>
      {/**   <Ticket size="25" color="#fff" /> */}
      <Box
        pad="xxsmall"
        background="dark-1">

        <Image
          src={encoded as any}
          fill="horizontal"
       //   fallback={txt_schedule + data_schedule}
          fit="cover" alt="Art System Ti - By QR code" />
         
      </Box>
      </Grommet>
    </main>
  )
}