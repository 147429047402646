
import { Box, Button, grommet, Grommet, Image, dark, ThemeType } from 'grommet';
import { Calculator, Map, Services, ShareOption, Trash } from 'grommet-icons';
import React, { useState, useEffect } from 'react';
import { FiArrowRightCircle, FiCalendar, FiCheck, FiEdit, FiGlobe, FiHome, FiInfo, FiInstagram, FiList, FiMail, FiMap, FiMapPin, FiMeh, FiMenu, FiPlay, FiShoppingCart, FiSmile, FiX } from 'react-icons/fi';
import { TiTicket } from "react-icons/ti";

import iconProfCard from '../../src/images/barbershopapp_layout_icon1.svg';
import iconProfessional from '../../src/images/barbershopapp_layout_icon2.svg';
import iconLocal from '../../src/images/barbershopapp_layout_icon4.svg';
import iconMap from '../../src/images/barbershopapp_layout_icon3.svg';
import { BiBarcodeReader, BiPalette, BiSprayCan, BiTimeFive } from "react-icons/bi";
import { BsBootstrapFill } from "react-icons/bs";

import iconShop from '../../src/images/iconShop.svg'
import { FaInstagram, FaRoute, FaWhatsapp } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import { deepMerge } from 'grommet/utils';

export default function BtnStyle({ iconTarget = '', iconTitle = '', iconType = true, txtLabel = '', icon = 0, iconsize = 32, iconcolor = '#FFF', iconBtnBkcolor = "status-warning", href = '' }) {

    const [messageTxtLabel, setTxtLabel] = useState('ops..');



    const customTheme: ThemeType = {
        global: {
            focus: {
                border: {
                    color: 'white',
                },
                shadow: {
                    color: 'white',
                },
            },
            font: {
                family: 'Arial',
            },
        },

        button: {
            border: {
                radius: undefined,
                color: 'dark-3',
            },
            disabled: {
                color: '#fe2693',
                opacity: '.1',
                border: {
                    color: '#000000',
                    width: '10px',
                },
            },

            primary: {
                color: 'dark-2',
            },
            extend: (txtBtn: any) => {
                let extraStyles = '';
                if (txtBtn.primary) {
                    extraStyles = `
                text-transform: uppercase;
              `;
                }
                return `
              color: white;
              font-size: 22px;
              font-weight: bold;
      
              ${extraStyles}
            `;
            },
        },
    };

    useEffect(() => {
        setTxtLabel(txtLabel);

    }, []);

    return (
        <Grommet theme={deepMerge(customTheme)}>
            <Box
                align="center"
                alignSelf="center"
                alignContent="center"
                animation="fadeIn"
                justify="between"
                direction="row"
                pad="xsmall">
                {txtLabel === '' ? (
                    <></>
                ) : (
                    <Box pad="small">
                        <Button
                            type='submit'
                            href={href}
                            label={<strong>{txtLabel}</strong>}
                            primary />
                    </Box>
                )}
                <Box onClick={() => { }} pad="xsmall" hoverIndicator={{ color: iconBtnBkcolor, opacity: "strong", }}>
                    {icon === 1 ? (
                        <FiShoppingCart
                            size={`${iconsize}`}
                            color={`${iconcolor}`}

                        />
                    ) : (
                        <>
                            {icon === 2 ? (
                                <Map
                                    size={`${iconsize}`}
                                    color={`${iconcolor}`}
                                />
                            ) : (
                                <>
                                    {icon === 3 ? (
                                        <FiX
                                            size={`${iconsize}`}
                                            color={`${iconcolor}`}
                                        />
                                    ) : (
                                        <>
                                            {icon === 4 ? (
                                                <FiEdit
                                                    size={`${iconsize}`}
                                                    color={`${iconcolor}`}
                                                />
                                            ) : (
                                                <>
                                                    {icon === 5 ? (
                                                        <FiCalendar
                                                            title={`${iconTitle}`}
                                                            size={`${iconsize}`}
                                                            color={`${iconcolor}`}
                                                        />
                                                    ) : (
                                                        <>
                                                            {icon === 6 ? (
                                                                <FiCheck
                                                                    target={`${iconTarget}`}
                                                                    title={`${iconTitle}`}
                                                                    size={`${iconsize}`}
                                                                    color={`${iconcolor}`}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {icon === 7 ? (
                                                                        <FiGlobe
                                                                            size={`${iconsize}`}
                                                                            color={`${iconcolor}`}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            {icon === 8 ? (
                                                                                <Image src={iconProfessional} height={`${iconsize}`}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {icon === 9 ? (
                                                                                        <Image src={iconMap} height={`${iconsize}`} />
                                                                                    ) : (
                                                                                        <>
                                                                                            {icon === 10 ? (

                                                                                                <Image src={iconLocal} height={`${iconsize}`} />

                                                                                            ) : (
                                                                                                <>
                                                                                                    {icon === 11 ? (
                                                                                                        <FaWhatsapp
                                                                                                            size={`${iconsize}`}
                                                                                                            color={`${iconcolor}`}
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {icon === 12 ? (
                                                                                                                <FiInstagram
                                                                                                                    size={`${iconsize}`}
                                                                                                                    color={`${iconcolor}`}
                                                                                                                />
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {icon === 13 ? (
                                                                                                                        <FaRoute
                                                                                                                            size={`${iconsize}`}
                                                                                                                            color={`${iconcolor}`}
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {icon === 14 ? (
                                                                                                                                <FiMail
                                                                                                                                    size={`${iconsize}`}
                                                                                                                                    color={`${iconcolor}`}
                                                                                                                                />
                                                                                                                            ) : (
                                                                                                                                <>
                                                                                                                                    {icon === 15 ? (
                                                                                                                                        <FiList
                                                                                                                                            size={`${iconsize}`}
                                                                                                                                            color={`${iconcolor}`}
                                                                                                                                        />
                                                                                                                                    ) : (
                                                                                                                                        <>
                                                                                                                                            {icon === 16 ? (
                                                                                                                                                <FiArrowRightCircle
                                                                                                                                                    size={`${iconsize}`}
                                                                                                                                                    color={`${iconcolor}`}
                                                                                                                                                />
                                                                                                                                            ) : (
                                                                                                                                                <>
                                                                                                                                                    {icon === 17 ? (
                                                                                                                                                        <FiHome
                                                                                                                                                            size={`${iconsize}`}
                                                                                                                                                            color={`${iconcolor}`}
                                                                                                                                                        />
                                                                                                                                                    ) : (
                                                                                                                                                        <>
                                                                                                                                                            {icon === 18 ? (
                                                                                                                                                                <TiTicket
                                                                                                                                                                    size={`${iconsize}`}
                                                                                                                                                                    color={`${iconcolor}`}
                                                                                                                                                                />
                                                                                                                                                            ) : (
                                                                                                                                                                <>
                                                                                                                                                                    {icon === 19 ? (
                                                                                                                                                                        <FiMenu
                                                                                                                                                                            size={`${iconsize}`}
                                                                                                                                                                            color={`${iconcolor}`}
                                                                                                                                                                        />
                                                                                                                                                                    ) : (
                                                                                                                                                                        <>
                                                                                                                                                                            {icon === 20 ? (
                                                                                                                                                                                <FiInfo
                                                                                                                                                                                    size={`${iconsize}`}
                                                                                                                                                                                    color={`${iconcolor}`}
                                                                                                                                                                                />
                                                                                                                                                                            ) : (
                                                                                                                                                                                <>
                                                                                                                                                                                    {icon === 21 ? (
                                                                                                                                                                                        <BsBootstrapFill
                                                                                                                                                                                            size={`${iconsize}`}
                                                                                                                                                                                            color={`${iconcolor}`}
                                                                                                                                                                                        />
                                                                                                                                                                                    ) : (
                                                                                                                                                                                        <>
                                                                                                                                                                                            {icon === 22 ? (
                                                                                                                                                                                                <BiBarcodeReader
                                                                                                                                                                                                    size={`${iconsize}`}
                                                                                                                                                                                                    color={`${iconcolor}`}
                                                                                                                                                                                                />
                                                                                                                                                                                            ) : (
                                                                                                                                                                                                <>
                                                                                                                                                                                                    {icon === 23 ? (
                                                                                                                                                                                                        <BiSprayCan
                                                                                                                                                                                                            size={`${iconsize}`}
                                                                                                                                                                                                            color={`${iconcolor}`}
                                                                                                                                                                                                        />
                                                                                                                                                                                                    ) : (
                                                                                                                                                                                                        <>
                                                                                                                                                                                                            {icon === 25 ? (
                                                                                                                                                                                                                <BiTimeFive
                                                                                                                                                                                                                    size={`${iconsize}`}
                                                                                                                                                                                                                    color={`${iconcolor}`}
                                                                                                                                                                                                                />
                                                                                                                                                                                                            ) : (
                                                                                                                                                                                                                <>
                                                                                                                                                                                                                    {icon === 26 ? (
                                                                                                                                                                                                                        <BiPalette
                                                                                                                                                                                                                            size={`${iconsize}`}
                                                                                                                                                                                                                            color={`${iconcolor}`}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    ) : (
                                                                                                                                                                                                                        <>
                                                                                                                                                                                                                            {icon === 27 ? (
                                                                                                                                                                                                                                <FiMeh
                                                                                                                                                                                                                                    size={`${iconsize}`}
                                                                                                                                                                                                                                    color={`${iconcolor}`}
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                            ) : (
                                                                                                                                                                                                                                <>
                                                                                                                                                                                                                                    {icon === 28 ? (
                                                                                                                                                                                                                                        <FiSmile
                                                                                                                                                                                                                                            size={`${iconsize}`}
                                                                                                                                                                                                                                            color={`${iconcolor}`}
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                    ) : (
                                                                                                                                                                                                                                        <>
                                                                                                                                                                                                                                            {icon === 29 ? (
                                                                                                                                                                                                                                                <Trash
                                                                                                                                                                                                                                                    size={`${iconsize}`}
                                                                                                                                                                                                                                                    color={`${iconcolor}`}
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                            ) : (
                                                                                                                                                                                                                                                <>
                                                                                                                                                                                                                                                    {icon === 30 ? (
                                                                                                                                                                                                                                                        <ShareOption
                                                                                                                                                                                                                                                            size={`${iconsize}`}
                                                                                                                                                                                                                                                            color={`${iconcolor}`}
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                    ) : (
                                                                                                                                                                                                                                                        <>
                                                                                                                                                                                                                                                            {icon === 31 ? (
                                                                                                                                                                                                                                                                <Services
                                                                                                                                                                                                                                                                    size={`${iconsize}`}
                                                                                                                                                                                                                                                                    color={`${iconcolor}`}
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                            ) : (
                                                                                                                                                                                                                                                                <>
                                                                                                                                                                                                                                                                    {icon === 32 ? (
                                                                                                                                                                                                                                                                        <Calculator
                                                                                                                                                                                                                                                                            size={`${iconsize}`}
                                                                                                                                                                                                                                                                            color={`${iconcolor}`}
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                    ) : (
                                                                                                                                                                                                                                                                        <>
                                                                                                                                                                                                                                                                            {icon === 33 ? (
                                                                                                                                                                                                                                                                                <Image src={iconShop} height={`${iconsize}`} />

                                                                                                                                                                                                                                                                            ) : (
                                                                                                                                                                                                                                                                                <Image src={iconLocal} height={`${iconsize}`} />
                                                                                                                                                                                                                                                                            )}
                                                                                                                                                                                                                                                                        </>
                                                                                                                                                                                                                                                                    )}
                                                                                                                                                                                                                                                                </>
                                                                                                                                                                                                                                                            )}
                                                                                                                                                                                                                                                        </>
                                                                                                                                                                                                                                                    )}
                                                                                                                                                                                                                                                </>
                                                                                                                                                                                                                                            )}
                                                                                                                                                                                                                                        </>
                                                                                                                                                                                                                                    )}
                                                                                                                                                                                                                                </>
                                                                                                                                                                                                                            )}
                                                                                                                                                                                                                        </>
                                                                                                                                                                                                                    )}
                                                                                                                                                                                                                </>
                                                                                                                                                                                                            )}
                                                                                                                                                                                                        </>
                                                                                                                                                                                                    )}
                                                                                                                                                                                                </>
                                                                                                                                                                                            )}
                                                                                                                                                                                        </>
                                                                                                                                                                                    )}
                                                                                                                                                                                </>
                                                                                                                                                                            )}
                                                                                                                                                                        </>
                                                                                                                                                                    )}
                                                                                                                                                                </>
                                                                                                                                                            )}
                                                                                                                                                        </>
                                                                                                                                                    )}
                                                                                                                                                </>
                                                                                                                                            )}
                                                                                                                                        </>
                                                                                                                                    )}
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}

                </Box>
            </Box>
        </Grommet>
    )
}


{/**(icon === 1 ? ( <Cart size='32' />) : (<MapLocation  size='32' /> )) */ }

{/**

switch (icon) {
    case 1:
        setTxtLabel(txtLabel);
        break;

    case 2:
        setTxtLabel(txtLabel);
        break;

    default:
        setTxtLabel(txtLabel);
}

*/}
