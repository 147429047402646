import React, { useState,  useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from "../services/api";
import { Avatar, Box,  CheckBox,  Grommet, Layer, MaskedInput, Text, TextInput } from 'grommet';
import BtnStyle from './BtnStyleThemeType';
import { ThemeType, dark } from 'grommet/themes';

import { deepMerge } from 'grommet/utils';
import { FaBarcode, FaBlackTie, FaClock, FaMapSigns, FaSkull, FaSprayCan, FaUser } from 'react-icons/fa';
import { BiBarcode, BiSprayCan } from 'react-icons/bi';

interface BarbershopParams {
  id: string;
}
interface Barbershop {
  name: String;
  latitude: number;
  longitude: number;
  email: String;
  pix: String;
  avatar: String;
  colorbarber: String;
  andress: String;
  opening_hours: String;
  open_on_weekends: true;
  activated: true;

}

const customToggleTheme = {
  global: {
    colors: {
      'toggle-bg': 'dark-1',
      'toggle-knob': 'white',
      'toggle-accent': 'accent-2',
    },
  },
  checkBox: {
    border: {
      color: {
        light: 'toggle-bg',
      },
    },
    color: {
      light: 'toggle-knob',
    },
    check: {
      radius: '2px',
    },
    hover: {
      border: {
        color: undefined,
      },
    },
    toggle: {
      background: { light: 'toggle-bg' },
      color: {
        light: 'toggle-knob',
      },
      size: '56px',
      knob: {
        extend: `
          top: -4px;
          box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12),
           0px 2px 2px 0px rgba(0,0,0,0.24);
        `,
      },
      extend: (colorBr: any) => `
        height: 24px;
        background: ${colorBr};
      `,
    },
    gap: 'xsmall',
    size: '27px',
  },
};

const customThemeUpBarber: ThemeType = {
  global: {
    font: {
      family: 'Arial',
    },
  },
  button: {

    border: {
      radius: undefined,
      color: 'dark-3',
    },
    disabled: {
      color: '#FFCA58',
      opacity: '.1',
      border: {
        color: '#000000',
        width: '10px',
      },
    },

    primary: {
      color: 'dark-2',
    },
    extend: (txtBtn: any) => {
      let extraStyles = '';
      if (txtBtn.primary) {
        extraStyles = `
          text-transform: uppercase;
        `;
      }
      return `
        color: white;
        font-size: 22px;
        font-weight: bold;

        ${extraStyles}
      `;
    },
  },
};
// verificar os likes functions e seguir por esse cainho
const idBarber = localStorage.getItem('idBarbershop');
const countLikeHeader = localStorage.getItem('X-Total-LikeProf');

export default function UpdateBarber({ tbnTxtBarber = '', iconBarber = 8, sizeIconBarber = 35, colorbarberTxtBarber = '',
  avatarBarber = '', pixBarber = '',
  emailBarber = '', andressBarber = '', nameBarber = '',
  activatedBarber = true, opening_hoursBarber = ''
  , open_on_weekendsBarber = true, latitudeBarber = 0, longitudeBarber = 0 }) {
  const [barberUp, setBarberUp] = useState<Barbershop[]>([]);
  const params = useParams<BarbershopParams>();
  const [professional_id, setProfessional_id] = useState(params.id);
  const [user_id, setUser_id] = useState(idBarber);
  const [newEdit, setNewEdit] = useState(false);
  const [onOpen, setOnOpen] = useState(false);
  const [countLike, setCountLikes] = useState();
  const history = useHistory();

  const [name, setName] = useState(nameBarber);
  const [latitude, setLatitude] = useState(latitudeBarber);
  const [longitude, setLongitude] = useState(longitudeBarber);
  const [email, setEmail] = useState(emailBarber);
  const [colorbarber, setColorbarber] = useState(colorbarberTxtBarber);
  const [avatar, setAvatar] = useState(avatarBarber);
  const [pix, setPix] = useState(pixBarber);
  const [andress, setAndress] = useState(andressBarber);
  const [opening_hours, setOpening_hours] = useState(opening_hoursBarber);
  const [open_on_weekends, setOpen_on_weekends] = useState(open_on_weekendsBarber);
  const [activated, setActivated] = useState(activatedBarber);
  const [viewNewEdit, setViewNewEdit] = useState(false);


  useEffect(() => {
    api.get('barbershop').then(response => {
      setBarberUp(response.data)
    });

  }, []);

  async function BarberUpdate() {
    const data = {
      name,
      latitude,
      longitude,
      email,
      pix,
      avatar,
      colorbarber,
      andress,
      opening_hours,
      open_on_weekends,
      activated,
    };
    await api.put(`barbershop/${professional_id}`, data);

    alert(`Cadastro alterado com sucesso Sr. ${name} :)`);
  }

  return (
    <Box align='center'>
      <a onClick={() => { setOnOpen(!onOpen) }}>
        <BtnStyle txtLabel={tbnTxtBarber} icon={iconBarber} iconsize={sizeIconBarber} iconBtnBkcolor={colorbarber} />
      </a>

      {onOpen && (
        <Grommet theme={deepMerge(dark)}>

          <Layer
            modal
            position='center'

            onClickOutside={() => setOnOpen(!onOpen)}
            onEsc={() => setOnOpen(!onOpen)}
          >
             <Box
                justify='end'
                align='end'
                width='full'
                pad={{horizontal:'10px'}}
               margin={{ top: '10px', bottom: '-125px'}}
              >
                <a onClick={() => setOnOpen(!onOpen)} ><BtnStyle iconBtnBkcolor={colorbarber} icon={3} /></a>
              </Box>
            <Box
              direction='row'
              animation='slideUp'
              alignSelf='center'
              align='center'
              justify='between'
              alignContent='center'
              pad={{ top: 'small' }}
              onClick={() => { setViewNewEdit(!viewNewEdit) }}
            >
              <Avatar size='150px' src={avatar} />
             
            </Box>

            <Box
              animation='slideUp'
              width='500px'
              pad='small'
              align='center'
              direction='column'>
              <Box
                pad='small'
                width='full'>
                <Box >
                  <MaskedInput
                  icon={<FaUser />}
                    title='Nome'
                    placeholder='Nome'
                    value={name}
                    onChange={event => setName(event.target.value)}
                  />
                </Box>

                {viewNewEdit && (
                  <Box
                   animation='slideUp'
                  >

                    


                    <Box pad={{ vertical: 'small' }}>
                      <MaskedInput
                       icon={<FaSkull />}
                        mask={[{ fixed: 'https://i.ibb.co/' }, { regexp: /^.*$/ }]}
                        title='Avatar - siga as instruções :)'
                        placeholder='Avatar - siga as instruções :)'
                        value={avatar}
                        onChange={event => setAvatar(event.target.value)}
                      />

                    </Box>
                    <Box background={colorbarber}>
                      <MaskedInput
                      icon={<FaSprayCan />}
                        title='Cor da sua barbearia, exemplo #ddff47'
                        placeholder='#ddff47'
                        mask={[{ fixed: '#' }, { regexp: /^.*$/ }]}
                        value={colorbarber}
                        onChange={event => setColorbarber(event.target.value)}
                      />
                    </Box>
                    <Box pad={{ vertical: 'small' }}>
                      <MaskedInput
                        icon={<FaMapSigns/>}
                        mask={[{ fixed: 'Nº ' }, { regexp: /^.*$/ }]}
                        placeholder='Nº, ou coloque uma referência:)'
                        title='Nº, ou coloque uma referência:)'
                        value={andress}
                        onChange={event => setAndress(event.target.value)}
                      />
                    </Box>
                    <Box
                      align='center'
                      direction='row'>
                      <Box>
                        <MaskedInput
                        icon={<FaBarcode/>}
                          placeholder='Seu código Pix'
                          title='Seu código Pix'
                          value={pix}
                          onChange={event => setPix(event.target.value)}
                        />
                      </Box>
                      <Box pad={{ left: 'small' }}>
                        <MaskedInput
                        icon={<FaClock/>}
                          placeholder='9 às 18h'
                          title='Horário de atendimento'
                          value={opening_hours}
                          onChange={event => setOpening_hours(event.target.value)}
                        />
                      </Box>
                    </Box>
                    </Box>
                )}


              </Box>


              <Box
                width='500px'
                pad='small'
                justify='between'
                direction='row'>
                <CheckBox toggle label={activated ? 'Ativa On' : 'Ativa Off'} checked={activated}

                  onChange={() => { setNewEdit(!newEdit); setActivated(!activated) }}
                />
                <CheckBox toggle label={open_on_weekends ? 'Fim de semana On' : 'Fim de semana Off'} checked={open_on_weekends}
                  onChange={() => { setOpen_on_weekends(!open_on_weekends) }}
                />
                <a
                  title='Confirmar Aleração:)'
                  onClick={event => { BarberUpdate(); setOnOpen(false) }}>
                  <BtnStyle txtLabel={tbnTxtBarber} icon={iconBarber} iconsize={sizeIconBarber} iconBtnBkcolor={colorbarber} />
                </a>
              </Box>
            </Box>
          </Layer>
        </Grommet>
      )}
    </Box>
  )
}