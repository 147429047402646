import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from "../services/api";
import { Avatar, Box, Grommet,  Layer, Main, Text  } from 'grommet';
import BtnStyle from './BtnStyleThemeType';
import {  ThemeType, dark } from 'grommet/themes';
import DeleteSchedule from "./DeleteSchedule";
import ViewQrCode from "./ViewQrCode";
import CreateQrCode from "./CreateQrCode";
import TipContentQr from "./TipContentQr";

import { deepMerge } from 'grommet/utils';
import { Tip } from 'grommet/components/Tip';
import { FiCreditCard } from 'react-icons/fi';

interface BarbershopProfParams {
  id: string;
}
interface BarbershopProf {
  name: String;

  email: String;
  pix: String;
  avatar: String;
  colorbarber: String;
  andress: String;
  opening_hours: String;
  open_on_weekends: true;
  activated: true;
  scheduleView: Array<{
    id: number;
    schedule_date: string;
    professional_id: number;
    user_id: number;

  }>;


}

interface ProfBarbershop {
  id: number;
  barbershop: number;
  name: string;
  email: string;
  whatsapp: number;
  instagram: string;
  avatar: string;
  pix: string;
  opening_hours: string;
  open_on_weekends: boolean;
  activated: boolean;
  scheduleView: Array<{
    id: number;
    schedule_date: string;
    professional_id: number;
    user_id: number;

  }>;
}

interface BarbershopUser {
  id: number;
  name: string;
}

const customToggleTheme = {
  global: {
    colors: {
      'toggle-bg': 'dark-1',
      'toggle-knob': 'white',
      'toggle-accent': 'accent-2',
    },
  },
  checkBox: {
    border: {
      color: {
        light: 'toggle-bg',
      },
    },
    color: {
      light: 'toggle-knob',
    },
    check: {
      radius: '2px',
    },
    hover: {
      border: {
        color: undefined,
      },
    },
    toggle: {
      background: { light: 'toggle-bg' },
      color: {
        light: 'toggle-knob',
      },
      size: '56px',
      knob: {
        extend: `
          top: -4px;
          box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12),
           0px 2px 2px 0px rgba(0,0,0,0.24);
        `,
      },
      extend: (colorBr: any) => `
        height: 24px;
        background: ${colorBr};
      `,
    },
    gap: 'xsmall',
    size: '27px',
  },
};

const customThemeUpBarber: ThemeType = {
  global: {
    font: {
      family: 'Arial',
    },
  },
  button: {

    border: {
      radius: undefined,
      color: 'dark-3',
    },
    disabled: {
      color: '#fe2693',
      opacity: '.1',
      border: {
        color: '#000000',
        width: '10px',
      },
    },

    primary: {
      color: 'dark-2',
    },
    extend: (txtBtn: any) => {
      let extraStyles = '';
      if (txtBtn.primary) {
        extraStyles = `
          text-transform: uppercase;
        `;
      }
      return `
        color: white;
        font-size: 22px;
        font-weight: bold;

        ${extraStyles}
      `;
    },
  },
};
// verificar os likes functions e seguir por esse cainho
const idBarber = localStorage.getItem('idBarbershop');
const countLikeHeader = localStorage.getItem('X-Total-LikeProf');

export default function UpdateAgendaProf({ profId = 0,
  tbnTxtProf = '', iconProf = 8, sizeIconProf = 35, colorbarberTxtProf = 'status-warning',
  avatarProf = '', pixProf = '',
  emailProf = '', andressProf = '', nameProf = '',
  instagramProf = '', whatsappProf = '',
  activatedProf = true, opening_hoursProf = ''
  , open_on_weekendsProf = true }) {
  //const [barberUp, setBarberUp] = useState<BarbershopProf[]>([]);
  const params = useParams<BarbershopProfParams>();
  const [barberUp, setBarberUp] = useState<BarbershopProf>();
  const [barbershopUser, setBarbershopUser] = useState<BarbershopUser>();
  const [professional, setProfessional] = useState<ProfBarbershop>();

  const [userId, setUserId] = useState(0);

  const [professional_id, setProfessional_id] = useState(params.id);
  const [user_id, setUser_id] = useState(idBarber);
  const [newEdit, setNewEdit] = useState(false);
  const [onOpen, setOnOpen] = useState(false);
  const [countLike, setCountLikes] = useState();
  const history = useHistory();

  const [name, setName] = useState(nameProf);
  const [email, setEmail] = useState(emailProf);
  const [colorbarber, setColorbarber] = useState(colorbarberTxtProf);
  const [avatar, setAvatar] = useState(avatarProf);
  const [whatsapp, setWhatsapp] = useState(whatsappProf);
  const [instagram, setInstagram] = useState(instagramProf);
  const [pix, setPix] = useState(pixProf);
  const [andress, setAndress] = useState(andressProf);
  const [opening_hours, setOpening_hours] = useState(opening_hoursProf);
  const [open_on_weekends, setOpen_on_weekends] = useState(open_on_weekendsProf);
  const [activated, setActivated] = useState(activatedProf);
  const [crash, setCrash] = useState(false);
  const [viewTxtQr, setViewTxtQr] = useState(true);


  function AttributeUpDate() {
    api.get(`user/${idBarber}`).then(response => {
      setBarbershopUser(response.data);
    });

    setUserId(idBarber as any);

    api.get(`professional/${params.id}`).then(response => {
      setProfessional(response.data);
      // setUserId(`${idBarber}`);
      //setUserId(`${params.id}`); eu alterei aqui verificar se deu ruim,...
    });
  }
  useEffect(() => {
    api.get(`user/${idBarber}`).then(response => {
      setBarbershopUser(response.data);
    });

    setUserId(idBarber as any);

    api.get(`professional/${profId}`).then(response => {
      setProfessional(response.data);
    });


  }, [params.id]);


  useEffect(() => {
    api.get(`professional/${profId}`).then(response => {
      setBarberUp(response.data)
    });

  }, []);

  async function BarberProf() {
    const data = {
      name,
      email,
      pix,
      avatar,
      whatsapp,
      instagram,
      opening_hours,
      open_on_weekends,
      activated,
    };
    await api.put(`professional/${professional_id}`, data);

    alert(`Cadastro alterado com sucesso Sr. ${name} :)`);
  }

  return (
    <Main>



      <a onClick={() => { setOnOpen(!onOpen) }}>
        <BtnStyle txtLabel={tbnTxtProf} icon={iconProf} iconsize={sizeIconProf} iconBtnBkcolor={colorbarberTxtProf} />
      </a>


      {onOpen && (
        <Grommet theme={deepMerge(dark)}>

          <Layer
            modal
            position='center'
            onClickOutside={() => setOnOpen(!onOpen)}
            onEsc={() => setOnOpen(!onOpen)}
          >
             <Box
                justify='end'
                align='end'
                width='full'
                pad={{horizontal:'10px'}}
               margin={{ top: '10px', bottom: '-115px'}}
              >
                <a onClick={() => setOnOpen(!onOpen)} ><BtnStyle iconBtnBkcolor={colorbarberTxtProf} icon={3} /></a>
                <a onClick={() => setCrash(!crash)} ><BtnStyle iconBtnBkcolor={colorbarberTxtProf} icon={crash ? 22 : 29} /></a>
              </Box>
            <Box
              align='center'
              direction='row-responsive'
              justify='center'
            >
              <Box
                animation='slideUp'
                align='start'
                width='200px'
                pad={{ top: 'small', horizontal: 'large' }}
              >
                <Avatar size='100px' src={avatarProf} />
              </Box>
              <Box
                pad='large'
                align='center'

              >
                <h1 ><strong>{nameProf}</strong></h1>
              </Box>
              <Box
                justify='end'
                align='end'
                width='100px'
              // margin={{ top: '-120px', bottom: '-125px' }}
              >
              </Box>
            </Box>
            <Box>
              <div className="attributes">{barberUp?.scheduleView.map(schedule => {
                return (
                  <Box
                    onClick={() => { setViewTxtQr(!viewTxtQr) }}
                    direction='row-responsive' >
                    {crash ? (
                      <Box>
                        <DeleteSchedule id_delete={schedule.id} />
                        <Box
                        justify='between'
                        background='dark-1'
                        pad='xsmall'
                        direction='row-responsive'
                          align='center'>
                        <FiCreditCard size={20} color="#FFF" />
                          <Text>{schedule.id}</Text>
                        </Box>
                      </Box>

                    ) : (
                      viewTxtQr ? (
                        <Box
                          pad='xsmall'
                        >

                          <ViewQrCode data_schedule={schedule.schedule_date} />
                        </Box>
                      ) : (
                        <Box
                          pad='small'
                        >
                          {schedule.schedule_date}
                        </Box>
                      )
                    )}


                  </Box>
                )
              })}</div>
            </Box>
          </Layer>
        </Grommet>
      )}
    </Main>
  )
}