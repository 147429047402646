import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from "../services/api";
import { Avatar, Box, CheckBox, Grommet, Layer, MaskedInput, TextInput } from 'grommet';
import BtnStyle from './BtnStyleThemeType';
import { ThemeType, dark } from 'grommet/themes';

import { deepMerge } from 'grommet/utils';
import { FiInstagram, FiLink, FiMail, FiUser } from 'react-icons/fi';
import { BiBarcode, BiSprayCan, BiTime } from 'react-icons/bi';
import { FaWhatsapp } from 'react-icons/fa';

interface BarbershopParams {
  id: string;
}
interface Barbershop {
  name: String;
  latitude: number;
  longitude: number;
  email: String;
  pix: String;
  avatar: String;
  colorbarber: String;
  andress: String;
  opening_hours: String;
  open_on_weekends: true;
  activated: true;

}


const emailMask = [
  {
    regexp: /^[\w\-_.]+$/,
    placeholder: 'example',
  },
  { fixed: '@' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'my',
  },
  { fixed: '.' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'com',
  },
];

const maskZap = [
  {
    length: 2,
    regexp: /^[0-9]{1,2}$/,
    placeholder: 'xx',
  },
  { fixed: ' ' },
  {
    length: 5,
    regexp: /^[0-9]{1,5}$/,
    placeholder: 'xxxxx',
  },
  { fixed: ' ' },
  {
    length: 4,
    regexp: /^[0-9]{1,4}$/,
    placeholder: 'xxxx',
  },
]

const zapMask = [
  {
    regexp: /^[\w\-_.]+$/,
    placeholder: 'example',
  },
  { fixed: '@' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'my',
  },
  { fixed: '.' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'com',
  },
];

const customToggleTheme = {
  global: {
    colors: {
      'toggle-bg': 'dark-1',
      'toggle-knob': 'white',
      'toggle-accent': 'accent-2',
    },
  },
  checkBox: {
    border: {
      color: {
        light: 'toggle-bg',
      },
    },
    color: {
      light: 'toggle-knob',
    },
    check: {
      radius: '2px',
    },
    hover: {
      border: {
        color: undefined,
      },
    },
    toggle: {
      background: { light: 'toggle-bg' },
      color: {
        light: 'toggle-knob',
      },
      size: '56px',
      knob: {
        extend: `
          top: -4px;
          box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12),
           0px 2px 2px 0px rgba(0,0,0,0.24);
        `,
      },
      extend: (colorBr: any) => `
        height: 24px;
        background: ${colorBr};
      `,
    },
    gap: 'xsmall',
    size: '27px',
  },
};

const customThemeUpBarber: ThemeType = {
  global: {
    font: {
      family: 'Arial',
    },
  },
  button: {

    border: {
      radius: undefined,
      color: 'dark-3',
    },
    disabled: {
      color: '#fe2693',
      opacity: '.1',
      border: {
        color: '#000000',
        width: '10px',
      },
    },

    primary: {
      color: 'dark-2',
    },
    extend: (txtBtn: any) => {
      let extraStyles = '';
      if (txtBtn.primary) {
        extraStyles = `
          text-transform: uppercase;
        `;
      }
      return `
        color: white;
        font-size: 22px;
        font-weight: bold;

        ${extraStyles}
      `;
    },
  },
};
// verificar os likes functions e seguir por esse cainho
const idBarber = localStorage.getItem('idBarbershop');
const countLikeHeader = localStorage.getItem('X-Total-LikeProf');

export default function UpdateProf({ tbnTxtProf = '', iconProf = 8, sizeIconProf = 35, colorbarberTxtProf = '',
  avatarProf = '', pixProf = '',
  emailProf = '', andressProf = '', nameProf = '',
  instagramProf = '', whatsappProf = '',
  activatedProf = true, opening_hoursProf = ''
  , open_on_weekendsProf = true }) {
  const [barberUp, setBarberUp] = useState<Barbershop[]>([]);
  const params = useParams<BarbershopParams>();
  const [professional_id, setProfessional_id] = useState(params.id);
  const [user_id, setUser_id] = useState(idBarber);
  const [newEdit, setNewEdit] = useState(false);
  const [onOpen, setOnOpen] = useState(false);
  const [countLike, setCountLikes] = useState();
  const history = useHistory();

  const [name, setName] = useState(nameProf);
  const [email, setEmail] = useState(emailProf);
  const [colorprof, setColorprof] = useState(colorbarberTxtProf);
  const [avatar, setAvatar] = useState(avatarProf);
  const [whatsapp, setWhatsapp] = useState(whatsappProf);
  const [instagram, setInstagram] = useState(instagramProf);
  const [pix, setPix] = useState(pixProf);
  const [andress, setAndress] = useState(andressProf);
  const [opening_hours, setOpening_hours] = useState(opening_hoursProf);
  const [open_on_weekends, setOpen_on_weekends] = useState(open_on_weekendsProf);
  const [activated, setActivated] = useState(activatedProf);
  const [viewNewEdit, setViewNewEdit] = useState(false);


  useEffect(() => {
    api.get('professional').then(response => {
      setBarberUp(response.data)
    });

  }, []);

  async function BarberProf() {
    const data = {
      name,
      email,
      pix,
      avatar,
      whatsapp,
      instagram,
      colorprof,
      opening_hours,
      open_on_weekends,
      activated,
    };
    await api.put(`professional/${professional_id}`, data);

    alert(`Cadastro alterado com sucesso Sr. ${name} :)`);
  }

  return (
    <Box
      animation='slideUp'
      align='center'>
      <a onClick={() => { setOnOpen(!onOpen) }}>
        <BtnStyle txtLabel={tbnTxtProf} icon={iconProf} iconsize={sizeIconProf} iconBtnBkcolor={colorbarberTxtProf} />
      </a>

      {onOpen && (
        <Grommet theme={deepMerge(dark)}>
          <Layer
            modal
            position='center'
            onClickOutside={() => setOnOpen(!onOpen)}
            onEsc={() => setOnOpen(!onOpen)}
          >
            <Box
              justify='end'
              align='end'
              width='full'
              pad={{ horizontal: '10px' }}
              margin={{ top: '10px', bottom: '-125px' }}
            >
              <a onClick={() => setOnOpen(!onOpen)} ><BtnStyle iconBtnBkcolor={colorbarberTxtProf} icon={3} /></a>
            </Box>
            <Box
              animation='slideUp'
              align='center'
              pad={{ top: 'small' }}
              onClick={() => setViewNewEdit(!viewNewEdit)}
            >
              <Avatar size='150px' src={avatar} />
            </Box>

            <Box
              animation='slideUp'
              width='500px'
              pad='small'
              align='center'
              direction='column'>
              <Box
                animation='slideUp'
                pad='small'
                width='full'>
                <Box pad={{ vertical: 'small' }}>
                  <TextInput
                    icon={<FiUser />}
                    title='Nome'
                    placeholder='Nome'
                    value={name}
                    onChange={event => setName(event.target.value)}
                  />
                </Box>
                {viewNewEdit && (
                  <Box
                    animation='slideUp'
                  >
                    <Box
                    >
                      <MaskedInput
                        icon={<FiMail />}
                        placeholder='email'
                        title='ATENÇÃO - ao alterar só terá acesso com esse e-mail'
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        mask={emailMask}
                      />

                    </Box>
                    <Box pad={{ vertical: 'xsmall' }} />
                    <Box
                      round='xsmall'
                      background={avatar === 'https://i.ibb.co/09vsNhQ/icon.png' ? "#FFF" : colorprof}
                    >
                      <MaskedInput
                        icon={<FiLink />}
                        mask={[{ fixed: 'https://i.ibb.co/' }, { regexp: /^.*$/ }]}
                        title='Avatar - siga as instruções :)'
                        placeholder='Avatar - siga as instruções :)'
                        value={avatar}
                        onChange={event => setAvatar(event.target.value)}
                      />
                    </Box>
                    <Box pad={{ vertical: 'xsmall' }} />
                    <Box
                      round='xsmall'
                      background={instagram === 'https://www.instagram.com/barbershopapp1' ? "#FFF" : colorprof}>
                      <MaskedInput
                        icon={<FiInstagram />}

                        title='@instagram'
                        placeholder='https://www.instagram.com/seuinstagram'
                        mask={[{ fixed: 'https://www.instagram.com/' }, { regexp: /^.*$/ }]}
                        value={instagram}
                        onChange={event => setInstagram(event.target.value)}
                      />
                    </Box>
                    <Box

                      direction='row'
                      pad={{ vertical: 'small' }}>
                      <Box
                        round='xsmall'
                        background={pix === 'PIX' ? "#FFF" : colorprof}
                      >
                        <TextInput
                          icon={<BiBarcode />}
                          placeholder='Seu código Pix'
                          title='Seu código Pix'
                          value={pix}
                          onChange={event => setPix(event.target.value)}
                        />

                      </Box>

                      <Box pad={{ horizontal: 'xsmall' }} />
                      <Box
                        background={colorprof}
                        round='xsmall'
                      >
                        <TextInput
                          icon={<BiSprayCan />}
                          placeholder='Seu código colorprof'
                          title='Seu código colorprof'
                          value={colorprof}
                          onChange={event => setColorprof(event.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      align='center'
                      direction='row'>
                      <Box
                        round='xsmall'
                        background={whatsapp === '00 00000 0000' ? "#FFF" : colorprof}
                      >
                        <MaskedInput
                          mask={maskZap}
                          icon={<FaWhatsapp />}
                          placeholder='00 00000 0000 - Whatsapp'
                          title='whatsapp'
                          value={whatsapp}
                          onChange={event => setWhatsapp(event.target.value)}
                        />
                      </Box>
                      <Box
                        pad={{ left: 'small' }}>
                        <Box
                          round='xsmall'
                          background={opening_hours === '9 às 18h' ? colorprof : ''}>
                          <TextInput
                            icon={<BiTime />}
                            placeholder='9 às 18h'
                            title='Horário de atendimento'
                            value={opening_hours}
                            onChange={event => setOpening_hours(event.target.value)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>


              <Box
                width='500px'
                pad='small'
                justify='between'
                direction='row'>
                <CheckBox toggle label={activated ? 'Agenda On' : 'Agenda Off'} checked={activated}

                  onChange={() => { setNewEdit(!newEdit); setActivated(!activated) }}
                />
                <CheckBox toggle label={open_on_weekends ? 'Fim de semana On' : 'Fim de semana Off'} checked={open_on_weekends}
                  onChange={() => { setOpen_on_weekends(!open_on_weekends) }}
                />
                <a
                  title='Confirmar Aleração:)'
                  onClick={event => { BarberProf() }}>
                  <BtnStyle txtLabel={tbnTxtProf} icon={6} iconsize={sizeIconProf} iconBtnBkcolor={colorprof} />
                </a>
              </Box>
            </Box>
          </Layer>
        </Grommet>
      )}
    </Box>
  )
}