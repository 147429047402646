import React, { useEffect, useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import logoImg from '../../src/images/logo_barbershop.svg';
import api from '../services/api';
import '../css/landing.css';
import '../css/prelooad.css';

import { Box, Image, Main } from 'grommet';
import { FaMapMarkedAlt } from 'react-icons/fa';

interface Professional {
  id: number;
  name: string;
}
interface Barbershop {
  id: number;
  latitude: number;
  longitude: number;
  name: string;
}

export default function Landing(response: Response) {
  const UserEmail = localStorage.getItem('userEmail');

  const [countProfissa, setCountProfissa] = useState(0);
  const [countBarbershop, setCountBarbershop] = useState(0);

  const [profissionals, setProfissionals] = useState<Professional[]>([]);
  const [barbershops, setBarbershops] = useState<Barbershop[]>([]);



  function UpDateBarbershopMap() {
    api.get('professionals').then(response => {
      setProfissionals(response.data)
      setCountProfissa(profissionals.length as any)
    });
    api.get('barbershops').then(response => {
      setBarbershops(response.data)
      setCountBarbershop(barbershops.length as any)
    });
  }
  useEffect(() => {
    api.get('professionals').then(response => {
      setProfissionals(response.data)
      setCountProfissa(profissionals.length as any)
    });
    api.get('barbershops').then(response => {
      setBarbershops(response.data)
      setCountBarbershop(barbershops.length as any)
    });
  }, []);

  if (!profissionals) {
    return (
      <Main>
      <Box pad={{horizontal:'100px', vertical: '160px' }}  align="center" justify="center" direction="row">
        <div className="preloader17">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </Box></Main>);
  
  }

  if (!barbershops) {
    return (
      <Main>
      <Box pad={{horizontal:'100px', vertical: '160px' }}  align="center" justify="center" direction="row">
        <div className="preloader17">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </Box></Main>);
  
  }

  return (
    <Box>
      
      <div id="page-landing" onMouseMove={UpDateBarbershopMap}>
        <div className="content-wrapper">
        
         
        <Image width='280px' src={logoImg} alt="BarbershopApp" />

          <main>
         {/**  <a>{UserEmail}</a> */}
            <p>Bora,<br />cortar o cabelo e aparar a barba :)</p>
            {countBarbershop} Barbearias, {countProfissa} Profissionais
        </main>
          <div className="location">
            <strong>Nova Friburgo</strong>
            <span>Rio de Janeiro</span>
          </div>
          <Link to="/app/nf" className="enter-app">
            <FaMapMarkedAlt size={50} />
          </Link>
        </div>
      </div>      
    </Box>
  );
}