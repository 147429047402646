import { Box, Text } from 'grommet';
import React, { useState, useEffect } from 'react';

export default function TipContent ({ message = '' })  {
  const [messageTip, setMessageTip] = useState('ops..');

    useEffect(() => {
        setMessageTip(message);
      }, []);

      return(
        <Box justify='center' alignContent='center' direction='column'>
        <Box
        animation="fadeIn"
        align="center"
        round={{ size: 'medium', corner: 'bottom' }}
        pad="small"
         background="dark-1" 
           >
             <strong><Text alignSelf="center" size="large" color="light-1">{messageTip}</Text></strong>
        </Box>
        {/**<Box direction="row" align="center" justify='center'  alignContent='center'></Box>  */}
      </Box>
      )
};