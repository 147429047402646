import React, { useEffect, useState, Fragment } from 'react';
import './styles/global.css';
import 'leaflet/dist/leaflet.css';
import fire from "./services/fire";
import Login from "./pages/login";
import './styles/pages/landing.css';
import Routes from './routes';
import Tawkto from '../src/pages/TawkTo';

const App  = () => {
  const [user, setUser] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [hasAccount, setHasAccount] = useState(false);
  const [btnOpen, setBtnOpen] = useState(0);
  const [viewCreateUser, setViewCreateUser] = useState(false);
  // pode passar color por aqui,... e puxe a cor pelo bd... set junto com user & e-mail


  const clearInputs = () => {
    setEmail('');
    setPassword('');
  }
  const clearErrors = () => {
    setEmailError('');
    setPasswordError('');
  }

  const handleLogin = () => {
    clearErrors();

    fire
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((err) => {
        // eslint-disable-next-line default-case
        switch (err.code) {
          case "auth/invalid-email":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setEmailError(err.message);
            break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;
        }
      });

      localStorage.setItem('userEmail', email);
      localStorage.setItem('idBarbershopUser', user as any);
      localStorage.setItem('btnOpen', 0 as any);
  };
  
  const handleSingup = () => {
    
    clearErrors();
    fire
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch((err) => {
        switch (err.code) {
          case "auth/email-already-in-use":
          case "auth/invalid-email":
            setEmailError(err.message);
            break;
          case "auth/weak-password":
            setPasswordError(err.message);
            break;

        }
      });
  };
  // const handleLogout = () => {    fire.auth().signOut();  };
  const authListener = () => {
    
    fire.auth().onAuthStateChanged((user: any) => {
      if (user) {
        clearInputs();
        setUser(user);
      } else {
        setUser(0);
        localStorage.setItem('btnOpen', 0 as any);
        localStorage.setItem('idBarbershopUser', 0 as any);

      }
    });
  };

  const [showChatBot, setShowChatBot] = useState(false);
 
  useEffect(() => {
    authListener();
    localStorage.setItem('idBarbershop', 0 as any);

    const btnOpen = localStorage.getItem('btnOpen');
    
    if (btnOpen === '1') {
      setShowChatBot(true);
    }


    if (user === 0) {
      setViewCreateUser(true);
    }
    else{
      setViewCreateUser(false);
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div onMouseMove={() => {if (btnOpen === 1) {
      setShowChatBot(true);
    }}} className="App">
      {user ? (
        <Routes  />
      ) : (
          <Fragment>

            <Login
            user={user}
            setUser={setUser}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleLogin={handleLogin}
            handleSingup={handleSingup}
            hasAccount={hasAccount}
            setHasAccount={setHasAccount}
            emailError={emailError}
            passwordError={passwordError}
          />
          {showChatBot && (
            <Tawkto/>
          )}          
          </Fragment>
        )}
    </div>
  )
};
export default App;

