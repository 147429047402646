import React, { useEffect } from 'react';
const tawkToPropertyId = '7e20bb1ba1cce228621728ed761ed23e7432e9d9'
const tawkToKey = '60387d06385de407571a37d7'

export default function Tawkto({ linksrc = 'https://embed.tawk.to/60387d06385de407571a37d7/1eveagpdk' }) {

    useEffect(() => {
       
        const script = document.createElement("script");
        script.id = tawkToKey;
        script.async = true;
        script.src = linksrc;
        script.charset = 'UTF-8';
        script.setAttribute('crossorigin', '*');
    
        const first_script_tag = document.getElementsByTagName("script")[0];
        if (!first_script_tag || !first_script_tag.parentNode) {
            throw new Error('DOM is unavailable')
        }
        first_script_tag.parentNode.insertBefore(script, first_script_tag)

        });

    return (
        <>
        </>
    )
}