import React, { ChangeEvent, FormEvent, useState } from "react";
import { Map, Marker, TileLayer } from 'react-leaflet';
import { LeafletMouseEvent } from 'leaflet';
import { useHistory } from "react-router-dom";
import { FiAnchor, FiCamera, FiMenu, FiPlus, FiSquare } from "react-icons/fi";
import '../css/deleteAtt.css';
import Sidebar from "../components/Sidebar";
import mapIcon from "../utils/mapIcons";
import api from "../services/api";
import { Box, Grommet, Heading, Image, Select, Text } from "grommet";
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import Leaflet from 'leaflet';
import { BiSprayCan } from "react-icons/bi";
import { FormDown, FormUp, User } from 'grommet-icons';
import BtnStyle from "./BtnStyleThemeType";



const initMapLat = localStorage.getItem('map-lat');
const initMapLong = localStorage.getItem('map-long');

const options = ['barber-1', 'barber-2', 'barber-3', 'barber-4', 'barber-5', 'brand', 'accent-1', 'accent-2', 'accent-3', 'accent-4', 'neutral-1', 'neutral-2', 'neutral-3', 'neutral-4', 'light-1', 'light-3', 'light-6', 'dark-1', 'dark-3', 'dark-6', 'status-warning', 'status-ok'];

const customRoundedTheme = deepMerge(grommet, {
  global: {

    background: 'dark-1',

    colors: {
      selected: 'dark-1',

    },
    control: {
      border: {
        radius: '0px',
        selected: 'dark-1',

      },
    },
    input: {
      weight: 700,
    },
    font: {
      size: '22px',
    },
  },
  text: {
    medium: '13px',
  },
  textInput: {
    extend: 'padding: 0 12px;',
  },
  select: {

    control: {
      extend: 'padding: 3px 6px;',
      open: {
        background: 'dark-1',
        border: '1px solid dark-1',
      },
    },
    icons: {
      down: FormDown,
      up: FormUp,
      color: 'light-1',
      margin: 'small',
    },
  },
});


export default function CreateBarbershop() {


  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0
  });


  const [positionInit, setPositionInit] = useState({
    latitude: 0,
    longitude: 0
  });

  navigator.geolocation.getCurrentPosition(setPositionFc as any);

  function setPositionFc(position: any) {
    //  console.log(position)
    setIniLat(position.coords.latitude)
    setInitLong(position.coords.longitude)
    //    mapLocalized(lat, long);
  }

  const history = useHistory();

  let [iniLat, setIniLat] = useState('');
  let [initLong, setInitLong] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState('https://i.ibb.co/09vsNhQ/icon.png');
  const [pix, setPix] = useState('pix');
  const [colorbarber, setColorbarber] = useState('#d3cac2');
  const [colorbarberTxt, setColorbarberTxt] = useState('Default');

  const [colorRef, setColorRef] = useState('dark-1');

  const [instrunctions, setInstrunctions] = useState('https://i.ibb.co/YXPrnJn/regras.png');

  const [activated, setActivated] = useState(true);
  const [andress, setAndress] = useState('Nº');
  const [opening_hours, setOpeningHours] = useState('9 às 18h');
  const [open_on_weekends, setOpenOnWeekends] = useState(true);
  const [viewInfoCadastro, setViewInfoCadastro] = useState(false);
  const [viewCadastro, setViewCadastro] = useState(false);
  const [images, setImages] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const addEmailLogin = localStorage.getItem('userEmail');



  function handleMapClick(event: LeafletMouseEvent) {
    const { lat, lng } = event.latlng;

    setPosition({
      latitude: lat,
      longitude: lng
    });
    //    console.log(event.latlng);
  }
  function handleColor(colorUp = 'dark-1') {

    setEmail(addEmailLogin as any);
    switch (colorbarberTxt) {
      case ('barber-1'):
        setColorbarberTxt('barber-1');
        break;
      case ('barber-2'):
        setColorbarberTxt('barber-2');
        break;
      case ('barber-3'):
        setColorbarberTxt('barber-3');
        break;
      case ('barber-4'):
        setColorbarberTxt('barber-4');
        break;
      case ('barber-5'):
        setColorbarberTxt('barber-5');
        break;
      case ('Default'):
        setColorbarberTxt('Default');
        break;
      default:
        setColorbarberTxt(colorUp);
    }

    switch (colorUp) {
      case ('barber-1'):
        setColorRef('#fcddc1');
        setColorbarber('#fcddc1');
        //        setColorbarberTxt('barber-1');
        break;
      case ('barber-2'):
        setColorRef('#d3cac2');
        setColorbarber('#d3cac2');
        //       setColorbarberTxt('barber-2');
        break;
      case ('barber-3'):
        setColorRef('#ddff47');
        setColorbarber('#ddff47');
        //       setColorbarberTxt('barber-3');
        break;
      case ('barber-4'):
        setColorRef('#dabb9e96');
        setColorbarber('#dabb9e96');
        //       setColorbarberTxt('barber-4');
        break;
      case ('barber-5'):
        setColorRef('#533d0b');
        setColorbarber('#533d0b');
        //  status-warning     setColorbarberTxt('barber-5');
        break;

      default:
        setColorRef(colorUp);
    }
  }
  function handleSelectImages(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }
    const selectImages = Array.from(event.target.files);
    setImages(selectImages);

    const selectedImagesPreview = selectImages.map(image => {
      return URL.createObjectURL(image);
    });

    setPreviewImages(selectedImagesPreview);
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const { latitude, longitude } = position;

    const data = new FormData();

    data.append('name', name);
    data.append('latitude', String(latitude));
    data.append('longitude', String(longitude));
    data.append('email', email);
    data.append('pix', pix);
    data.append('avatar', avatar);
    data.append('colorbarber', colorbarber);
    data.append('andress', andress);
    data.append('opening_hours', opening_hours);
    data.append('open_on_weekends', String(open_on_weekends));
    data.append('activated', String(activated));

    images.forEach(image => {
      data.append('images', image);
    })

    await api.post('barbershops', data);
    alert(' Cadastro realizado ');

    history.push('/app/nf');
    console.log({
      position,
      name,
      email,
      latitude,
      longitude,
      andress,
      pix,
      colorbarber,
      avatar,
      opening_hours,
      open_on_weekends,
      activated

    })
  }
  const mapIconAvatar = Leaflet.icon({
    iconUrl: 'https://i.ibb.co/09vsNhQ/icon.png',
    iconRetinaUrl: avatar,
    iconSize: [58, 58],
    iconAnchor: [29, 68],
    popupAnchor: [170, 2]
  })
  return (
    <div id="page-create-orphanage" onMouseMove={() => handleColor(colorbarber)}>
      <Sidebar />
      <main>
        <form onSubmit={handleSubmit} className="create-orphanage-form">

          <fieldset>
            <legend>Cadastre sua Barbershop :)</legend>
            <Box
              justify='center'
              alignSelf='center'
              align='center'
              pad={{ bottom: 'small' }}
            >
              <Text > Clique no mapa, e marque onde fica a sua barbearia. </Text>
            </Box>
            {/** <h4>Latitude:{initMapLat} - Longitude:{initMapLong}</h4> */}
            <Map
              center={[iniLat as any, initLong as any]}
              style={{ width: '100%', height: 280 }}
              zoom={15}
              onclick={handleMapClick}
            >
              <TileLayer
                url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
              />

              {position.latitude !== 0 && (
                <Marker
                  interactive={false}
                  icon={mapIconAvatar}
                  position={[
                    position.latitude,
                    position.longitude
                  ]}
                />
              )}

              {/*<Marker interactive={false} icon={mapIcon} position={[-27.2092052,-49.6401092]} />*/}
            </Map>

            <div className="input-block">
              <label htmlFor="name">Barbershop<span>Coloque aqui o nome da sua barbearia :)</span></label>
              <input
                id="name"
                value={name}
                onChange={event => setName(event.target.value)} />
            </div>

            {/**
            * E-mail aqui só descomentar 
            * 
            *  <div className="input-block">
              <label htmlFor="about">e-mail <span>Máximo de 100 caracteres</span></label>
              <input
                id="name"
                maxLength={100}
                value={email}
                onChange={event => setEmail(event.target.value)} />
            </div>
            */}

            <div className="input-block">
              <label htmlFor="images">Fotos</label>

              <div className="images-container">
                {previewImages.map(image => {
                  return (
                    <img key={image} src={image} alt={name} />
                  )

                })}
                <label htmlFor="image[]" className="new-image">
                  <FiCamera size={40} color="#000" />
                </label>

              </div>

              <input multiple onChange={handleSelectImages} type="file" id="image[]" />
            </div>
            <Box
              pad='small'
              justify='end'
              direction='row'
              align='center'
              alignSelf='center'
            >

              <legend>Li e Concordo.</legend>

              <a title='Leia antes e confirme:)' className='nolink' onClick={() => { setViewInfoCadastro(!viewInfoCadastro) }}>
                <BtnStyle icon={6} />
              </a>

              <a title='Cadastro completo:)' className='nolink' onClick={() => { setViewCadastro(!viewCadastro) }}>
                <BtnStyle icon={19} />
              </a>
            </Box>
          </fieldset>


          {viewCadastro && (
            <>

              <fieldset>


                <div className="input-block">
                  <label htmlFor="avatar">Url Avatar Barbershop<span>Exemplo: https://i.ibb.co/nrqtsK2/ad.png </span> </label>
                  <input
                    id="avatar"
                    value={avatar}
                    onChange={event => setAvatar(event.target.value)} />
                </div>

                <div className="input-block">
                  <label htmlFor="andress">Endereço Barbershop<span>Coloque o nº do endereço e ou uma referência:) </span> </label>
                  <input
                    id="andress"
                    value={andress}
                    onChange={event => setAndress(event.target.value)} />
                </div>

                <div className="input-block">
                  <Box round={{ corner: 'top', size: '8px' }} pad={{ top: 'xsmall', horizontal: 'small' }}>
                    <label htmlFor="colorbarber">Color Barbershop <span>Escolha aqui uma cor para sua barbearia:)   {colorbarberTxt} </span></label>
                    <Grommet theme={customRoundedTheme}>
                      <Box

                        background='dark-1'
                      >
                        <Box
                          direction='row'
                          align='center'
                          justify='center'
                        >

                          <Heading level={2} color={colorbarber}>Seus Títulos e Icons</Heading>
                          <Box
                            pad={{ horizontal: 'medium' }}
                            alignSelf='center'
                          >
                            <BtnStyle iconsize={35} icon={26} iconBtnBkcolor={colorbarber} />
                          </Box>

                        </Box>
                        <Box
                          direction='row'
                          round={{ size: '8px' }}
                          color='dark-1' background={colorRef}>
                          <Box
                            round={{ corner: 'left', size: '8px' }}
                            width='180px'
                            align='center'
                            justify='center'

                            background={colorRef}>
                            <Heading level={2} >  {colorbarberTxt} </Heading>
                          </Box>
                          <Select
                            id="colorbarber"
                            placeholder="Select"
                            value={colorbarber}
                            options={options}
                            onChange={({ option }) => { setColorbarber(option); setColorbarberTxt(''); }}
                          />
                        </Box>
                      </Box>
                    </Grommet>
                  </Box>

                </div>
                {/**
            * Para vip poder adicionar outra cor
            *  <div className="input-block">
              <label htmlFor="colorbarber">Color Barbershop<span>Coloque aqui a cor da sua barbearia:) Exemplo: #FFF </span> </label>
              <input
                id="colorbarber"
                value={colorbarber}
                onChange={event => setColorbarber(event.target.value)} />
            </div>
            */}

                <div className="input-block">
                  <label htmlFor="andress">
                    QrCode PIX
                    <span>Coloque aqui seu Pix. Gerando um QrCode PIX ao fim do agendamento, para facilitar o pagamento de seu cliente e Receba sem tocar no dinheiro, num Pix:) </span> </label>
                  <Box
                    alignSelf='center'
                    alignContent='center'
                    align='center'
                    direction='row'
                    pad={{ horizontal: 'xxsmall' }}
                  >
                    <BtnStyle iconsize={46} icon={22} iconBtnBkcolor={colorbarber} />
                    <input
                      id="pix"
                      value={pix}
                      onChange={event => setPix(event.target.value)} />
                  </Box>
                </div>

                <div className="input-block">
                  <Box
                    justify='center'
                    alignSelf='center'
                    align='center'
                    pad={{ bottom: 'xxsmall' }}
                  >
                    <label htmlFor="opening_hours"> Horário de funcionamento:</label>
                  </Box>
                  <Box
                    alignSelf='center'
                    alignContent='center'
                    align='center'
                    direction='row'
                    pad={{ horizontal: 'xxsmall' }}
                  >
                    <BtnStyle iconsize={46} icon={25} iconBtnBkcolor={colorbarber} />
                    <input
                      id="opening_hours"
                      value={opening_hours}
                      onChange={event => setOpeningHours(event.target.value)} />
                  </Box>
                </div>

                <div className="input-block">
                  <Box
                    justify='center'
                    alignSelf='center'
                    align='center'
                    pad={{ bottom: 'xxsmall' }}
                  >
                    <label htmlFor="open_on_weekends">Atende fim de semana</label>
                  </Box>

                  <Box
                    direction='row'
                    justify='between'
                    width='full'
                    pad={{ horizontal: 'medium' }}>
                    <Box
                      pad='medium'
                      alignContent='center'
                      width='full'
                      align='center'
                      round={{ corner: 'left', size: '8px' }}
                      onClick={() => setOpenOnWeekends(true)}
                      background={open_on_weekends ? colorbarber : 'dark-1'}>
                      <strong>SIM</strong>
                    </Box>
                    <Box
                      pad='medium'
                      alignContent='center'
                      align='center'
                      width='full'
                      round={{ corner: 'right', size: '8px' }}
                      onClick={() => setOpenOnWeekends(false)}
                      background={!open_on_weekends ? colorbarber : 'dark-1'}>
                      <strong>NÃO</strong>
                    </Box>
                  </Box>
                  {/**
               * vai entrar como ativado
               * <label htmlFor="open_on_weekends">Ativado</label>

              <div className="button-select">
                <button
                  type="button"
                  className={activated ? 'active' : ''}
                  onClick={() => setActivated(true)}
                >
                  Sim
                   </button>
                <button
                  type="button"
                  className={!activated ? 'active' : ''}
                  onClick={() => setActivated(false)}
                >
                  Não
                  </button>
              </div>
               */}
                </div>
              </fieldset>
            </>
          )}
          {viewInfoCadastro && (
            <Box pad='medium'>
              <Image width='480px' src={instrunctions} />
              <button className="confirm-button" type="submit">
                Confirmar
              </button>
            </Box>
          )}

        </form>
      </main>
    </div>
  );
}

// return `https://a.tile.openstreetmap.org/${z}/${x}/${y}.png`;
