import React from 'react';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Favicon url="https://comofazerumsite.com/extras/gerador-de-favicons/favicon/3684barbershopapp_layout.ico" />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
