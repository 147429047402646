import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import landing from './pages/landing';
import BarbershopMaps from './pages/BarbershopMap';
import Barbershop from './pages/Barbershop';
import Professional from './pages/Professional';
import CreateBarbershop from './pages/CreateBarbershop';
import CreateBarbershopAdm from './pages/CreateBarbershopAdm';

function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={landing} />
                <Route path="/app/nf" component={BarbershopMaps} />                
                <Route path="/create/barbershops" component={CreateBarbershop} />
                <Route path="/adm/create/barbershops" component={CreateBarbershopAdm} />
                <Route path="/barbershop/:id" component={Barbershop} />
                <Route path="/professional/:id" component={Professional} />
            </Switch>
        </BrowserRouter>
    );
}
export default Routes;